import { createFileRoute, getRouteApi } from '@tanstack/react-router'
import { z } from 'zod'

import { DateFilter } from '../../../../components/Admin/DateFilter'
import { ProjectCard } from '../../../../components/Admin/ProjectCard'
import { BreadCrumbs } from '../../../../utils/breadCrumbs'
import { apiUtils, trpc } from '../../../../utils/trpc'

const routeApi = getRouteApi('/_auth/admin/_admin/users')
export const AdminUserList = () => {
    const { endDate, startDate } = routeApi.useSearch()
    const navigate = routeApi.useNavigate()
    const { adminUsers } = routeApi.useLoaderData()

    const setStartDate = (date: Date | undefined) => {
        navigate({
            search: (search) => ({
                ...search,
                startDate: date ?? undefined,
            }),
        })
    }

    const setEndDate = (date: Date | undefined) => {
        navigate({
            search: (search) => ({
                ...search,
                endDate: date,
            }),
        })
    }

    const { data: projects, isPending } = trpc.project.getAllAdmin.useQuery(undefined, {
        initialData: adminUsers,
    })

    if (isPending) {
        return (
            <div className="flex min-h-screen w-full items-center justify-center text-white">
                <div
                    className="inline-block size-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                >
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                    </span>
                </div>
            </div>
        )
    }

    return (
        <div className="space-y-5">
            <DateFilter
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />
            <div className="grid grid-cols-[repeat(auto-fill,minmax(600px,_1fr))] gap-5">
                {projects?.map((project) => (
                    <ProjectCard
                        key={project.id}
                        project={project}
                        startDate={startDate}
                        endDate={endDate}
                    />
                ))}
            </div>
        </div>
    )
}

export const Route = createFileRoute('/_auth/admin/_admin/users')({
    component: AdminUserList,
    beforeLoad: () => {
        return {
            breadCrumb: BreadCrumbs.AdminUsers,
        }
    },
    validateSearch: z.object({
        startDate: z.string().pipe(z.coerce.date()).optional(),
        endDate: z.string().pipe(z.coerce.date()).optional(),
    }),
    loader: async () => {
        const adminUsers = await apiUtils.project.getAllAdmin.ensureData()
        return {
            adminUsers,
        }
    },
})
