/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import { User } from '@flow/db'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'

export const UserSelector = ({
    value,
    users,
    onChange,
}: {
    value: string | null
    onChange: any
    users: User[] | null
}) => {
    const user = users?.find((s) => s.id === value) || null
    return (
        <Listbox value={value} onChange={onChange}>
            <div className="relative mt-1 w-64">
                <Listbox.Button className="relative w-full cursor-default rounded-lg  bg-white/5 py-2 pl-3 pr-10 text-left  text-white shadow-sm ring-1 ring-inset ring-white/10 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm sm:leading-6">
                    <span className="block truncate">{user?.name || 'Select a user to add'}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {users?.map((user) => (
                            <Listbox.Option
                                key={user.id}
                                className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                        active ? 'bg-brand-blue text-white' : 'text-gray-900'
                                    }`
                                }
                                value={user.id}
                            >
                                {({ selected }) => (
                                    <div
                                        className={`flex items-center space-x-4 truncate ${
                                            selected ? 'font-medium' : 'font-normal'
                                        }`}
                                    >
                                        <img
                                            className="size-8 flex-none rounded-full bg-gray-50"
                                            src={user.avatar}
                                            alt=""
                                        />
                                        <span>{user.name}</span>
                                    </div>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}
