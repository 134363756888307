import { BellOff, BellRing } from 'lucide-react'

import { trpc } from '../../utils/trpc'
import { Button } from '../Primitives/Button'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../Primitives/Tooltip'

interface IssueNotificationButtonProps {
    issueId: string
}

export const IssueNotificationButton = ({ issueId }: IssueNotificationButtonProps) => {
    const context = trpc.useUtils()
    const { data } = trpc.notification.getIssueNotificationSubscriptionStatus.useQuery({
        issueId,
    })
    const { mutate } = trpc.notification.toggleIssueNotifications.useMutation({
        onSuccess: () => {
            context.notification.getIssueNotificationSubscriptionStatus.invalidate({
                issueId,
            })
        },
    })

    const isSubscribed: boolean = data?.subscribed ?? false

    return (
        <Button
            variant="ghost"
            onClick={() =>
                mutate({
                    issueId,
                })
            }
        >
            <TooltipProvider>
                <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                        {isSubscribed ? <BellRing /> : <BellOff />}
                    </TooltipTrigger>
                    <TooltipContent>
                        {isSubscribed
                            ? 'Unsubscribe from notifications'
                            : 'Subscribe to notifications'}
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </Button>
    )
}
