import { DotsHorizontalIcon } from '@radix-ui/react-icons'

import { Button } from '../Primitives/Button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuTrigger,
} from '../Primitives/DropdownMenu'

interface CommentDropdownProps {
    setDeleteModal?: () => void
    isAuthor: boolean
}
export const CommentDropdown = ({ setDeleteModal, isAuthor }: CommentDropdownProps) => {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    className="flex size-8 p-0 data-[state=open]:bg-muted-foreground"
                >
                    <DotsHorizontalIcon className="size-4" />
                    <span className="sr-only">Open menu</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[200px] text-xs">
                {isAuthor && <DropdownMenuItem>Edit</DropdownMenuItem>}
                <DropdownMenuItem
                    onClick={() => {
                        navigator.clipboard.writeText(window.location.href)
                    }}
                >
                    Copy link to comment
                </DropdownMenuItem>
                {isAuthor && (
                    <>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={setDeleteModal}>
                            Delete
                            <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
                        </DropdownMenuItem>
                    </>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
