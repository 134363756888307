import { createFileRoute } from '@tanstack/react-router'

import { NotificationItem } from '../../components/Notifications/NotificationItem'
import { Button } from '../../components/Primitives/Button'
import { BreadCrumbs } from '../../utils/breadCrumbs'
import { trpc } from '../../utils/trpc'

export const Inbox = () => {
    const { data } = trpc.notification.getLastNotifications.useQuery()
    const context = trpc.useUtils()
    const { mutate } = trpc.notification.markNotificationAsRead.useMutation({
        onSuccess: () => {
            context.notification.getLastNotifications.invalidate()
        },
    })

    const handleMarkAllAsRead = () => {
        mutate({
            notificationIds: data?.map((notification) => notification.id) || [],
        })
    }

    return (
        <div className="mx-auto w-[700px] space-y-12">
            <div className="flex justify-between">
                <h1 className="text-2xl font-bold">Inbox</h1>
                <Button onClick={handleMarkAllAsRead} variant="outline">
                    Mark all as read
                </Button>
            </div>
            <div className="mx-auto  text-sm">
                {data?.map((notification) => {
                    return <NotificationItem key={notification.id} notification={notification} />
                })}
            </div>
        </div>
    )
}

export const Route = createFileRoute('/_auth/inbox')({
    component: Inbox,
    beforeLoad: () => {
        return {
            breadCrumb: BreadCrumbs.Inbox,
        }
    },
})
