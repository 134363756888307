import { CommentWithChildAndUser, CommentWithUser } from '@schema'
import { useSearch } from '@tanstack/react-router'
import { ReplyIcon } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'

import { useAuth } from '../../contexts/auth.context'
import { useTaskStore } from '../../hooks/useTaskStore'
import { cn } from '../../utils/cn'
import { timeAgo } from '../../utils/date'
import { trpc } from '../../utils/trpc'
import { CommentEditor } from '../Plate/CommentEditor'
import { Avatar, AvatarImage } from '../Primitives/Avatar'
import { Button } from '../Primitives/Button'
import { AddComment } from './AddComment'
import { CommentDropdown } from './CommentDropdown'
import { DeleteCommentDialog } from './DeleteCommentDialog'
import { EmojiCounter } from './GithubCounter'
interface CommentBoxProps {
    comment: CommentWithChildAndUser
    issueId: string
}

export const CommentDisplay = ({ comment, issueId }: CommentBoxProps) => {
    const [commentToDelete, setCommentToDelete] = useState<string | null>(null)
    const setReplyId = useTaskStore((s) => s.setActiveReplyId)
    const replyId = useTaskStore((s) => s.activeReplyId)

    return (
        <div className="flex flex-col space-y-4 rounded-lg text-primary">
            <SingleComment
                comment={comment}
                onDelete={setCommentToDelete}
                onReply={() => setReplyId(comment.id)}
            />
            <div className="flex flex-col space-y-4 border-l-2 pl-4">
                {comment.childComments.map((child) => (
                    <SingleComment
                        key={child.id}
                        comment={child}
                        onDelete={setCommentToDelete}
                        isChild={true}
                    />
                ))}
                {replyId === comment.id && (
                    <AddComment
                        issueId={issueId}
                        parentId={comment.id}
                        cancelReply={() => setReplyId(null)}
                        onCommentCb={() => setReplyId(null)}
                    />
                )}
            </div>

            <DeleteCommentDialog
                commentId={commentToDelete}
                setClose={() => setCommentToDelete(null)}
            />
        </div>
    )
}

interface SingleCommentProps {
    comment: CommentWithUser
    onDelete: (id: string) => void
    onReply?: () => void
    isChild?: boolean
}

const SingleComment = ({ comment, onDelete, onReply, isChild = false }: SingleCommentProps) => {
    const { user } = useAuth()
    const { commentId } = useSearch({
        from: '/_auth/project/$projectId/issue/$issueId',
    })
    const commentRef = useRef<HTMLDivElement>(null)

    const utils = trpc.useUtils()
    const { mutateAsync } = trpc.issue.createCommentReaction.useMutation()

    useEffect(() => {
        if (commentRef.current && comment.id === commentId) {
            commentRef.current.scrollIntoView({
                behavior: 'smooth',
            })
        }
    }, [comment.id, commentId])

    const handleCommentReaction = async (emoji: string) => {
        await mutateAsync({
            commentId: comment.id,
            emoji,
        })
        utils.issue.invalidate()
    }

    return (
        <div
            ref={commentRef}
            className={cn(
                'flex flex-col space-y-2 bg-secondary transition-colors rounded-lg p-2 pl-4 ',
                {
                    'bg-secondary/50': isChild,
                },
            )}
        >
            <div className="flex items-center space-x-2 text-xs">
                <Avatar className={cn('h-6 w-6')}>
                    <AvatarImage src={comment.user.avatar} />
                </Avatar>
                <p>{comment.user.name}</p>
                <p className="text-muted-foreground">{timeAgo(comment.createdAt)}</p>
                <div className="flex flex-1 items-center justify-end">
                    {onReply && (
                        <Button
                            onClick={onReply}
                            variant="ghost"
                            size="icon"
                            className="flex size-8 p-0"
                        >
                            <ReplyIcon className="mr-1 inline-block size-3" />
                        </Button>
                    )}
                    <CommentDropdown
                        setDeleteModal={() => onDelete(comment.id)}
                        isAuthor={comment.user.id === user?.id}
                    />
                </div>
            </div>
            <CommentEditor key={comment.id} value={comment.body as any} contentEditable={false} />
            <div className="flex justify-end">
                <EmojiCounter
                    reactions={comment.reactions}
                    onSelect={(code) => handleCommentReaction(code)}
                />
            </div>
        </div>
    )
}
