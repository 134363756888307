import { PlateEditor, Value, WithPlatePlugin } from '@udecode/plate-common'

import { createUploadStore } from '../upload/createUploadStore'
import { finishUploads } from './finishUploads'
import { getSaveValue } from './getSaveValue'
import { AttachmentsPlugin, CloudEditorProps, FinishUploadsOptions } from './types'
import { uploadFiles } from './uploadFiles'

export const withAttachments = <
    V extends Value = Value,
    E extends PlateEditor<V> = PlateEditor<V>,
    EE extends E & CloudEditorProps<V> = E & CloudEditorProps<V>,
>(
    e: E,
    plugin: WithPlatePlugin<AttachmentsPlugin, V, E>,
) => {
    const editor = e as unknown as EE

    const { uploadStoreInitialValue } = plugin.options

    const uploadStore = createUploadStore({
        uploads: uploadStoreInitialValue || {},
    })

    editor.attachments = {
        uploadFiles: (files: Iterable<File>) => {
            uploadFiles(editor, files)
        },
        uploadStore,
        getSaveValue: () => {
            return getSaveValue<V>(editor.children, uploadStore.get.uploads())
        },
        finishUploads: async (options?: FinishUploadsOptions) => {
            return finishUploads(editor, options)
        },
    }

    return editor
}
