import { createFileRoute } from '@tanstack/react-router'

import { AddHoliday } from '../../components/Holidays/AddHoliday'
import { Columns } from '../../components/Holidays/Columns'
import { HolidaysTable } from '../../components/Holidays/HolidaysTable'
import { BreadCrumbs } from '../../utils/breadCrumbs'
import { trpc } from '../../utils/trpc'

export const HolidaysPage = () => {
    const { data, isLoading } = trpc.holiday.getAll.useQuery()

    if (isLoading) {
        return (
            <div className="flex min-h-screen w-full items-center justify-center text-white">
                <div
                    className="inline-block size-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                >
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                    </span>
                </div>
            </div>
        )
    }

    return (
        <div className="w-full">
            <AddHoliday />
            <HolidaysTable data={data ?? []} columns={Columns} />
        </div>
    )
}

export const Route = createFileRoute('/_auth/holidays')({
    component: HolidaysPage,
    beforeLoad: () => {
        return {
            breadCrumb: BreadCrumbs.Holidays,
        }
    },
})
