import { formatDistanceStrict } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'

export const useElapsedTime = () => {
    const [elapsedTime, setElapsedTime] = useState('00:00:00')
    const [startDate, setStartDate] = useState<Date | null>(null)

    const updateElapsedTime = useCallback(() => {
        if (startDate) {
            const now = new Date()
            const distance = formatDistanceStrict(startDate, now, { unit: 'second' })
            const totalSeconds = parseInt(distance, 10)
            const hours = Math.floor(totalSeconds / 3600)
            const minutes = Math.floor((totalSeconds % 3600) / 60)
            const seconds = totalSeconds % 60

            if (totalSeconds < 3600) {
                setElapsedTime(`${padZero(minutes)}:${padZero(seconds)}`)
            } else {
                setElapsedTime(`${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`)
            }
        }
    }, [startDate])

    useEffect(() => {
        updateElapsedTime()
        const intervalId = setInterval(updateElapsedTime, 1000)

        return () => clearInterval(intervalId)
    }, [startDate, updateElapsedTime])

    const padZero = (num: number) => {
        return String(num).padStart(2, '0')
    }

    return {
        elapsedTime,
        setStartDate,
    }
}
