import { NotificationType } from '@flow/db'
import { Notification } from '@schema'
import { useNavigate } from '@tanstack/react-router'
import { match, P } from 'ts-pattern'

import { timeAgo } from '../../utils/date'
import { trpc } from '../../utils/trpc'
import { Avatar, AvatarImage } from '../Primitives/Avatar'

interface NotificationItemProps {
    notification: Notification
}

export const NotificationItem = ({ notification }: NotificationItemProps) => {
    const navigate = useNavigate()
    const context = trpc.useUtils()
    const { mutate } = trpc.notification.markNotificationAsRead.useMutation({
        onSuccess: () => {
            context.notification.getLastNotifications.invalidate()
        },
    })

    // There is always an  actor for now, but there will be system messages in the future
    if (!notification.actor) {
        return null
    }

    return match(notification)
        .with(
            {
                type: P.union(NotificationType.COMMENT_ADDED, NotificationType.COMMENT_REPLIED),
            },
            (notification) => {
                const { comment, issue, project, actor, type } = notification

                const handleOnClick = () => {
                    mutate({
                        notificationIds: [notification.id],
                    })
                    navigate({
                        to: `/project/${project?.id}/issue/${issue?.id}?commentId=${comment?.id}`,
                    })
                }
                return (
                    <div
                        onClick={handleOnClick}
                        className="flex cursor-pointer space-x-2 rounded-lg px-4 py-2 hover:bg-muted"
                    >
                        <div className="relative">
                            {notification.status === 'UNREAD' && (
                                <div className="absolute -left-1 -top-1 z-10 flex size-[10px] rounded-full bg-blue-700" />
                            )}
                            <Avatar asChild={true} className="mt-1 size-8">
                                <AvatarImage src={actor?.avatar} />
                            </Avatar>
                        </div>
                        <div className="flex flex-1 flex-col space-y-1">
                            <p>
                                <span className="text-muted-foreground">{actor?.name}</span>{' '}
                                {type === NotificationType.COMMENT_REPLIED
                                    ? 'replied to your comment on:'
                                    : 'commented on:'}
                            </p>
                            <p>
                                <span className="font-bold">
                                    #{project?.id}-{issue?.key}
                                </span>{' '}
                                {issue?.name}
                            </p>
                            <div className="flex justify-end">
                                <p className="text-xs text-muted-foreground">
                                    {timeAgo(notification.createdAt)}
                                </p>
                            </div>
                        </div>
                    </div>
                )
            },
        )
        .with(
            {
                type: NotificationType.ISSUE_ASSIGNED,
            },
            (notification) => {
                const { issue, project, actor } = notification

                const handleOnClick = () => {
                    mutate({
                        notificationIds: [notification.id],
                    })
                    navigate({ to: `/project/${project?.id}/issue/${issue?.id}` })
                }
                return (
                    <div
                        onClick={handleOnClick}
                        className="flex cursor-pointer space-x-2 rounded-lg px-4 py-2 hover:bg-muted"
                    >
                        <div className="relative">
                            {notification.status === 'UNREAD' && (
                                <div className="absolute -left-1 -top-1 z-10 flex size-[10px] rounded-full bg-blue-700" />
                            )}
                            <Avatar asChild={true} className="mt-1 size-8">
                                <AvatarImage src={actor?.avatar} />
                            </Avatar>
                        </div>
                        <div className="flex flex-1 flex-col space-y-1">
                            <p>
                                <span className="text-muted-foreground">{actor?.name} </span>
                                assigned you to:
                            </p>
                            <p>
                                <span className="font-bold">
                                    #{project?.id}-{issue?.key}
                                </span>{' '}
                                {issue?.name}
                            </p>
                            <div className="flex justify-end">
                                <p className="text-xs text-muted-foreground">
                                    {timeAgo(notification.createdAt)}
                                </p>
                            </div>
                        </div>
                    </div>
                )
            },
        )
        .with(
            {
                type: NotificationType.USER_MENTIONED,
            },
            (notification) => {
                const { issue, project, actor, comment } = notification

                // eslint-disable-next-line sonarjs/no-identical-functions
                const handleOnClick = () => {
                    mutate({
                        notificationIds: [notification.id],
                    })
                    navigate({
                        to: `/project/${project?.id}/issue/${issue?.id}?commentId=${comment?.id}`,
                    })
                }

                return (
                    <div
                        onClick={handleOnClick}
                        className="flex cursor-pointer space-x-2 rounded-lg px-4 py-2 hover:bg-muted"
                    >
                        <div className="relative">
                            {notification.status === 'UNREAD' && (
                                <div className="absolute -left-1 -top-1 z-10 flex size-[10px] rounded-full bg-blue-700" />
                            )}
                            <Avatar asChild={true} className="mt-1 size-8">
                                <AvatarImage src={actor?.avatar} />
                            </Avatar>
                        </div>
                        <div className="flex flex-1 flex-col space-y-1">
                            <p>
                                <span className="text-muted-foreground">{actor?.name} </span>
                                mention you in {comment ? 'comment' : 'description'}:
                            </p>
                            <p>
                                <span className="font-bold">
                                    #{project?.id}-{issue?.key}
                                </span>{' '}
                                {issue?.name}
                            </p>
                            <div className="flex justify-end">
                                <p className="text-xs text-muted-foreground">
                                    {timeAgo(notification.createdAt)}
                                </p>
                            </div>
                        </div>
                    </div>
                )
            },
        )
        .otherwise((n) => {
            console.error('Unknown notification type', n)
            return null
        })
}
