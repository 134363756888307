import { Dialog, Transition } from '@headlessui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { ProjectSchema, projectSchema } from '@schema'
import { Fragment } from 'react'
import { useForm } from 'react-hook-form'

import { trpc } from '../../utils/trpc'

export const CreateProjectModal = ({
    isOpen,
    closeModal,
}: {
    isOpen: boolean
    closeModal: () => void
}) => {
    const utils = trpc.useUtils()
    const { mutate } = trpc.project.create.useMutation({
        async onSuccess() {
            await utils.project.getAll.invalidate()
            closeModal()
        },
    })

    const { register, handleSubmit } = useForm<ProjectSchema>({
        resolver: zodResolver(projectSchema),
    })

    const onSubmit = (data: ProjectSchema) => {
        mutate(data)
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-slate-700/70" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="mt-32 flex min-h-full items-start justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md overflow-hidden rounded-2xl bg-gray-900 p-6 text-left align-middle shadow-xl transition-all">
                                <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="flex w-full justify-between">
                                        <div className="flex-1">
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium leading-6 text-white"
                                            >
                                                Name
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="name"
                                                    type="name"
                                                    autoComplete="email"
                                                    required
                                                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                                    {...register('name')}
                                                />
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium leading-6 text-white"
                                            >
                                                Project Identifier
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                                    {...register('id')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex items-center justify-between">
                                            <label
                                                htmlFor="password"
                                                className="block text-sm font-medium leading-6 text-white"
                                            >
                                                Project description
                                            </label>
                                        </div>
                                        <div className="mt-2">
                                            <textarea
                                                required
                                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                                {...register('description')}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                        >
                                            Create
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
