import { getForBoardFilterSchema } from '@schema'
import { createFileRoute, getRouteApi } from '@tanstack/react-router'
import { useParams } from '@tanstack/react-router'

import { Board } from '../../../components/Board/Board'
import { BoardFilters } from '../../../components/Board/BoardFilters'
import { NewIssueDialog } from '../../../components/Issue/NewIssueDialog'
import { ProjectContextProvider } from '../../../contexts/project.context'
import { BreadCrumbs } from '../../../utils/breadCrumbs'
import { apiUtils } from '../../../utils/trpc'

const PATH = '/_auth/project/$projectId/'
const routeApi = getRouteApi(PATH)

export const BoardPage = () => {
    const params = useParams({
        strict: false,
    })
    const { projectData } = routeApi.useLoaderData()

    return (
        <div className="relative flex size-full flex-1 flex-col text-base text-primary">
            <ProjectContextProvider projectId={params.projectId!} initialData={projectData}>
                <div className="flex flex-1 flex-col">
                    <div className="flex space-x-4">
                        <NewIssueDialog key={params.projectId} source="board" />
                        <BoardFilters />
                    </div>
                    <Board projectId={params.projectId!} />
                </div>
            </ProjectContextProvider>
        </div>
    )
}

export const Route = createFileRoute('/_auth/project/$projectId/')({
    component: BoardPage,
    beforeLoad: () => {
        return {
            breadCrumb: BreadCrumbs.Board,
        }
    },
    validateSearch: getForBoardFilterSchema,
    loaderDeps: ({ search }) => ({
        search,
    }),
    loader: async ({ params, deps }) => {
        const projectDataPromise = apiUtils.project.getOne.ensureData({
            id: params.projectId,
            ...deps.search,
        })

        const projectBoardDataPromise = apiUtils.project.getForBoard.ensureData({
            id: params.projectId,
            ...deps.search,
        })

        const [projectData, projectBoardData] = await Promise.all([
            projectDataPromise,
            projectBoardDataPromise,
        ])

        return {
            projectData,
            projectBoardData,
        }
    },
})
