import { createFileRoute } from '@tanstack/react-router'

import { BreadCrumbs } from '../../../../utils/breadCrumbs'

export const Admin = () => {
    return <div>Admin</div>
}

export const Route = createFileRoute('/_auth/admin/_admin/')({
    component: Admin,
    beforeLoad: () => {
        return {
            breadCrumb: BreadCrumbs.AdminDashboard,
        }
    },
})
