import { useParams } from '@tanstack/react-router'
import { cn, withRef } from '@udecode/cn'
import { PlateElement } from '@udecode/plate-common'
import { getMentionOnSelectItem } from '@udecode/plate-mention'
import { useState } from 'react'

import { trpc } from '../../../utils/trpc'
import {
    InlineCombobox,
    InlineComboboxContent,
    InlineComboboxEmpty,
    InlineComboboxInput,
    InlineComboboxItem,
} from './inline-combobox'

const onSelectItem = getMentionOnSelectItem()

export const MentionInputElement = withRef<typeof PlateElement>(({ className, ...props }, ref) => {
    const { children, editor, element } = props
    const [search, setSearch] = useState('')
    const { issueId } = useParams({
        strict: false,
    })
    const { data: issue } = trpc.issue.getById.useQuery({
        issueId: issueId || '',
    })

    return (
        <PlateElement as="span" data-slate-value={element.value} ref={ref} {...props}>
            <InlineCombobox
                element={element}
                setValue={setSearch}
                showTrigger={false}
                trigger="@"
                value={search}
            >
                <span
                    className={cn(
                        'inline-block rounded-md bg-muted px-1.5 py-0.5 align-baseline text-sm ring-ring focus-within:ring-2',
                        className,
                    )}
                >
                    <InlineComboboxInput />
                </span>

                <InlineComboboxContent className="my-1.5">
                    <InlineComboboxEmpty>No results found</InlineComboboxEmpty>

                    {issue?.project?.members.map((member) => (
                        <InlineComboboxItem
                            key={member.id}
                            onClick={() =>
                                onSelectItem(
                                    editor,
                                    {
                                        text: member.name,
                                    },
                                    search,
                                )
                            }
                            value={member.id}
                        >
                            {member.name}
                        </InlineComboboxItem>
                    ))}
                </InlineComboboxContent>
            </InlineCombobox>

            {children}
        </PlateElement>
    )
})
