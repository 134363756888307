import { formatDuration } from '../../utils/date'

export const LoggedEntryBar: React.FC<{ loggedMinutes: number }> = ({ loggedMinutes }) => {
    const getDailyPerformance = (loggedMinutes: number) => {
        const fullDay = 480
        return (loggedMinutes / fullDay) * 100 > 100 ? 100 : (loggedMinutes / fullDay) * 100
    }

    return (
        <div className="relative h-40 w-full overflow-hidden rounded-md bg-secondary">
            <div
                className="absolute top-0 size-full flex-1 rounded-t-sm bg-primary transition-all"
                style={{
                    transform: `translateY(${100 - (getDailyPerformance(loggedMinutes) || 0)}%)`,
                }}
            />
            <div className="absolute bottom-0 z-10 flex w-full flex-1 justify-center pb-1 font-bold text-secondary">
                {formatDuration(loggedMinutes)}
            </div>
        </div>
    )
}
