import type { AppRouter } from '@api'
import { User } from '@flow/db'
import { inferRouterOutputs } from '@trpc/server'
import { createContext, useContext, useEffect } from 'react'

import { removeJwt, storeJwt } from '../utils/ls'
import { trpc } from '../utils/trpc'

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

type RouterOutput = inferRouterOutputs<AppRouter>

export type UserOutput = RouterOutput['auth']['getMe']

export type AuthContextType = {
    login: (token: string, user?: User) => Promise<void>
    logout: () => Promise<void>
    user: UserOutput | undefined
}

export const AuthContextProvider: React.FC<{
    children?: React.ReactNode
}> = ({ children }) => {
    const { isLoading, refetch, data, error } = trpc.auth.getMe.useQuery(undefined, {
        staleTime: Infinity,
        refetchInterval: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: 0,
    })

    useEffect(() => {
        if (error?.shape?.data?.httpStatus === 401 || error?.message === 'Unauthorized') {
            removeJwt()
        }
    }, [error])

    const login = async (jwt: string) => {
        storeJwt(jwt)
        await refetch()
    }

    const logout = async () => {
        removeJwt()
    }

    if (isLoading) {
        return (
            <div className="flex min-h-screen w-full items-center justify-center text-white">
                <div
                    className="inline-block size-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                >
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                    </span>
                </div>
            </div>
        )
    }

    return (
        <AuthContext.Provider
            value={{
                login,
                logout,
                user: data,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = (): AuthContextType => {
    return useContext(AuthContext)
}
