import { createPluginFactory, Value } from '@udecode/plate-common'

import { PlateCloudEditor } from '../attachmentsPlugin/types'
import { CloudAttachmentPlugin } from './types'
import { withCloudAttachment } from './withCloudAttachment'

export const ELEMENT_ATTACHMENT = 'cloud_attachment'

export const createAttachmentPluginEl = createPluginFactory<
    CloudAttachmentPlugin,
    Value,
    PlateCloudEditor
>({
    key: ELEMENT_ATTACHMENT,
    isElement: true,
    isVoid: true,
    withOverrides: withCloudAttachment,
})
