import { User } from '@flow/db'
import { ReactionWithUser } from '@schema'
import _ from 'lodash'
import { Smile } from 'lucide-react'
import React from 'react'

import { useAuth } from '../../contexts/auth.context'
import { Button } from '../Primitives/Button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../Primitives/DropdownMenu'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../Primitives/Tooltip'

interface GithubCounterProps {
    reactions: ReactionWithUser[]
    user: User
    onSelect: (emoji: string) => void
}

export const GithubCounter: React.FC<GithubCounterProps> = ({ reactions, onSelect }) => {
    const groups = _.groupBy(reactions, 'emoji')
    return (
        <>
            {_.map(groups, (c, emoji) => {
                return (
                    <GithubCounterGroup
                        key={emoji}
                        emoji={emoji}
                        count={c.length}
                        users={c.map((r) => r.user)}
                        onSelect={onSelect}
                    />
                )
            })}
        </>
    )
}

interface GithubCounterGroupProps {
    emoji: string
    count: number
    onSelect: (emoji: string) => void
    users: User[]
}

export const GithubCounterGroup: React.FC<GithubCounterGroupProps> = ({
    emoji,
    count,
    onSelect,
    users,
}) => {
    return (
        <TooltipProvider>
            <Tooltip delayDuration={20}>
                <TooltipTrigger asChild>
                    <Button
                        onClick={() => onSelect(emoji)}
                        variant="outline"
                        size="sm"
                        className="h-8 px-2"
                    >
                        <span className="mr-1 font-emoji text-base">{emoji}</span> {count}
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    {users.map((user, i) => {
                        return (
                            <div key={i} className="text-xs">
                                {user.name}
                            </div>
                        )
                    })}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}

interface GithubSelectorProps {
    reactions?: string[]
    onSelect: (shortcode: string) => void
}

export const GithubSelector: React.FC<GithubSelectorProps> = ({
    reactions = ['👍', '👎', '😄', '🎉', '😕', '❤️'],
    onSelect,
}) => {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button size="sm" variant="outline">
                    <Smile className="size-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent side="top">
                <div className="flex space-x-3 p-2">
                    {_.map(reactions, (reaction, i) => (
                        <div
                            key={i}
                            className="cursor-pointer text-center font-emoji text-xl leading-4 transition-transform duration-150 hover:scale-125"
                            onClick={() => onSelect(reaction)}
                        >
                            {reaction}
                        </div>
                    ))}
                </div>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

interface EmojiCounterProps {
    onSelect: (shortcode: string) => void
    reactions: ReactionWithUser[]
}

export const EmojiCounter = ({ onSelect, reactions }: EmojiCounterProps) => {
    const { user } = useAuth()
    return (
        <div className="flex gap-2">
            <GithubCounter user={user!} reactions={reactions} onSelect={onSelect} />
            <GithubSelector onSelect={onSelect} />
        </div>
    )
}
