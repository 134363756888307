import { IssuePriority } from '@flow/db'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    AssigneesSchema,
    assigneesSchema,
    CycleIdSchema,
    cycleIdSchema,
    EpicIdSchema,
    epicIdSchema,
    EstimationStringSchema,
    estimationStringSchema,
    GetIssueOutput,
    PrioritySchema,
    prioritySchema,
    StartDateSchema,
    startDateSchema,
    StatusIdSchema,
    statusIdSchema,
    TargetDateSchema,
    targetDateSchema,
} from '@schema'
import { Check } from 'lucide-react'
import { useForm } from 'react-hook-form'

import { convertToTimeString } from '../../utils/date'
import { trpc } from '../../utils/trpc'
import { Button } from '../Primitives/Button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../Primitives/Form'
import { Input } from '../Primitives/Input'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../Primitives/Select'
import { DatePicker } from '../Tracker/DatePicker'
import { AssigneeBox } from './MultiSelect'

// TODO refactor

interface BaseIssuePropertyEditProps {
    issue: GetIssueOutput
}

export const IssuePropertyEdit = ({ issue }: BaseIssuePropertyEditProps) => {
    return (
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <EpicPropertyEdit issue={issue} />
            <CyclePropertyEdit issue={issue} />
            <AssigneePropertyEdit issue={issue} />
            <EstimatePropertyEdit issue={issue} />
            <StatusPropertyEdit issue={issue} />
            <PriorityPropertyEdit issue={issue} />
            <StartDatePropertyEdit issue={issue} />
            <TargetDatePropertyEdit issue={issue} />
        </div>
    )
}

const EpicPropertyEdit = ({ issue }: BaseIssuePropertyEditProps) => {
    const context = trpc.useUtils()
    const { mutate } = trpc.issue.editProperties.useMutation({
        onSuccess: async () => {
            await context.issue.getById.invalidate({
                issueId: issue.id,
            })
        },
    })
    const form = useForm<EpicIdSchema>({
        mode: 'onChange',
        resolver: zodResolver(epicIdSchema),
        defaultValues: {
            issueId: issue.id,
            epicId: issue.epicId,
        },
    })

    return (
        <Form {...form}>
            <div>
                <FormField
                    name="epicId"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Epic</FormLabel>
                            <FormControl>
                                <Select
                                    defaultValue={issue.epicId === null ? undefined : issue.epicId}
                                    onValueChange={(val) => {
                                        field.onChange(val)
                                        mutate({
                                            issueId: issue.id,
                                            epicId: val,
                                        })
                                    }}
                                >
                                    <SelectTrigger className=" bg-background">
                                        <SelectValue>{issue.epic?.name || ''}</SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectItem value={null as any}>No epic</SelectItem>
                                            {issue.project.epics.map((status) => {
                                                return (
                                                    <SelectItem key={status.id} value={status.id}>
                                                        {status.name}
                                                    </SelectItem>
                                                )
                                            })}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </Form>
    )
}

const CyclePropertyEdit = ({ issue }: BaseIssuePropertyEditProps) => {
    const context = trpc.useUtils()
    const { mutate } = trpc.issue.editProperties.useMutation({
        onSuccess: async () => {
            await context.issue.getById.invalidate({
                issueId: issue.id,
            })
        },
    })
    const form = useForm<CycleIdSchema>({
        mode: 'onChange',
        resolver: zodResolver(cycleIdSchema),
        defaultValues: {
            issueId: issue.id,
            cycleId: issue.cycleId,
        },
    })

    return (
        <Form {...form}>
            <div>
                <FormField
                    name="cycleId"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Cycle</FormLabel>
                            <FormControl>
                                <Select
                                    defaultValue={
                                        issue.cycleId === null ? undefined : issue.cycleId
                                    }
                                    onValueChange={(val) => {
                                        field.onChange(val)
                                        mutate({
                                            issueId: issue.id,
                                            cycleId: val,
                                        })
                                    }}
                                >
                                    <SelectTrigger className=" bg-background">
                                        <SelectValue>{issue.cycle?.name || 'No cycle'}</SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectItem value={null as any}>No cycle</SelectItem>
                                            {issue.project.cycles.map((cycle) => {
                                                return (
                                                    <SelectItem key={cycle.id} value={cycle.id}>
                                                        {cycle.name}
                                                    </SelectItem>
                                                )
                                            })}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </Form>
    )
}

const AssigneePropertyEdit = ({ issue }: BaseIssuePropertyEditProps) => {
    const context = trpc.useUtils()
    const { mutate } = trpc.issue.editProperties.useMutation({
        onSuccess: async () => {
            await context.issue.getById.invalidate({
                issueId: issue.id,
            })
        },
    })
    const form = useForm<AssigneesSchema>({
        mode: 'onChange',
        resolver: zodResolver(assigneesSchema),
        defaultValues: {
            issueId: issue.id,
            assignees: issue.assignees.map((a) => a.id),
        },
    })

    return (
        <Form {...form}>
            <div>
                <FormField
                    name="assignees"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Assignees</FormLabel>
                            <FormControl>
                                <AssigneeBox
                                    className="w-full"
                                    selectedPeople={issue.project.members.filter((member) =>
                                        field.value?.includes(member.id),
                                    )}
                                    peopleOptions={issue.project.members}
                                    onChange={(val) => {
                                        field.onChange(val)
                                        mutate({
                                            issueId: issue.id,
                                            assignees: val,
                                        })
                                    }}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </Form>
    )
}

const TargetDatePropertyEdit = ({ issue }: BaseIssuePropertyEditProps) => {
    const context = trpc.useUtils()
    const { mutate } = trpc.issue.editProperties.useMutation({
        onSuccess: async () => {
            await context.issue.getById.invalidate({
                issueId: issue.id,
            })
        },
    })
    const form = useForm<TargetDateSchema>({
        mode: 'onChange',
        resolver: zodResolver(targetDateSchema),
        defaultValues: {
            issueId: issue.id,
            targetDate: issue.targetDate,
        },
    })

    return (
        <Form {...form}>
            <div>
                <FormField
                    name="targetDate"
                    render={({ field }) => (
                        <FormItem className="flex flex-col">
                            <FormLabel>Target Date</FormLabel>
                            <FormControl>
                                <DatePicker
                                    withClear={true}
                                    setDate={(val) => {
                                        field.onChange(val ?? null)
                                        mutate({
                                            issueId: issue.id,
                                            targetDate: val ?? null,
                                        })
                                    }}
                                    date={field.value ?? undefined}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </Form>
    )
}

const StartDatePropertyEdit = ({ issue }: BaseIssuePropertyEditProps) => {
    const context = trpc.useUtils()
    const { mutate } = trpc.issue.editProperties.useMutation({
        onSuccess: async () => {
            await context.issue.getById.invalidate({
                issueId: issue.id,
            })
        },
    })
    const form = useForm<StartDateSchema>({
        mode: 'onChange',
        resolver: zodResolver(startDateSchema),
        defaultValues: {
            issueId: issue.id,
            startDate: issue.startDate,
        },
    })

    return (
        <Form {...form}>
            <div>
                <FormField
                    name="startDate"
                    render={({ field }) => (
                        <FormItem className="flex flex-col">
                            <FormLabel>Start Date</FormLabel>
                            <FormControl>
                                <DatePicker
                                    withClear={true}
                                    setDate={(val) => {
                                        field.onChange(val ?? null)
                                        mutate({
                                            issueId: issue.id,
                                            startDate: val ?? null,
                                        })
                                    }}
                                    date={field.value ?? undefined}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </Form>
    )
}

const PriorityPropertyEdit = ({ issue }: BaseIssuePropertyEditProps) => {
    const context = trpc.useUtils()
    const { mutate } = trpc.issue.editProperties.useMutation({
        onSuccess: async () => {
            await context.issue.getById.invalidate({
                issueId: issue.id,
            })
        },
    })
    const form = useForm<PrioritySchema>({
        mode: 'onChange',
        resolver: zodResolver(prioritySchema),
        defaultValues: {
            issueId: issue.id,
            priority: issue.priority,
        },
    })

    return (
        <Form {...form}>
            <div>
                <FormField
                    name="priority"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Priority</FormLabel>
                            <FormControl>
                                <Select
                                    onValueChange={(val: IssuePriority | 'null') => {
                                        if (val === 'null') {
                                            mutate({
                                                issueId: issue.id,
                                                priority: null,
                                            })
                                            field.onChange(null)
                                            return
                                        }
                                        field.onChange(val)
                                        mutate({
                                            issueId: issue.id,
                                            priority: val,
                                        })
                                    }}
                                    defaultValue={field.value ?? 'null'}
                                >
                                    <SelectTrigger className="w-full bg-background">
                                        <SelectValue>
                                            {field.value?.toLocaleLowerCase() ?? 'No priority'}
                                        </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectItem value={'null'}>No priority</SelectItem>
                                            <SelectItem value="LOW">Low</SelectItem>
                                            <SelectItem value="MEDIUM">Medium</SelectItem>
                                            <SelectItem value="HIGH">High</SelectItem>
                                            <SelectItem value="URGENT">Urgent</SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </Form>
    )
}

const StatusPropertyEdit = ({ issue }: BaseIssuePropertyEditProps) => {
    const context = trpc.useUtils()
    const { mutate } = trpc.issue.editProperties.useMutation({
        onSuccess: async () => {
            await context.issue.getById.invalidate({
                issueId: issue.id,
            })
        },
    })
    const form = useForm<StatusIdSchema>({
        mode: 'onChange',
        resolver: zodResolver(statusIdSchema),
        defaultValues: {
            issueId: issue.id,
            statusId: issue.statusId,
        },
    })

    return (
        <Form {...form}>
            <div>
                <FormField
                    name="statusId"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Status</FormLabel>
                            <FormControl>
                                <Select
                                    defaultValue={
                                        issue.statusId === null ? undefined : issue.statusId
                                    }
                                    onValueChange={(val) => {
                                        field.onChange(val)
                                        mutate({
                                            issueId: issue.id,
                                            statusId: val,
                                        })
                                    }}
                                >
                                    <SelectTrigger className=" bg-background">
                                        <SelectValue>
                                            {
                                                issue.project.statuses.find(
                                                    (s) => s.id === field.value,
                                                )?.name
                                            }
                                        </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {issue.project.statuses
                                                .sort((a, b) => {
                                                    return (a.sequence ?? 99) - (b.sequence ?? 98)
                                                })
                                                .map((status) => {
                                                    return (
                                                        <SelectItem
                                                            key={status.id}
                                                            value={status.id}
                                                        >
                                                            {status.name}
                                                        </SelectItem>
                                                    )
                                                })}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </Form>
    )
}

const EstimatePropertyEdit = ({ issue }: BaseIssuePropertyEditProps) => {
    const context = trpc.useUtils()
    const { mutate } = trpc.issue.editProperties.useMutation({
        onSuccess: async () => {
            await context.issue.getById.invalidate({
                issueId: issue.id,
            })
        },
    })
    const form = useForm<EstimationStringSchema>({
        mode: 'onChange',
        resolver: zodResolver(estimationStringSchema),
        defaultValues: {
            issueId: issue.id,
            estimationString: convertToTimeString(issue.estimation ?? 0),
        },
    })

    return (
        <Form {...form}>
            <div>
                <FormField
                    name="estimationString"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Estimation</FormLabel>
                            <FormControl>
                                <div className="relative flex ">
                                    <Input
                                        {...field}
                                        value={field.value}
                                        onChange={(val) => field.onChange(val)}
                                    />
                                    <Button
                                        className="absolute right-0 h-full"
                                        onClick={() => {
                                            mutate({
                                                issueId: issue.id,
                                                estimationString: field.value,
                                            })
                                        }}
                                        variant="ghost"
                                    >
                                        <Check className="size-4" />
                                    </Button>
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </Form>
    )
}
