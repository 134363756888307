import { Comment, Issue, Notification as NotificationBase, Project, User } from '@flow/db'
import { z } from 'zod'

export const markNotificationsReadSchema = z.object({
    notificationIds: z.array(z.string()),
})

export const toggleIssueNotificationsSchema = z.object({
    issueId: z.string().nonempty(),
})

export const getIssueNotificationSubscriptionStatusSchema = z.object({
    issueId: z.string().nonempty(),
})

export type MarkNotificationsReadInput = z.infer<typeof markNotificationsReadSchema>
export type ToggleIssueNotificationsInput = z.infer<typeof toggleIssueNotificationsSchema>
export type GetIssueNotificationSubscriptionStatusInput = z.infer<
    typeof getIssueNotificationSubscriptionStatusSchema
>

export type Notification = NotificationBase & {
    user: User
    actor: User | null
    project: Project | null
    comment: Comment | null
    issue: Issue | null
}
