import { createZustandStore } from '@udecode/plate-common'

import { Upload } from './types'

export const createUploadStore = ({
    uploads: initialUploads = {},
}: {
    uploads?: Record<string, Upload>
} = {}) => {
    return createZustandStore('upload')({ uploads: initialUploads })
        .extendActions((set, get) => ({
            upload: (id: string, upload: Upload): void => {
                const uploads = get.uploads()
                set.uploads({ ...uploads, [id]: upload })
            },
        }))
        .extendSelectors((state, get) => ({
            upload: (id: string): Upload | undefined => {
                const uploads = get.uploads()
                return uploads[id]
            },
        }))
}
