import { format } from 'date-fns'
import React from 'react'

export const DateDisplay: React.FC<{ date: Date }> = ({ date }) => {
    return (
        <div className=" text-xl">
            <p>{format(date, 'EEEE, yyyy.MM.dd')}</p>
        </div>
    )
}
