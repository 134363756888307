import { createFileRoute } from '@tanstack/react-router'

export const ColorsPage = () => {
    return (
        <main className="h-full">
            <header className="flex items-center justify-between gap-12 p-4 sm:p-6 lg:px-8">
                <h1 className="text-2xl font-semibold leading-7 text-white">Dashboard</h1>
                <div className="flex size-full space-x-4 text-yellow-400">
                    <div className="size-32 bg-background">background</div>
                    <div className="size-32 bg-foreground">foreground</div>
                    <div className="size-32 bg-primary">primary </div>
                    <div className="size-32 bg-primary-foreground"> primary-fore</div>
                    <div className="size-32 bg-secondary">secondary </div>
                    <div className="size-32 bg-secondary-foreground">secondary-fore </div>
                    <div className="size-32 bg-muted">muted </div>
                    <div className="size-32 bg-muted-foreground">muted-for </div>
                </div>
            </header>
        </main>
    )
}

export const Route = createFileRoute('/_auth/colors')({
    component: ColorsPage,
})
