import { Project, UserRole } from '@flow/db'
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid'
import { Link } from '@tanstack/react-router'

import { ProtectedElement } from '../Primitives/Protected'

export const ProjectList: React.FC<{ projects: Project[] }> = ({ projects }) => {
    return (
        <ul className="flex flex-col gap-4">
            {projects.map((project) => (
                <div
                    key={project.id}
                    className="group relative flex items-center space-x-4 rounded-md border border-gray-500 p-2 hover:bg-gray-800/20"
                >
                    <Link
                        className="flex flex-1 items-center space-x-4 "
                        to={`/project/${project.id}`}
                    >
                        <span className="flex size-12 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[1.625rem] font-medium text-gray-400 group-hover:text-white">
                            {project.id[0]}
                            {project.id[1]}
                        </span>

                        <div className="min-w-0 flex-auto">
                            <div className="flex items-center gap-x-3">
                                <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                                    <p className="flex gap-x-2">
                                        <span className="whitespace-nowrap">{project.name}</span>
                                    </p>
                                </h2>
                            </div>
                            <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400 group-hover:text-white">
                                <p className="truncate">{project.description}</p>
                            </div>
                        </div>
                    </Link>
                    <ProtectedElement authorizedRoles={[UserRole.ADMIN]}>
                        <Link className="h-full" to={`/project/${project.id}/settings`}>
                            <span className="flex size-8 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[1.625rem] font-medium text-gray-400 group-hover:text-white">
                                <AdjustmentsHorizontalIcon className="w-6 text-gray-400 group-hover:text-white" />
                            </span>
                        </Link>
                    </ProtectedElement>
                </div>
            ))}
        </ul>
    )
}
