import { CalendarIcon, Cross2Icon } from '@radix-ui/react-icons'
import { format } from 'date-fns'
import * as React from 'react'

import { cn } from '../../utils/cn'
import { convertToUTCMidday } from '../../utils/date'
import { Button } from '../Primitives/Button'
import { Popover, PopoverContent, PopoverTrigger } from '../Primitives/Popover'
import { Calendar } from './Calendar'

interface DatePickerProps {
    date?: Date
    setDate: (date: Date | undefined) => void
    withClear?: boolean
}

export const DatePicker = ({ setDate, date, withClear = false }: DatePickerProps) => {
    const handleSetDate = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setDate(undefined)
    }

    const dateChange = (date: Date | undefined) => {
        if (!date) return setDate(undefined)
        setDate(convertToUTCMidday(date))
    }

    return (
        <Popover>
            <PopoverTrigger asChild>
                <div className="relative flex">
                    <Button
                        id="date-picker"
                        type="button"
                        variant={'outline'}
                        className={cn('w-[240px] justify-start text-left font-normal')}
                        asChild
                    >
                        <div>
                            <CalendarIcon className="mr-2 size-4" />
                            {date ? format(date, 'yyyy.MM.dd') : <span>Pick a date</span>}
                        </div>
                    </Button>
                    {withClear && (
                        <Button
                            className="absolute right-0 h-full"
                            onClick={handleSetDate}
                            variant="ghost"
                        >
                            <Cross2Icon />
                        </Button>
                    )}
                </div>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                    weekStartsOn={1}
                    mode="single"
                    selected={date ? convertToUTCMidday(date) : undefined}
                    onSelect={dateChange}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    )
}
