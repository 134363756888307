import { GetTimeEntriesOutput } from '@schema'
import { createFileRoute, getRouteApi } from '@tanstack/react-router'
import { startOfWeek } from 'date-fns'
import { z } from 'zod'

import { TimelineDisplay } from '../../../../components/Timeline/TimelineDisplay'
import { BreadCrumbs } from '../../../../utils/breadCrumbs'
import { apiUtils, trpc } from '../../../../utils/trpc'

const routeApi = getRouteApi('/_auth/admin/_admin/timeline')
export const AdminTimelinePage = () => {
    const navigate = routeApi.useNavigate()
    const { startDate, numberOfDays } = routeApi.useSearch()
    const initialData = routeApi.useLoaderData()

    const setDate = (date: Date | undefined) => {
        navigate({
            search: (search) => ({
                ...search,
                startDate:
                    date ??
                    startOfWeek(new Date(), {
                        weekStartsOn: 1,
                    }),
            }),
        })
    }

    const setNumberOfDays = (days: number) => {
        navigate({
            search: (search) => ({
                ...search,
                numberOfDays: days,
            }),
        })
    }

    const { data: timeEntries } = trpc.tracker.getTimeEntriesAdmin.useQuery<GetTimeEntriesOutput[]>(
        undefined,
        {
            initialData: initialData.trackerResults,
        },
    )

    return (
        <TimelineDisplay
            date={startDate}
            setDate={setDate}
            numberOfDays={numberOfDays}
            setNumberOfDays={setNumberOfDays}
            timeEntries={timeEntries}
        />
    )
}

export const Route = createFileRoute('/_auth/admin/_admin/timeline')({
    component: AdminTimelinePage,
    beforeLoad: () => {
        return {
            breadCrumb: BreadCrumbs.AdminTimeline,
        }
    },
    validateSearch: z.object({
        startDate: z
            .string()
            .pipe(z.coerce.date())
            .default(() =>
                startOfWeek(new Date(), {
                    weekStartsOn: 1,
                }).toISOString(),
            ),
        numberOfDays: z.number().default(5),
    }),
    loader: async () => {
        const trackerResults = await apiUtils.tracker.getTimeEntriesAdmin.ensureData()
        return {
            trackerResults,
        }
    },
})
