import { createFileRoute } from '@tanstack/react-router'

import { PasswordForm } from '../../components/User/PasswordForm'
import { PersonalInfoForm } from '../../components/User/PersonalInfoForm'
import { useAuth } from '../../contexts/auth.context'
import { BreadCrumbs } from '../../utils/breadCrumbs'

export const SettingsPage = () => {
    const { user } = useAuth()
    if (!user) {
        return null
    }

    return (
        <div className="relative h-full bg-gray-900">
            <div className="divide-y divide-white/5">
                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-white">
                            Personal Information
                        </h2>
                        <p className="mt-1 text-sm leading-6 text-gray-400">Basic stuff</p>
                    </div>
                    <PersonalInfoForm user={user} />
                </div>

                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-white">
                            Change password
                        </h2>
                        <p className="mt-1 text-sm leading-6 text-gray-400">
                            Update your password associated with your account.
                        </p>
                    </div>
                    <PasswordForm user={user} />
                </div>
            </div>
        </div>
    )
}

export const Route = createFileRoute('/_auth/settings')({
    component: SettingsPage,
    beforeLoad: () => {
        return {
            breadCrumb: BreadCrumbs.ProjectSettings,
        }
    },
})
