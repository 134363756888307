export const BreadCrumbs = {
    AdminDashboard: 'AdminDashboard',
    AdminUsers: 'AdminUsers',
    AdminProjects: 'AdminProjects',
    AdminTimeline: 'AdminTimeline',
    Dashboard: 'Dashboard',
    Inbox: 'Inbox',
    Projects: 'Projects',
    Activity: 'Activity',
    Holidays: 'Holidays',
    Board: 'Board',
    Backlog: 'Backlog',
    Archive: 'Archive',
    List: 'List',
    Timeline: 'Timeline',
    Epic: 'Epic',
    Epics: 'Epics',
    ProjectSettings: 'ProjectSettings',
    Issue: 'Issue',
    Cycles: 'Cycles',
    Cycle: 'Cycle',
} as const

export type BreadCrumbKeys = keyof typeof BreadCrumbs
