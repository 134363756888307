import { Slot } from '@radix-ui/react-slot'
import { cn, withRef } from '@udecode/cn'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

export const buttonVariants = cva(
    'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
    {
        defaultVariants: {
            size: 'default',
            variant: 'default',
        },
        variants: {
            isMenu: {
                true: 'h-auto w-full cursor-pointer justify-start',
            },
            size: {
                default: 'h-10 px-4 py-2',
                icon: 'size-10',
                lg: 'h-11 rounded-md px-8',
                none: '',
                sm: 'h-9 rounded-md px-3',
                sms: 'size-9 rounded-md px-0',
                xs: 'h-8 rounded-md px-3',
            },
            variant: {
                default: 'bg-primary text-primary-foreground hover:bg-primary/90',
                destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
                ghost: 'hover:bg-accent hover:text-accent-foreground',
                inlineLink: 'text-base text-primary underline underline-offset-4',
                link: 'text-primary underline-offset-4 hover:underline',
                outline:
                    'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
                secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
            },
        },
    },
)

export const Button = withRef<
    'button',
    {
        asChild?: boolean
    } & VariantProps<typeof buttonVariants>
>(({ asChild = false, className, isMenu, size, variant, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'

    return (
        <Comp
            className={cn(buttonVariants({ className, isMenu, size, variant }))}
            ref={ref}
            {...props}
        />
    )
})
