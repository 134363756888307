import { UserTimeEntry } from '@schema'
import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'

import { formatDuration } from '../../utils/date'
import { Button } from '../Primitives/Button'
import { Dialog, DialogTrigger } from '../Primitives/Dialog'
import { DataTableColumnHeader } from '../TaskList/DataTableColumnHeader'
import { TimeEntryDeleteModal } from './TimeEntryDeleteModal'
import { TimeEntryModal } from './TimeEntryEditModal'

export const TimeEntryTableColumns: ColumnDef<UserTimeEntry>[] = [
    {
        accessorKey: 'key',
        header: ({ column }) => <DataTableColumnHeader column={column} title="IssueID" />,
        cell: ({ row }) => (
            <div className="w-[80px]">
                #{row.original.issue.projectId}-{row.original.issue.key}
            </div>
        ),
        enableSorting: true,
        enableHiding: true,
    },
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
        cell: ({ row }) => {
            return (
                <div className="flex flex-1 space-x-2">
                    <span className="max-w-[400px] truncate font-medium">
                        {row.original.issue.name}
                    </span>
                </div>
            )
        },
    },
    {
        accessorKey: 'timeSpent',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Time spent" />,
        cell: ({ row }) => {
            return (
                <div className="flex flex-1 space-x-2">
                    <span className="max-w-[400px] truncate font-medium">
                        {formatDuration(row.getValue('timeSpent'))}
                    </span>
                </div>
            )
        },
    },
    {
        accessorKey: 'startDate',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Start Date" />,
        cell: ({ row }) => {
            return (
                <div className="flex flex-1 space-x-2">
                    <span className="max-w-[500px] truncate font-medium">
                        {format(row.getValue('startDate'), 'dd/MM/yyyy')}
                    </span>
                </div>
            )
        },
    },
    {
        accessorKey: 'updatedAt',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Updated at" />,
        cell: ({ row }) => {
            return (
                <div className="flex flex-1 space-x-2">
                    <span className="max-w-[500px] truncate font-medium">
                        {format(row.getValue('updatedAt'), 'dd/MM/yyyy HH:mm')}
                    </span>
                </div>
            )
        },
    },
    {
        id: 'actions',
        cell: ({ row }) => {
            return (
                <div className="flex flex-1 space-x-2">
                    <Dialog key={row.original.id + 'edit'}>
                        <DialogTrigger>
                            <Button asChild variant="secondary">
                                <div>Edit</div>
                            </Button>
                        </DialogTrigger>
                        {row.original && (
                            <TimeEntryModal timeEntry={row.original} key={row.original.id} />
                        )}
                    </Dialog>
                    <Dialog key={row.original.id + 'delete'}>
                        <DialogTrigger>
                            <Button asChild variant="secondary">
                                <div>Delete</div>
                            </Button>
                        </DialogTrigger>
                        <TimeEntryDeleteModal timeEntryId={row.original.id} />
                    </Dialog>
                </div>
            )
        },
    },
]
