import { Label } from '../Primitives/Label'
import { DatePicker } from '../Tracker/DatePicker'

interface Props {
    startDate: Date | undefined
    setStartDate: (date: Date | undefined) => void
    endDate: Date | undefined
    setEndDate: (date: Date | undefined) => void
}

export const DateFilter = ({ startDate, setStartDate, endDate, setEndDate }: Props) => {
    return (
        <div className="space-y-3">
            <div>Filter logged hours</div>
            <div className="flex items-center space-x-2">
                <div className="space-y-2">
                    <Label>Start date</Label>
                    <DatePicker date={startDate} setDate={(value) => setStartDate(value)} />
                </div>
                <div className="space-y-2">
                    <Label>End date</Label>
                    <DatePicker date={endDate} setDate={(value) => setEndDate(value)} />
                </div>
            </div>
        </div>
    )
}
