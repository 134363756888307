import { trpc } from '../../utils/trpc'
import { Button } from '../Primitives/Button'
import {
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '../Primitives/Dialog'

export const TimeEntryDeleteModal: React.FC<{ timeEntryId: string }> = ({ timeEntryId }) => {
    const context = trpc.useUtils()

    const { mutateAsync: deleteEntry } = trpc.tracker.deleteEntry.useMutation({
        onSuccess() {
            context.tracker.getTimeEntriesByUser.invalidate()
        },
    })

    const deleteTimeEntry = async (id: string) => {
        await deleteEntry({ id })
    }

    return (
        <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
                <DialogTitle>Delete tracked entry</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this entry? This action cannot be undone.
                </DialogDescription>
            </DialogHeader>
            <DialogFooter>
                <Button asChild onClick={() => deleteTimeEntry(timeEntryId)} type="submit">
                    <div>Delete entry</div>
                </Button>
            </DialogFooter>
        </DialogContent>
    )
}
