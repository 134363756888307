import { Issue } from '@flow/db'
import { Combobox } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { PlayIcon, StopIcon } from '@radix-ui/react-icons'
import { Link } from '@tanstack/react-router'
import clsx from 'clsx'
import { debounce } from 'lodash'
import { useEffect, useRef, useState } from 'react'

import { useElapsedTime } from '../../../hooks/useTimer'
import { useToast } from '../../../hooks/useToast'
import { trpc } from '../../../utils/trpc'
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '../../Primitives/Accordion'
import { Button } from '../../Primitives/Button'
import { Label } from '../../Primitives/Label'
import { Separator } from '../../Primitives/Separator'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '../../Primitives/Table'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../Primitives/Tabs'
import { TrackerManual } from './TrackerManual'

interface TrackerPopoverProps {
    projectId: string | undefined
    issueId?: string | undefined
}

export const TrackerPopover = ({ projectId, issueId }: TrackerPopoverProps) => {
    const [query, setQuery] = useState('')
    const [selectedIssue, setSelectedIssue] = useState<Issue | null>(null)

    const context = trpc.useUtils()

    const { toast } = useToast()

    const { data: onGoingIssue, isLoading } = trpc.tracker.getOngoingEntry.useQuery()
    const { data: lastEntries } = trpc.tracker.getLastEntries.useQuery()

    const { mutate: mutateStart } = trpc.tracker.start.useMutation({
        onSuccess: async () => {
            await context.tracker.invalidate()
        },
    })

    const { mutate: mutateStop } = trpc.tracker.stop.useMutation({
        onSuccess: async () => {
            await context.tracker.invalidate()
        },
    })

    const debouncedSearch = useRef(
        debounce((query: string) => {
            setQuery(query)
        }, 200),
    ).current

    const { elapsedTime, setStartDate } = useElapsedTime()

    const { data: issue } = trpc.issue.getById.useQuery(
        {
            issueId: issueId || '',
        },
        {
            enabled: !!issueId,
        },
    )

    useEffect(() => {
        if (!issue) return
        setSelectedIssue(issue)
    }, [issue])

    useEffect(() => {
        return () => {
            debouncedSearch.cancel()
        }
    }, [debouncedSearch])

    const { data } = trpc.issue.search.useQuery(
        {
            projectId: projectId || null,
            query,
        },
        {
            enabled: !!projectId && !!query,
        },
    )

    useEffect(() => {
        if (!onGoingIssue) return
        setSelectedIssue(onGoingIssue.issue)
        setStartDate(new Date(onGoingIssue.startDate))
    }, [onGoingIssue, setStartDate])

    const handleStart = async () => {
        if (!selectedIssue) return
        mutateStart({
            issueId: selectedIssue.id,
        })
        toast({
            title: 'Timer started',
            description: `You started tracking on ${selectedIssue.name}`,
        })
    }

    const handleStop = async () => {
        mutateStop()
        toast({
            title: 'Timer stopped',
            description: `You stopped tracking on ${selectedIssue?.name}`,
        })
    }

    if (isLoading) return null

    return (
        <div>
            <Tabs defaultValue={onGoingIssue ? 'timer' : 'manual'} className="w-[500px] space-y-4">
                <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="timer">Timer</TabsTrigger>
                    <TabsTrigger value="manual">Manual</TabsTrigger>
                </TabsList>
                <div>
                    <Label>Select issue</Label>
                    <Combobox
                        as="div"
                        className="w-full"
                        value={selectedIssue}
                        onChange={setSelectedIssue}
                    >
                        <div className="relative">
                            <Combobox.Input
                                className="flex h-9 w-full rounded-md border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                                displayValue={(item: Issue | null) => item?.name || ''}
                                onChange={(event) => debouncedSearch(event.target.value)}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <ChevronUpDownIcon
                                    className="size-5 text-brand-blue"
                                    aria-hidden="true"
                                />
                            </Combobox.Button>

                            <Combobox.Options className="no-scrollbar absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md border bg-popover py-1 text-sm text-popover-foreground shadow-md">
                                {data?.length === 0 && (
                                    <div className="relative flex w-full cursor-default select-none items-center px-4 py-2 ">
                                        No issues found
                                    </div>
                                )}
                                {data?.map((issue) => (
                                    <Combobox.Option
                                        className={({ active }) =>
                                            clsx(
                                                'relative flex w-full cursor-default select-none items-center px-4 py-2',
                                                active ? 'bg-indigo-600 text-white' : '',
                                            )
                                        }
                                        key={issue.id}
                                        value={issue}
                                    >
                                        {issue.name}
                                    </Combobox.Option>
                                ))}
                            </Combobox.Options>
                        </div>
                    </Combobox>
                </div>
                <TabsContent value="timer">
                    <div className="flex flex-col">
                        {onGoingIssue && (
                            <div className="flex flex-col">
                                <Link
                                    to={`project/${onGoingIssue.issue.projectId}/issue/${onGoingIssue.issue.id}`}
                                >
                                    <Label>Current issue:</Label>

                                    <p className="mt-2 text-sm">
                                        <span className="mr-2">
                                            {onGoingIssue.issue.projectId}-{onGoingIssue.issue.key}
                                        </span>
                                        {onGoingIssue.issue.name}
                                    </p>
                                </Link>
                            </div>
                        )}
                        <div className="flex w-full justify-end">
                            {onGoingIssue ? (
                                <div>
                                    Time: {elapsedTime}
                                    <Button
                                        onClick={handleStop}
                                        variant="destructive"
                                        className="ml-8"
                                    >
                                        <StopIcon className="mr-2" />
                                        Stop
                                    </Button>
                                </div>
                            ) : (
                                <Button onClick={handleStart} variant="default">
                                    <PlayIcon className="mr-2" />
                                    Start
                                </Button>
                            )}
                        </div>
                    </div>
                </TabsContent>
                <TabsContent value="manual">
                    <TrackerManual issueId={selectedIssue?.id} />
                </TabsContent>

                <Separator className="my-4" />
                <Accordion type="single" collapsible className="w-full">
                    <AccordionItem className="border-0" value="item-1">
                        <AccordionTrigger>Previous tracked issues</AccordionTrigger>
                        <AccordionContent>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Id</TableHead>
                                        <TableHead>Name</TableHead>
                                        <TableHead className="text-right">Time</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {lastEntries?.map((entry) => {
                                        return (
                                            <TableRow key={entry.id}>
                                                <TableCell className="whitespace-nowrap font-medium">
                                                    {entry.issue.projectId}-{entry.issue.key}
                                                </TableCell>
                                                <TableCell>{entry.issue.name}</TableCell>
                                                <TableCell className="text-right">
                                                    {convertMinutesToTime(entry.timeSpent!)}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            </Tabs>
        </div>
    )
}

const convertMinutesToTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60

    const hoursString = `${hours}h`

    return `${hours ? hoursString : ''} ${remainingMinutes} m`
}
