import { GetIssueOutput } from '@schema'
import { Link } from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'

import { formatDuration } from '../../utils/date'
import { Avatar, AvatarImage } from '../Primitives/Avatar'
import { Checkbox } from '../Primitives/Checkbox'
import { DataTableColumnHeader } from './DataTableColumnHeader'
import { DataTableRowActions } from './DataTableRowActions'

export const Columns: ColumnDef<GetIssueOutput>[] = [
    {
        id: 'select',
        header: ({ table }) => (
            <Checkbox
                checked={table.getIsAllPageRowsSelected()}
                onCheckedChange={(value: any) => table.toggleAllPageRowsSelected(!!value)}
                aria-label="Select all"
                className="translate-y-[2px]"
            />
        ),
        cell: ({ row }) => (
            <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value: any) => row.toggleSelected(!!value)}
                aria-label="Select row"
                className="translate-y-[2px]"
            />
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: 'key',
        header: ({ column }) => <DataTableColumnHeader column={column} title="IssueID" />,
        cell: ({ row }) => (
            <Link to={`/project/${row.original.projectId}/issue/${row.original.id}`}>
                <div className="w-[80px] cursor-pointer">
                    #{row.original.projectId}-{row.getValue('key')}
                </div>
            </Link>
        ),
        enableSorting: true,
        enableHiding: true,
    },
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
        cell: ({ row }) => {
            return (
                <div className="flex flex-1 cursor-pointer space-x-2">
                    <Link to={`/project/${row.original.projectId}/issue/${row.original.id}`}>
                        <span className="max-w-[400px] truncate font-medium">
                            {row.getValue('name')}
                        </span>
                    </Link>
                </div>
            )
        },
    },
    {
        accessorKey: 'assignees',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Assignees" />,
        cell: ({ row }) => {
            const assignees: GetIssueOutput['assignees'] = row.getValue('assignees')
            return (
                <span className="flex max-w-[500px] space-x-1 truncate font-medium">
                    {assignees.map((people) => (
                        <span key={people.id} className="flex items-center">
                            <Avatar className="size-5">
                                <AvatarImage src={people.avatar} />
                            </Avatar>
                        </span>
                    ))}
                </span>
            )
        },
    },
    {
        accessorKey: 'description',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Description" />,
        cell: ({ row }) => {
            return (
                <div className="flex flex-1 space-x-2">
                    <span className="max-w-[500px] truncate font-medium">
                        {row.getValue('description')}
                    </span>
                </div>
            )
        },
    },
    {
        accessorKey: 'timeEntries',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Total Time spent" />,
        cell: ({ row }) => {
            const timeEntries: GetIssueOutput['timeEntries'] = row.getValue('timeEntries')
            const users = new Set<string>()
            const timeSpent = timeEntries.reduce((acc, curr) => {
                const time = curr?.timeSpent ?? 0
                users.add(curr.userId)
                return acc + time
            }, 0)
            return (
                <div className="flex flex-1 space-x-2">
                    <span className="max-w-[500px] truncate font-medium">
                        {formatDuration(timeSpent)} ({users.size})
                    </span>
                </div>
            )
        },
        sortingFn: (a, b) => {
            const timeEntriesA: GetIssueOutput['timeEntries'] = a.getValue('timeEntries')
            const timeEntriesB: GetIssueOutput['timeEntries'] = b.getValue('timeEntries')
            const timeSpentA = timeEntriesA.reduce((acc, curr) => {
                const time = curr?.timeSpent ?? 0
                return acc + time
            }, 0)
            const timeSpentB = timeEntriesB.reduce((acc, curr) => {
                const time = curr?.timeSpent ?? 0
                return acc + time
            }, 0)
            return timeSpentA - timeSpentB
        },
    },
    {
        accessorKey: 'startDate',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Start Date" />,
        cell: ({ row }) => {
            const startDate: GetIssueOutput['startDate'] = row.getValue('startDate')
            return (
                <div className="flex flex-1 space-x-2">
                    <span className="max-w-[500px] truncate font-medium">
                        {startDate && format(startDate, 'yyyy.MM.dd')}
                    </span>
                </div>
            )
        },
    },
    {
        accessorKey: 'targetDate',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Target Date" />,
        cell: ({ row }) => {
            const targetDate: GetIssueOutput['targetDate'] = row.getValue('targetDate')
            return (
                <div className="flex flex-1 space-x-2">
                    <span className="max-w-[500px] truncate font-medium">
                        {targetDate && format(targetDate, 'yyyy.MM.dd')}
                    </span>
                </div>
            )
        },
    },
    {
        id: 'actions',
        cell: ({ row }) => <DataTableRowActions row={row} />,
    },
]
