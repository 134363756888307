import { User } from '@flow/db'
import { GetProjectsOutput } from '@schema'

import { formatToCurrency } from '../../utils/formatToCurrency'
import { Avatar, AvatarImage } from '../Primitives/Avatar'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../Primitives/Card'

interface Props {
    project: GetProjectsOutput
    startDate: Date | undefined
    endDate: Date | undefined
}

export const ProjectCard = ({ project, startDate, endDate }: Props) => {
    const calculateMemberLoggedHours = (member: User) => {
        const userId = member.id
        const loggedMinutes = project.members
            .filter((member) => member.id === userId)
            .map((member) =>
                member.timeEntries
                    .filter((entry) => entry.issue.projectId === project.id)
                    .filter((entry) =>
                        startDate && endDate && entry.endDate
                            ? entry.startDate >= startDate && entry.endDate <= endDate
                            : entry.endDate,
                    )
                    .map((entry) => entry.timeSpent || 0)
                    .reduce((acc, curr) => acc + curr, 0),
            )
        const totalMinutes = loggedMinutes.reduce((acc, curr) => acc + curr, 0)
        return Math.round(totalMinutes / 60)
    }

    const calculateTotalLoggedHours = () => {
        return project.members
            .map((member) => calculateMemberLoggedHours(member))
            .reduce((acc, curr) => acc + curr, 0)
    }

    const calculateMemberCost = (member: User) => {
        return calculateMemberLoggedHours(member) * ((member.salary || 0) / 160)
    }

    const calculateTotalCost = () => {
        return project.members
            .map((member) => calculateMemberCost(member))
            .reduce((acc, curr) => acc + curr, 0)
    }

    return (
        <Card>
            <CardHeader className="mb-5 flex items-center space-x-2 rounded-t-lg border-b bg-slate-200 dark:bg-slate-800">
                <CardTitle className="flex items-center space-x-2">
                    <span className="rounded-sm bg-brand-purple px-1 py-[.2px] text-muted dark:text-primary">
                        {project.id}
                    </span>
                    <span>{project.name}</span>
                </CardTitle>
                <CardDescription>{project.description}</CardDescription>
            </CardHeader>
            <CardContent className="space-y-3 text-sm">
                <div className="flex items-center space-x-2">
                    <span>Total logged hours: {calculateTotalLoggedHours()}</span>
                    <span>Total cost: {formatToCurrency(calculateTotalCost())}</span>
                </div>
                <div className="border-t pt-2 text-center">Members</div>
                <ul className="space-y-3">
                    {project.members.map((member) => (
                        <li key={member.id} className="flex items-center space-x-3">
                            <Avatar>
                                <AvatarImage src={member.avatar} alt={member.name} />
                            </Avatar>
                            <span>{member.name}</span>
                            <div className="flex items-center space-x-2">
                                <span>Logged hours: {calculateMemberLoggedHours(member)}</span>
                                <span>Cost: {formatToCurrency(calculateMemberCost(member))}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            </CardContent>
        </Card>
    )
}
