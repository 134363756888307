import { zodResolver } from '@hookform/resolvers/zod'
import { PlusIcon } from '@radix-ui/react-icons'
import { AddManualTimeSchema, addManualTimeSchema } from '@schema'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { useToast } from '../../../hooks/useToast'
import { trpc } from '../../../utils/trpc'
import { Button } from '../../Primitives/Button'
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../../Primitives/Form'
import { Input } from '../../Primitives/Input'
import { DatePicker } from '../../Tracker/DatePicker'

interface Props {
    issueId: string | undefined
}

export const TrackerManual = ({ issueId }: Props) => {
    const form = useForm<AddManualTimeSchema>({
        resolver: zodResolver(addManualTimeSchema),
        defaultValues: {
            startDate: new Date(),
            issueId,
            timeString: '',
        },
    })

    const context = trpc.useUtils()
    const { toast } = useToast()

    const { mutate } = trpc.tracker.addManualEntry.useMutation({
        onSuccess: async () => {
            toast({
                title: 'Time added',
                description: 'Time has been added to the issue',
            })

            await context.tracker.invalidate()
        },
    })

    const { control, handleSubmit, setValue } = form
    useEffect(() => {
        if (!issueId) return
        setValue('issueId', issueId)
    }, [issueId, setValue])

    const onSubmit = (data: AddManualTimeSchema) => {
        mutate(data)
    }

    return (
        <Form {...form}>
            <form className="flex flex-col items-start space-y-2" onSubmit={handleSubmit(onSubmit)}>
                <FormField
                    control={control}
                    name="startDate"
                    render={({ field: { onChange, value } }) => {
                        return (
                            <FormItem className="flex flex-col">
                                <FormLabel>Start Date</FormLabel>
                                <FormControl>
                                    <DatePicker setDate={(val) => onChange(val)} date={value} />
                                </FormControl>
                            </FormItem>
                        )
                    }}
                />
                <FormField
                    control={form.control}
                    name="timeString"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Time</FormLabel>
                            <FormControl>
                                <Input autoFocus={true} {...field} />
                            </FormControl>
                            <FormMessage />
                            <FormDescription>Use the format: 2w 4d 6h 45m</FormDescription>
                        </FormItem>
                    )}
                />
                <Button type="submit" className="self-end">
                    <PlusIcon className="mr-2" />
                    Add
                </Button>
            </form>
        </Form>
    )
}
