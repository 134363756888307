import { Issue, Project, Status, TimeEntry, User } from '@flow/db'
import { z } from 'zod'

import { checkTimeString } from '../../utils/time'

export const startTrackerSchema = z.object({
    issueId: z.string(),
})

export const addManualTimeSchema = z.object({
    issueId: z.string(),
    startDate: z.date().optional(),
    timeString: z.string().refine(checkTimeString, {
        message: 'Invalid time string',
    }),
})

export const getTimeEntryByIssuesIdSchema = z.object({
    issueId: z.string(),
})

export const getTimeEntriesByProjectSchema = z.object({
    projectId: z.string(),
    startDate: z.date(),
    endDate: z.date(),
})

export const getEntriesByUserSchema = z.object({
    startDate: z.date(),
    endDate: z.date(),
})

export const updateEntrySchema = z.object({
    id: z.string(),
    startDate: z.date().optional(),
    timeString: z.string().refine(checkTimeString, {
        message: 'Invalid time string',
    }),
})

export const deleteEntrySchema = z.object({
    id: z.string(),
})

export type StartTrackerSchema = z.infer<typeof startTrackerSchema>
export type AddManualTimeSchema = z.infer<typeof addManualTimeSchema>
export type GetTimeEntryByIssuesIdSchema = z.infer<typeof getTimeEntryByIssuesIdSchema>
export type GetEntriesByProjectSchema = z.infer<typeof getTimeEntriesByProjectSchema>
export type GetEntriesByUserSchema = z.infer<typeof getEntriesByUserSchema>
export type UpdateEntrySchema = z.infer<typeof updateEntrySchema>
export type DeleteEntrySchema = z.infer<typeof deleteEntrySchema>

export type UserTimeEntry = TimeEntry & {
    issue: Issue & {
        project: Project
    }
}

export type UserTimeEntryByProject = TimeEntry & {
    issue: Issue & {
        status: Status
    }
    user: User
}

export type GetTimeEntriesOutput = TimeEntry & {
    user: User
    issue: Issue & {
        project: Project
        status: Status
    }
}
