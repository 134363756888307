import { zodResolver } from '@hookform/resolvers/zod'
import { DialogClose } from '@radix-ui/react-dialog'
import { UpdateEntrySchema, updateEntrySchema, UserTimeEntry } from '@schema'
import { useForm } from 'react-hook-form'

import { convertToTimeString } from '../../utils/date'
import { trpc } from '../../utils/trpc'
import { Button } from '../Primitives/Button'
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../Primitives/Dialog'
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../Primitives/Form'
import { Input } from '../Primitives/Input'
import { DatePicker } from './DatePicker'

export const TimeEntryModal: React.FC<{ timeEntry: UserTimeEntry }> = ({ timeEntry }) => {
    const form = useForm<UpdateEntrySchema>({
        mode: 'onChange',
        resolver: zodResolver(updateEntrySchema),
        defaultValues: {
            id: timeEntry.id,
            startDate: timeEntry.startDate,
            timeString: convertToTimeString(timeEntry.timeSpent ?? 0),
        },
    })

    const context = trpc.useUtils()

    const { handleSubmit, control, reset } = form

    const { mutateAsync: updateEntry } = trpc.tracker.updateEntry.useMutation({
        onSuccess() {
            context.tracker.getTimeEntriesByUser.invalidate()
        },
    })

    const onSubmit = async (input: UpdateEntrySchema) => {
        await updateEntry(input)
    }

    return (
        <DialogContent className="sm:max-w-[425px]" onOpenAutoFocus={() => reset()}>
            <DialogHeader>
                <DialogTitle>Edit tracked entry</DialogTitle>
                <DialogDescription>
                    Make changes to your time entry here. Click save when you're done.
                </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
                <Form {...form}>
                    <form className="space-y-8">
                        <div className="flex items-center space-x-12">
                            <FormField
                                name="issueName"
                                render={() => (
                                    <FormItem className="flex-1">
                                        <FormLabel>Issue Name</FormLabel>
                                        <FormControl>
                                            <Input value={timeEntry.issue.name} disabled={true} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="flex items-center space-x-12">
                            <FormField
                                control={control}
                                name="startDate"
                                render={({ field }) => (
                                    <FormItem className="flex flex-col">
                                        <FormLabel>Start date</FormLabel>
                                        <FormControl>
                                            <DatePicker
                                                withClear={true}
                                                setDate={(val) => field.onChange(val ?? null)}
                                                date={field.value ?? undefined}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="flex items-center space-x-12">
                            <FormField
                                control={form.control}
                                name="timeString"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Time</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                        <FormDescription>
                                            Use the format: 2w 4d 6h 45m
                                        </FormDescription>
                                    </FormItem>
                                )}
                            />
                        </div>

                        <DialogClose asChild>
                            <Button asChild onClick={handleSubmit(onSubmit)} type="submit">
                                <div>Save changes</div>
                            </Button>
                        </DialogClose>
                    </form>
                </Form>
            </div>
        </DialogContent>
    )
}
