import { GetIssueByAssigneOutput } from '@schema'

import { useTaskFilters } from '../../utils/useTaskFilters'
import { Icons } from '../Plate/Icons'
import { Separator } from '../Primitives/Separator'

export const WeeklyStatusBadge: React.FC<{ taskList: GetIssueByAssigneOutput[] }> = ({
    taskList,
}) => {
    const { completedTasksThisWeek, overdueTasks } = useTaskFilters(taskList)

    return (
        <div className="mt-12 flex h-16 items-center justify-center gap-12 rounded-full bg-secondary px-12 py-2">
            <div>My week</div>
            <Separator orientation={'vertical'} className="bg-primary" />
            <div className="flex items-center gap-2">
                <Icons.check className="size-4" />
                {completedTasksThisWeek.length} tasks completed
            </div>
            <Separator orientation={'vertical'} className="bg-primary" />
            <div>{overdueTasks.length} tasks overdue</div>
        </div>
    )
}
