import { GetHolidaysOutput } from '@schema'
import { ColumnDef } from '@tanstack/react-table'

import { Avatar, AvatarImage } from '../Primitives/Avatar'
import { HolidaysColumnHeader } from './HolidaysColumnHeader'
import { RowActions } from './RowActions'

export const Columns: ColumnDef<GetHolidaysOutput>[] = [
    {
        accessorKey: 'user',
        header: ({ column }) => <HolidaysColumnHeader column={column} title="User" />,
        cell: ({ row }) => (
            <div className="flex items-center gap-2">
                <Avatar className="size-6">
                    <AvatarImage src={row.original.user.avatar} alt={row.original.user.name} />
                </Avatar>
                <span>{row.original.user.name}</span>
            </div>
        ),
    },
    {
        accessorKey: 'start',
        header: ({ column }) => <HolidaysColumnHeader column={column} title="Start date" />,
        cell: ({ row }) => new Date(row.getValue('start')).toDateString(),
    },
    {
        accessorKey: 'end',
        header: ({ column }) => <HolidaysColumnHeader column={column} title="End date" />,
        cell: ({ row }) => new Date(row.getValue('end')).toDateString(),
    },
    {
        accessorKey: 'comment',
        header: ({ column }) => <HolidaysColumnHeader column={column} title="Comment" />,
        cell: ({ row }) => row.getValue('comment'),
    },
    {
        header: 'Actions',
        cell: ({ row }) => <RowActions row={row} />,
        enableSorting: false,
    },
]
