import { DotsHorizontalIcon } from '@radix-ui/react-icons'
import { GetIssueOutput } from '@schema'
import { Row } from '@tanstack/react-table'

import { ConfirmDeleteModal } from '../Issue/ConfirmDeleteModal'
import { Button } from '../Primitives/Button'
import { Dialog, DialogTrigger } from '../Primitives/Dialog'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuTrigger,
} from '../Primitives/DropdownMenu'

interface DataTableRowActionsProps {
    row: Row<GetIssueOutput>
}

export function DataTableRowActions({ row: _row }: DataTableRowActionsProps) {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="flex size-8 p-0 data-[state=open]:bg-muted">
                    <DotsHorizontalIcon className="size-4" />
                    <span className="sr-only">Open menu</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[160px]">
                <DropdownMenuItem>Edit</DropdownMenuItem>
                <DropdownMenuItem>Make a copy</DropdownMenuItem>
                <DropdownMenuItem>Favorite</DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                    <Dialog key={_row.original.id + 'delete'}>
                        <DialogTrigger asChild>
                            <Button variant="outline">Delete</Button>
                        </DialogTrigger>
                        <ConfirmDeleteModal issue={_row.original} />
                    </Dialog>
                    Delete
                    <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
