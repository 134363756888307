import { Value } from '@udecode/plate-common'
import delay from 'delay'

import { getInProgressUploads } from './getInProgressUploads'
import { FinishUploadsOptions, PlateCloudEditor } from './types'

const TEN_MINUTES = 1000 * 60 * 60

export const finishUploads = async <V extends Value>(
    editor: PlateCloudEditor<V>,
    { maxTimeoutInMs = TEN_MINUTES }: FinishUploadsOptions = {},
): Promise<void> => {
    const uploads = editor.attachments.uploadStore.get.uploads()
    const uploadingOrigins = getInProgressUploads(editor.children, uploads)
    const finishPromises = uploadingOrigins.map((origin) => origin.finishPromise)
    const timeoutPromise = delay(maxTimeoutInMs, { value: 'timeout' })
    await Promise.race([Promise.all(finishPromises), timeoutPromise])
}
