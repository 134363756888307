import { Moon, Sun } from 'lucide-react'

import { useTheme } from '../../contexts/next.theme.context'
import { Button } from './Button'
export const ModeToggle = () => {
    const { setTheme, theme } = useTheme()

    return (
        <div>
            <Button
                variant="ghost"
                onClick={() => {
                    setTheme(theme === 'dark' ? 'light' : 'dark')
                }}
                size={'icon'}
            >
                {theme === 'dark' ? <Sun className="size-4" /> : <Moon className="size-4" />}
            </Button>
        </div>
    )
}
