import { Cycle, Epic, GithubOption, Issue, Project, Status, TimeEntry, User } from '@flow/db'
import { z } from 'zod'

export const projectSchema = z.object({
    id: z.string().min(1).max(5),
    name: z.string().min(1).max(255).toUpperCase(),
    description: z.string().min(1).max(255),
})

export const editProjectSchema = z.object({
    id: z.string().min(1).max(5),
    name: z.string().min(1).max(255).toUpperCase(),
    description: z.string().min(1).max(255).nullable(),
    github: z
        .object({
            owner: z.string().min(1).max(255),
            repo: z.string().min(1).max(255),
            defaultBranch: z.string().min(1).max(255),
            githubEnabled: z.boolean(),
        })
        .nullable(),
})

export const addMemberSchema = z.object({
    id: z.string().min(1).max(5),
    userId: z.string(),
})

export const removeMemberSchema = z.object({
    id: z.string().min(1).max(5),
    userId: z.string(),
})

export const getOneSchema = z.object({
    id: z.string().min(1).max(5),
})

export const getForBoardFilterSchema = z.object({
    epicId: z.string().optional(),
    cycleId: z.string().optional(),
    members: z.array(z.string()).nullable().optional(),
})

export const getForBoardSchema = getForBoardFilterSchema.extend({
    id: z.string().min(1).max(5),
})

export type ProjectSchema = z.infer<typeof projectSchema>
export type GetOneSchema = z.infer<typeof getOneSchema>
export type AddMemberSchema = z.infer<typeof addMemberSchema>
export type RemoveMemberSchema = z.infer<typeof removeMemberSchema>
export type GetForBoardSchema = z.infer<typeof getForBoardSchema>
export type EditProjectSchema = z.infer<typeof editProjectSchema>

export type BoardIssue = Issue & {
    assignees: User[]
    epic: Epic | null
    cycle: Cycle | null
}

export type BoardStatus = Status & {
    issues: BoardIssue[]
}

export type ProjectBoard = Project & {
    statuses: BoardStatus[]
    members: User[]
    epics: Epic[]
    cycles: Cycle[]
}

export type ProjectData = Project & {
    members: User[]
    epics: Epic[]
    cycles: Cycle[]
    statuses: Status[]
    githubOptions: GithubOption | null
}

type TimeEntryWithIssue = TimeEntry & {
    issue: Issue & {
        project: Project
    }
}

type UserWithTimeEntries = User & {
    projects: Project[]
    timeEntries: TimeEntryWithIssue[]
}

export type GetProjectsOutput = Project & {
    members: UserWithTimeEntries[]
}
