import {
    Comment,
    Cycle,
    Epic,
    GitBranch,
    Issue,
    IssuePriority,
    Project,
    Reaction,
    State,
    Status,
    TimeEntry,
    User,
} from '@flow/db'
import { z } from 'zod'

import { checkTimeString } from '../../utils/time'

export const moveIssueSchema = z.object({
    issueId: z.string(),
    index: z.number(),
    statusId: z.string(),
})

export const searchIssueSchema = z.object({
    projectId: z.string().optional().nullable(),
    query: z.string().optional().default(''),
})

export const createIssueSchema = z.object({
    name: z.string(),
    status: z.nativeEnum(State).optional().nullable(),
    statusId: z.string().optional().nullable(),
    projectId: z.string(),
    epicId: z.string().optional().nullable(),
    cycleId: z.string().optional().nullable(),
    assignees: z.array(z.string()).optional().nullable(),
})

export const getIssueIdSchema = z.object({
    issueId: z.string(),
})

export const editIssueSchema = z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().nullable().optional(),
    descriptionText: z.any(),
})

const withIssueId = <T extends z.ZodTypeAny>(
    schema: T,
): z.ZodIntersection<T, z.ZodObject<{ issueId: z.ZodString }>> => {
    return schema.and(z.object({ issueId: z.string() }))
}

export const estimationStringSchema = withIssueId(
    z.object({ estimationString: z.string().refine(checkTimeString) }),
)
export const prioritySchema = withIssueId(
    z.object({ priority: z.nativeEnum(IssuePriority).nullable() }),
)
export const startDateSchema = withIssueId(z.object({ startDate: z.date().nullable() }))
export const targetDateSchema = withIssueId(z.object({ targetDate: z.date().nullable() }))
export const statusIdSchema = withIssueId(z.object({ statusId: z.string() }))
export const assigneesSchema = withIssueId(z.object({ assignees: z.array(z.string()) }))
export const epicIdSchema = withIssueId(z.object({ epicId: z.string().nullable() }))
export const cycleIdSchema = withIssueId(z.object({ cycleId: z.string().nullable() }))

export const editIssuePropertiesSchema = z.union([
    estimationStringSchema,
    prioritySchema,
    startDateSchema,
    targetDateSchema,
    statusIdSchema,
    assigneesSchema,
    epicIdSchema,
    cycleIdSchema,
])

export const deleteIssueSchema = z.string()

export const getProjectByIdFilterSchema = z.object({
    states: z.array(z.nativeEnum(State)).optional(),
    statuses: z.array(z.string()).optional(),
    epicId: z.string().optional(),
    cycleId: z.string().optional(),
})

export const getByProjectIdSchema = getProjectByIdFilterSchema.extend({
    projectId: z.string(),
})

export const moveToBoardSchema = z.object({
    projectId: z.string(),
    statusId: z.string(),
    issues: z.array(z.string()),
})

export const createCommentSchema = z.object({
    issueId: z.string(),
    parentId: z.string().nullable().optional(),
    body: z.any(),
    bodyText: z.string().nullable().optional(),
})

export const editCommentSchema = z.object({
    commentId: z.string(),
    body: z.any(),
    bodyText: z.string().nullable().optional(),
})

export const getCommentsByIssueIdSchema = z.object({
    issueId: z.string(),
})

export const deleteCommentSchema = z.object({
    commentId: z.string(),
})

export const createCommentReactionSchema = z.object({
    commentId: z.string(),
    emoji: z.string(),
})

export type MoveIssueInput = z.infer<typeof moveIssueSchema>
export type CreateIssueInput = z.infer<typeof createIssueSchema>
export type SearchIssueSchema = z.infer<typeof searchIssueSchema>
export type GetIssueIdSchema = z.infer<typeof getIssueIdSchema>
export type EditIssueSchema = z.infer<typeof editIssueSchema>
export type GetByProjectIdSchema = z.infer<typeof getByProjectIdSchema>
export type DeleteIssueSchema = z.infer<typeof deleteIssueSchema>
export type MoveToBoardSchema = z.infer<typeof moveToBoardSchema>
export type CreateCommentSchema = z.infer<typeof createCommentSchema>
export type GetCommentsByIssueIdSchema = z.infer<typeof getCommentsByIssueIdSchema>
export type DeleteCommentSchema = z.infer<typeof deleteCommentSchema>
export type EditCommentSchema = z.infer<typeof editCommentSchema>
export type EditIssuePropertiesSchema = z.infer<typeof editIssuePropertiesSchema>
export type EstimationStringSchema = z.infer<typeof estimationStringSchema>
export type PrioritySchema = z.infer<typeof prioritySchema>
export type StartDateSchema = z.infer<typeof startDateSchema>
export type TargetDateSchema = z.infer<typeof targetDateSchema>
export type StatusIdSchema = z.infer<typeof statusIdSchema>
export type AssigneesSchema = z.infer<typeof assigneesSchema>
export type EpicIdSchema = z.infer<typeof epicIdSchema>
export type CycleIdSchema = z.infer<typeof cycleIdSchema>
export type CreateCommentReactionSchema = z.infer<typeof createCommentReactionSchema>

export type GetIssueOutput = Issue & {
    status: Status
    epic: Epic | null
    cycle: Cycle | null
    assignees: User[]
    project: Project & {
        epics: Epic[]
        cycles: Cycle[]
        members: User[]
        statuses: Status[]
    }
    timeEntries: TimeEntry[]
    createdBy: User | null
    gitBranches: GitBranch[]
}

export type GetByProjectIdOutput = {
    issues: GetIssueOutput[]
    project: Project
}

export type GetIssueByAssigneOutput = Issue & {
    status: Status
    assignees: User[]
    project: Project
}

export type ReactionWithUser = Reaction & {
    user: User
}

export type CommentWithUser = Comment & {
    user: User
    reactions: ReactionWithUser[]
}

export type CommentWithChildAndUser = CommentWithUser & {
    childComments: CommentWithUser[]
}
