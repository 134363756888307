import { GetIssueOutput } from '@schema'
import { useLocation, useNavigate } from '@tanstack/react-router'

import { trpc } from '../../utils/trpc'
import { Button } from '../Primitives/Button'
import {
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '../Primitives/Dialog'

export const ConfirmDeleteModal: React.FC<{ issue: GetIssueOutput }> = ({ issue }) => {
    const nav = useNavigate()
    const location = useLocation()
    const context = trpc.useUtils()

    const { mutateAsync: deleteIssue } = trpc.issue.delete.useMutation({
        onSuccess: async () => {
            await context.issue.invalidate()
            if (location.pathname.includes('issue')) {
                nav({
                    to: `/project/${issue.project.id}`,
                })
            }
        },
    })

    return (
        <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
                <DialogTitle>Delete issue</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this issue? This action cannot be undone.
                </DialogDescription>
            </DialogHeader>
            <DialogFooter>
                <Button onClick={async () => deleteIssue(issue.id)} type="submit">
                    Delete issue
                </Button>
            </DialogFooter>
        </DialogContent>
    )
}
