import { Holiday } from '@flow/db'
import { z } from 'zod'

export const createHolidaySchema = z.object({
    userId: z.string(),
    start: z.date(),
    end: z.date(),
    comment: z.string().optional(),
})

export const getHolidayIdSchema = z.object({
    id: z.string(),
})

export const editHolidaySchema = z.object({
    id: z.string(),
    userId: z.string(),
    start: z.date(),
    end: z.date(),
    comment: z.string(),
})

export const deleteHolidaySchema = z.object({
    id: z.string(),
})

export type CreateHolidaySchema = z.infer<typeof createHolidaySchema>
export type GetHolidayIdSchema = z.infer<typeof getHolidayIdSchema>
export type EditHolidaySchema = z.infer<typeof editHolidaySchema>
export type DeleteHolidaySchema = z.infer<typeof deleteHolidaySchema>

export type GetHolidaysOutput = Holiday & {
    id: string
    start: Date
    end: Date
    comment: string | null
    user: { name: string; avatar: string }
}
