import { TimeEntry } from '@flow/db'
import { createFileRoute, getRouteApi } from '@tanstack/react-router'
import { addDays, format, isSameDay, startOfWeek } from 'date-fns'
import { z } from 'zod'

import { Avatar, AvatarImage } from '../../components/Primitives/Avatar'
import { Label } from '../../components/Primitives/Label'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../components/Primitives/Select'
import { DatePicker } from '../../components/Tracker/DatePicker'
import { LoggedEntryBar } from '../../components/Tracker/LoggedEntryBar'
import { TimeEntryTable } from '../../components/Tracker/TimeEntryTable'
import { TimeEntryTableColumns } from '../../components/Tracker/TimeEntryTableColumns'
import { useAuth } from '../../contexts/auth.context'
import { BreadCrumbs } from '../../utils/breadCrumbs'
import { cn } from '../../utils/cn'
import { getGrid } from '../../utils/grid'
import { trpc } from '../../utils/trpc'

const routeApi = getRouteApi('/_auth/activity')

const ActivityPage = () => {
    const { user } = useAuth()

    const { date, numberOfDays } = routeApi.useLoaderDeps()
    const navigate = routeApi.useNavigate()
    const { data: timeEntriesByUser } = trpc.tracker.getTimeEntriesByUser.useQuery(
        {
            startDate: date || new Date(),
            endDate: addDays(date || new Date(), numberOfDays),
        },
        {
            enabled: !!date,
        },
    )

    const setDate = (date: Date | undefined) => {
        navigate({
            search: (search) => {
                return {
                    ...search,
                    date: date?.toISOString() || undefined,
                }
            },
        })
    }

    const setNumberOfDays = (numberOfDays: string) => {
        navigate({
            search: (search) => {
                return {
                    ...search,
                    numberOfDays: Number(numberOfDays) || undefined,
                }
            },
        })
    }

    const days = Array.from({ length: numberOfDays }, (_, i) => i)

    const getDailyTimeEntries = (entries: TimeEntry[]) => {
        const dailyEntries = entries.reduce((acc, curr: TimeEntry) => {
            if (curr.timeSpent) {
                return acc + curr.timeSpent
            } else return acc
        }, 0)

        return <LoggedEntryBar loggedMinutes={dailyEntries} />
    }

    if (!user) return null

    return (
        <div className="relative flex size-full flex-col text-base text-primary">
            <main className="mt-4 h-full px-8 pb-12">
                <div className="flex h-full flex-col">
                    <div className="flex items-center  space-x-2">
                        <div className="flex flex-col space-y-2">
                            <Label htmlFor="date-picker">Start Date</Label>
                            <DatePicker setDate={setDate} date={date} />
                        </div>
                        <div className="flex flex-col space-y-2">
                            <Label htmlFor="date-picker">Number of days</Label>

                            <Select value={numberOfDays.toString()} onValueChange={setNumberOfDays}>
                                <SelectTrigger className="w-[180px]">
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectItem value="1">1</SelectItem>
                                        <SelectItem value="2">2</SelectItem>
                                        <SelectItem value="3">3</SelectItem>
                                        <SelectItem value="4">4</SelectItem>
                                        <SelectItem value="5">5</SelectItem>
                                        <SelectItem value="6">6</SelectItem>
                                        <SelectItem value="7">7</SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>

                    <div className="mt-8 grid  grid-cols-8 border-b">
                        <div />
                        <div className="col-span-7">
                            <div className={cn(`grid w-full pb-4`, getGrid(numberOfDays))}>
                                {date &&
                                    days?.map((day) => (
                                        <div key={day} className="col-span-1">
                                            <div className="text-center text-xs">
                                                {format(
                                                    new Date(
                                                        date!.getFullYear(),
                                                        date!.getMonth(),
                                                        date!.getDate() + day,
                                                    ),
                                                    'EEEE MMM d',
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="col-span-1 flex items-start border-t p-4">
                            <div className="flex items-center">
                                <Avatar className="mr-2 size-8">
                                    <AvatarImage src={user.avatar} />
                                </Avatar>
                                <h2 className="text-base">{user.name}</h2>
                            </div>
                        </div>
                        <div className={cn('col-span-7 border-t p-4')}>
                            <div className={cn(`grid w-full`, getGrid(numberOfDays))}>
                                {timeEntriesByUser &&
                                    days?.map((day, index) => {
                                        const TT = new Date(
                                            date!.getFullYear(),
                                            date!.getMonth(),
                                            date!.getDate() + day,
                                        )
                                        const entries = timeEntriesByUser.filter((t) => {
                                            return isSameDay(new Date(t.startDate), TT)
                                        })
                                        return (
                                            <div key={`${day}-${index}`} className="col-span-1">
                                                {entries.length > 0 && (
                                                    <div className="flex flex-col space-y-2 p-4 text-xs">
                                                        {getDailyTimeEntries(entries)}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-8 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 lg:px-8">
                        <div className="">
                            <h2 className="text-base font-semibold leading-7 text-white">
                                Tracked issues
                            </h2>
                            <p className="mt-1 text-sm leading-6 text-gray-400">
                                Manage your timelogs
                            </p>
                        </div>
                        <div className="col-span-7 w-full">
                            {timeEntriesByUser && (
                                <TimeEntryTable
                                    columns={TimeEntryTableColumns}
                                    data={timeEntriesByUser}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export const Route = createFileRoute('/_auth/activity')({
    component: ActivityPage,
    validateSearch: z.object({
        date: z.string().optional(),
        numberOfDays: z.number().optional(),
    }),
    loaderDeps: ({ search }) => {
        return {
            date: search.date
                ? new Date(search.date)
                : startOfWeek(new Date(), {
                      weekStartsOn: 1,
                  }),
            numberOfDays: search.numberOfDays ?? 5,
        }
    },
    beforeLoad: () => {
        return {
            breadCrumb: BreadCrumbs.Activity,
        }
    },
})
