import { z } from 'zod'

export const createBranchSchema = z.object({
    issueId: z.string(),
    branchName: z.string(),
})

export const deleteBranchSchema = z.object({
    branchId: z.string(),
})

export const createPullRequestSchema = z.object({
    branchId: z.string(),
    title: z.string().optional().nullable(),
    body: z.string().optional().nullable(),
    reviewers: z.array(z.string()).optional().nullable(),
})

export const deletePullRequestSchema = z.object({
    pullRequestId: z.string(),
})

export const getBranchesSchema = z.object({
    issueId: z.string(),
})

export type CreateBranchSchema = z.infer<typeof createBranchSchema>
export type DeleteBranchSchema = z.infer<typeof deleteBranchSchema>
export type CreatePullRequestSchema = z.infer<typeof createPullRequestSchema>
export type DeletePullRequestSchema = z.infer<typeof deletePullRequestSchema>
export type GetBranchesSchema = z.infer<typeof getBranchesSchema>
