import { Attachment } from '@flow/db'
import { Value } from '@udecode/plate-common'

import { uploadFile as _uploadFile } from '../../../../utils/uploadFile'
import { PlateCloudEditor } from './types'

export const uploadFile = async <V extends Value = Value>(
    editor: PlateCloudEditor<V>,
    file: File,
) => {
    try {
        const resp: Attachment = await _uploadFile(file)
        editor?.attachments.genericFileHandlers?.onSuccess?.({
            id: resp.id,
            fileType: resp.type,
            name: resp.name,
            url: resp.url,
        })
    } catch (err: any) {
        console.log(err)
    }
}

export const uploadFiles = <V extends Value = Value>(
    editor: PlateCloudEditor<V>,
    files: Iterable<File>,
) => {
    for (const file of files) {
        uploadFile(editor, file)
    }
}
