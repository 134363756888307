// this is necessary to get the correct grid for the timeline bc of tailwind
export const getGrid = (totalDays: number | undefined) => {
    switch (totalDays) {
        case 1:
            return 'grid-cols-1'
        case 2:
            return 'grid-cols-2'
        case 3:
            return 'grid-cols-3'
        case 4:
            return 'grid-cols-4'
        case 5:
            return 'grid-cols-5'
        case 6:
            return 'grid-cols-6'
        case 7:
            return 'grid-cols-7'
        default:
            return 'grid-cols-7'
    }
}
