import { trpc } from '../../utils/trpc'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '../Primitives/AlertDialog'

interface DeleteCommentDialogProps {
    setClose: () => void
    commentId: null | string
}

export const DeleteCommentDialog = ({ commentId, setClose }: DeleteCommentDialogProps) => {
    const context = trpc.useUtils()
    const { mutateAsync } = trpc.issue.deleteComment.useMutation({
        onSuccess: async () => {
            await context.issue.getCommentsByIssueId.invalidate()
        },
    })

    const handleDelete = async () => {
        if (!commentId) return
        await mutateAsync({
            commentId,
        })

        setClose()
    }
    return (
        <AlertDialog open={commentId !== null} onOpenChange={setClose}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                        This action cannot be undone. This will permanently delete the comment.
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel onClick={setClose}>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={handleDelete}>Continue</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
