import { z } from 'zod'

import { checkTimeString } from '../../utils/time'

export const getByProjectSchema = z.object({
    projectId: z.string(),
})

export const createEpicSchema = z.object({
    projectId: z.string(),
    name: z.string(),
    description: z.string(),
    estimationString: z.string().refine(checkTimeString),
    startDate: z.date().nullable(),
    targetDate: z.date().nullable(),
})

export const editEpicSchema = z.object({
    id: z.string(),
    projectId: z.string(),
    name: z.string(),
    description: z.string(),
    estimationString: z.string().refine(checkTimeString),
    startDate: z.date().nullable(),
    targetDate: z.date().nullable(),
})

export const deleteEpicSchema = z.object({
    id: z.string(),
})

export type GetByProjectSchema = z.infer<typeof getByProjectSchema>
export type CreateEpicSchema = z.infer<typeof createEpicSchema>
export type EditEpicSchema = z.infer<typeof editEpicSchema>
export type DeleteEpicSchema = z.infer<typeof deleteEpicSchema>
