import { EditUserSchema } from '@schema'
import { useForm } from 'react-hook-form'

import { UserOutput } from '../../contexts/auth.context'
import { useToast } from '../../hooks/useToast'
import { trpc } from '../../utils/trpc'

export const PersonalInfoForm: React.FC<{ user: UserOutput }> = ({ user }) => {
    const { register, handleSubmit } = useForm<EditUserSchema>({
        defaultValues: {
            name: user.name,
            email: user.email || undefined,
        },
    })
    const { toast } = useToast()
    const utils = trpc.useUtils()

    const { mutate: editUser } = trpc.auth.editUser.useMutation({
        onSuccess() {
            utils.auth.getMe.invalidate()
            toast({
                title: 'Profile updated',
                description: 'Your profile has been updated',
            })
        },
    })

    const onSubmit = async (data: EditUserSchema) => {
        await editUser(data)
    }

    return (
        <form className="md:col-span-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div className="col-span-full flex items-center gap-x-8">
                    <img
                        src={user.avatar || ''}
                        alt=""
                        className="size-24 flex-none rounded-lg bg-gray-800 object-cover"
                    />
                    <div>
                        <button
                            type="button"
                            className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                        >
                            Change avatar
                        </button>
                        <p className="mt-2 text-xs leading-5 text-gray-400">
                            JPG, GIF or PNG. 1MB max.
                        </p>
                    </div>
                </div>

                <div className="col-span-full">
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium leading-6 text-white"
                    >
                        Name
                    </label>
                    <div className="mt-2">
                        <input
                            {...register('name')}
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 pl-4 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-brand-purple sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

                <div className="col-span-full">
                    <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-white"
                    >
                        Email address
                    </label>
                    <div className="mt-2">
                        <input
                            {...register('email')}
                            disabled={true}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 pl-4 text-gray-400 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-brand-blue sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
            </div>

            <div className="mt-8 flex">
                <button
                    type="submit"
                    className="rounded-md bg-brand-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-blue/60 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-blue"
                >
                    Save
                </button>
            </div>
        </form>
    )
}
