import { createFileRoute } from '@tanstack/react-router'
import { useState } from 'react'

import { Button } from '../../components/Primitives/Button'
import { ScrollArea } from '../../components/Primitives/ScrollArea'
import { CreateProjectModal } from '../../components/Project/CreateModal'
import { ProjectList } from '../../components/Projects/ProjectList'
import { BreadCrumbs } from '../../utils/breadCrumbs'
import { trpc } from '../../utils/trpc'

export const Projects = () => {
    const [isOpen, setIsOpen] = useState(false)

    const { data: projects, isLoading } = trpc.project.getAll.useQuery()

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    if (isLoading) return <div>Loading...</div>
    if (!projects) return <div>Something went wrong</div>

    return (
        <ScrollArea>
            <main className="h-full pl-4 lg:pr-96">
                <header className="my-6 flex items-center justify-between">
                    <Button variant="default" onClick={openModal}>
                        Create
                    </Button>
                </header>
                <ProjectList projects={projects} />
            </main>
            <CreateProjectModal closeModal={closeModal} isOpen={isOpen} />
        </ScrollArea>
    )
}

export const Route = createFileRoute('/_auth/projects')({
    component: Projects,
    beforeLoad: () => {
        return {
            breadCrumb: BreadCrumbs.Projects,
        }
    },
})
