import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInSeconds,
    format,
} from 'date-fns'
import { match, P } from 'ts-pattern'

export const convertToUTCMidday = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8)
}

// refactor chat gpt and centralize formatting logic
export const formatDuration = (minutes: number): string => {
    // Constants for the minutes in each time period
    const minutesPerHour = 60
    const hoursPerDay = 8
    const daysPerWeek = 5
    const minutesPerDay = minutesPerHour * hoursPerDay
    const minutesPerWeek = minutesPerDay * daysPerWeek

    // Calculate the number of weeks, days, hours, and minutes
    const weeks = Math.floor(minutes / minutesPerWeek)
    minutes %= minutesPerWeek
    const days = Math.floor(minutes / minutesPerDay)
    minutes %= minutesPerDay
    const hours = Math.floor(minutes / minutesPerHour)
    minutes %= minutesPerHour

    // Format the result, omitting any time periods that are zero
    const result: string[] = []
    if (weeks > 0) {
        result.push(`${weeks}w`)
    }
    if (days > 0) {
        result.push(`${days}d`)
    }
    if (hours > 0) {
        result.push(`${hours}h`)
    }
    if (minutes > 0) {
        result.push(`${minutes}m`)
    }

    // Return the result as a string, separated by spaces
    return result.join(' ')
}

export const convertToTimeString = (minutes: number): string => {
    if (minutes <= 0) {
        return ''
    }

    const workWeekMinutes = 5 * 8 * 60 // 5 days * 8 hours/day * 60 minutes/hour
    const workDayMinutes = 8 * 60 // 8 hours/day * 60 minutes/hour

    const weeks = Math.floor(minutes / workWeekMinutes)
    const days = Math.floor((minutes % workWeekMinutes) / workDayMinutes)
    const hours = Math.floor((minutes % workDayMinutes) / 60)
    const remainingMinutes = minutes % 60

    const parts: string[] = []
    if (weeks > 0) {
        parts.push(`${weeks}w`)
    }
    if (days > 0) {
        parts.push(`${days}d`)
    }
    if (hours > 0) {
        parts.push(`${hours}h`)
    }
    if (remainingMinutes > 0) {
        parts.push(`${remainingMinutes}m`)
    }

    return parts.join(' ')
}

export const timeAgo = (date: Date): string => {
    const now: Date = new Date()
    const timeDifferences = {
        secondsAgo: differenceInSeconds(now, date),
        minutesAgo: differenceInMinutes(now, date),
        hoursAgo: differenceInHours(now, date),
        daysAgo: differenceInDays(now, date),
    }

    return match(timeDifferences)
        .with({ secondsAgo: P.number.lt(60) }, ({ secondsAgo }) => `${secondsAgo} seconds ago`)
        .with({ minutesAgo: P.number.lt(60) }, ({ minutesAgo }) => `${minutesAgo} mins ago`)
        .with({ hoursAgo: P.number.lt(24) }, ({ hoursAgo }) => `${hoursAgo} hours ago`)
        .otherwise(() => format(date, 'yyyy.MM.dd HH:mm'))
}
