import { Plate, PlateEditor, Value } from '@udecode/plate-common'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import { cn } from '../../utils/cn'
import { CursorOverlay } from './_plate-ui/cursor-overlay'
import { Editor } from './_plate-ui/editor'
import { withOutPlaceholderPlugins } from './plugins'

interface BaseEditorProps {
    onChange?: (value: Value) => void
    value: Value | undefined
    contentEditable?: boolean
    placeholder?: string
}

export const CommentEditor = forwardRef<PlateEditor, BaseEditorProps>(
    ({ onChange, value, contentEditable = true, placeholder }, ref) => {
        const containerRef = useRef<HTMLDivElement>(null)
        const editorRef = useRef<PlateEditor>(null)

        useImperativeHandle(ref, () => editorRef.current as PlateEditor)

        return (
            <Plate
                editorRef={editorRef}
                readOnly={!contentEditable}
                value={value}
                onChange={onChange}
                plugins={withOutPlaceholderPlugins}
            >
                <Editor
                    variant={'outline'}
                    focusRing={false}
                    placeholder={placeholder}
                    className={cn(
                        'w-full outline-none scrollbar-hide bg-transparent h-auto border-none px-0',
                    )}
                />
                <CursorOverlay containerRef={containerRef} />
            </Plate>
        )
    },
)
