import { zodResolver } from '@hookform/resolvers/zod'
import { AuthSchema, authSchema } from '@schema'
import { createFileRoute } from '@tanstack/react-router'
import { useNavigate } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'

import { Button } from '../components/Primitives/Button'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '../components/Primitives/Card'
import { Input } from '../components/Primitives/Input'
import { Label } from '../components/Primitives/Label'
import { useAuth } from '../contexts/auth.context'
import { trpc } from '../utils/trpc'

export const Login = () => {
    const { login } = useAuth()
    const { mutateAsync } = trpc.auth.login.useMutation()
    const navigate = useNavigate()

    const { register, handleSubmit } = useForm<AuthSchema>({
        resolver: zodResolver(authSchema),
        defaultValues: {
            email: '',
            password: '',
        },
    })

    const onSubmit = async (data: AuthSchema) => {
        const { jwt } = await mutateAsync(data)
        await login(jwt)
        navigate({
            to: '/',
            viewTransition: true,
        })
    }

    return (
        <div className="flex min-h-screen flex-1 flex-col items-center justify-center bg-secondary px-6 py-12 lg:px-12">
            <div className="mb-12 sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-20 w-auto rounded-full border-2 border-white/20"
                    src="/logo.png"
                    alt="Your Company"
                />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Card className="w-full max-w-sm">
                    <CardHeader>
                        <CardTitle className="text-2xl">Login</CardTitle>
                        <CardDescription>
                            Enter your email below to login to your account.
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="grid gap-4">
                        <div className="grid gap-2">
                            <Label htmlFor="email">Email</Label>
                            <Input
                                id="email"
                                type="email"
                                placeholder="m@example.com"
                                {...register('email')}
                            />
                        </div>
                        <div className="grid gap-2">
                            <Label htmlFor="password">Password</Label>
                            <Input id="password" type="password" {...register('password')} />
                        </div>
                    </CardContent>
                    <CardFooter>
                        <Button type="submit" className="w-full">
                            Sign in
                        </Button>
                    </CardFooter>
                </Card>
            </form>
        </div>
    )
}

export const Route = createFileRoute('/login')({
    component: Login,
})
