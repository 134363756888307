import { zodResolver } from '@hookform/resolvers/zod'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { IdCardIcon } from '@radix-ui/react-icons'
import { EditIssueSchema, editIssueSchema, GetIssueOutput } from '@schema'
import { Value } from '@udecode/slate'
import { format } from 'date-fns'
import { CopyIcon } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { useTaskStore } from '../../hooks/useTaskStore'
import { useToast } from '../../hooks/useToast'
import { copyToClipboard } from '../../utils/clipboard'
import { formatDuration } from '../../utils/date'
import { serialize } from '../../utils/editorSerialize'
import { trpc } from '../../utils/trpc'
import { BaseEditor } from '../Plate/BaseEditor'
import { Avatar, AvatarImage } from '../Primitives/Avatar'
import { Button } from '../Primitives/Button'
import { Dialog } from '../Primitives/Dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../Primitives/Form'
import { Input } from '../Primitives/Input'
import { Label } from '../Primitives/Label'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../Primitives/Tooltip'
import { CommentContainer } from './CommentBox'
import { ConfirmDeleteModal } from './ConfirmDeleteModal'
import { GithubActions } from './GithubActions'
import { IssueNotificationButton } from './IssueNotificationButton'
import { IssuePropertyEdit } from './IssuePropertyEdit'

export const IssueEdit: React.FC<{ issue: GetIssueOutput }> = ({ issue }) => {
    const context = trpc.useUtils()
    const { toast } = useToast()
    const getDescription = useTaskStore((s) => s.getDescription)

    const [hasCopied, setHasCopied] = useState(false)
    const { mutateAsync } = trpc.issue.edit.useMutation({
        onSuccess: async () => {
            await context.issue.invalidate()
        },
    })

    useEffect(() => {
        setTimeout(() => {
            setHasCopied(false)
        }, 2000)
    }, [hasCopied])

    const { data } = trpc.tracker.getTimeEntryByIssue.useQuery({
        issueId: issue.id,
    })
    const form = useForm<EditIssueSchema>({
        mode: 'onChange',
        resolver: zodResolver(editIssueSchema),
        defaultValues: {
            id: issue.id,
            name: issue.name,
        },
    })

    const onSubmit = async (input: EditIssueSchema) => {
        const descriptionText = getDescription()
        const description = serialize((descriptionText as Value) ?? [])
        await mutateAsync({
            ...input,
            description,
            descriptionText,
        })
        toast({
            title: 'Issue updated',
            description: 'Your issue has been updated',
        })
    }

    const { handleSubmit, control } = form

    return (
        <Form {...form}>
            <div className="flex w-full">
                <div className="flex-1">
                    <div className="mx-auto flex max-w-[1000px] flex-col space-y-4">
                        <div className="flex">
                            <h3 className="flex items-center text-xl">
                                <TooltipProvider>
                                    <Tooltip open={hasCopied} delayDuration={0}>
                                        <TooltipTrigger>
                                            <div
                                                onClick={() => {
                                                    copyToClipboard(
                                                        `#${issue.project?.id}-${issue.key} ${issue.name}`,
                                                    )
                                                    setHasCopied(true)
                                                }}
                                                className="mb-2 flex items-center text-muted-foreground"
                                            >
                                                <IdCardIcon className="mr-2 size-5 text-indigo-400" />{' '}
                                                <span className="text-primary">
                                                    #{issue.projectId}-{issue.key}
                                                </span>
                                                <CopyIcon className="ml-2 size-5 text-primary" />
                                            </div>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            {hasCopied ? 'Copied' : 'Copy'}
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            </h3>
                            <div className="flex flex-1 justify-end gap-4">
                                <IssueNotificationButton issueId={issue.id} />
                                <Button onClick={handleSubmit(onSubmit)}>Update Issue</Button>
                                <Dialog>
                                    <DialogTrigger asChild>
                                        <Button variant="destructive">Delete Issue</Button>
                                    </DialogTrigger>
                                    <ConfirmDeleteModal issue={issue} />
                                </Dialog>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <FormField
                                control={control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem className="flex-1">
                                        <FormLabel>Name</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <BaseEditor
                            users={issue.project.members}
                            defaultValue={issue.descriptionText as Value | null}
                        />
                        <CommentContainer issueId={issue.id} />
                    </div>
                </div>
                <div className="flex max-w-[550px] flex-col space-y-4 px-12">
                    <h2>Details</h2>
                    <IssuePropertyEdit issue={issue} />
                    <div>
                        <Label>Stats</Label>
                        <div className="grid w-full grid-cols-2 gap-4 text-sm">
                            <div className="flex w-full flex-col space-y-2">
                                <p className="text-xs">Created at:</p>
                                <div className="mb-2 flex items-center space-x-2">
                                    <span className="text-xs">
                                        {format(issue.createdAt, 'yyyy.MM.dd HH:mm')}
                                    </span>
                                </div>
                            </div>
                            {issue.createdBy && (
                                <div className="flex w-full flex-col space-y-2">
                                    <p className="text-xs">Created by: </p>
                                    <div className="mb-2 flex items-center space-x-2">
                                        <Avatar className="size-8">
                                            <AvatarImage src={issue.createdBy.avatar} />
                                        </Avatar>
                                        <span className="text-base">{issue.createdBy.name}</span>
                                    </div>
                                </div>
                            )}
                            {data?._sum.timeSpent && (
                                <div className="flex w-full flex-col items-start space-y-2">
                                    <p className="text-xs">Timespent: </p>
                                    <div className="rounded-md bg-primary px-2 py-1 text-xs text-muted">
                                        {formatDuration(data?._sum.timeSpent)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {issue.project.githubOptionsId && <GithubActions issue={issue} />}
                </div>
            </div>
        </Form>
    )
}
