import { GetHolidaysOutput } from '@schema'
import { Row } from '@tanstack/react-table'

import { DeleteHoliday } from './DeleteHoliday'
import { EditHoliday } from './EditHoliday'

interface RowActionsProps {
    row: Row<GetHolidaysOutput>
}

export function RowActions({ row }: RowActionsProps) {
    return (
        <div className="flex items-center gap-2">
            <EditHoliday
                id={row.original.id}
                userId={row.original.userId}
                start={row.original.start}
                end={row.original.end}
                comment={row.original.comment || ''}
            />
            <DeleteHoliday id={row.original.id} />
        </div>
    )
}
