import { Value } from '@udecode/slate'
import { Node } from 'slate'

export const serialize = (nodes: Value) => {
    return nodes.map((n) => Node.string(n)).join('\n')
}

export const parseJson = (text: string): Value | undefined => {
    try {
        return JSON.parse(text)
    } catch (e) {
        return undefined
    }
}
