import { useEffect } from 'react'

import { useElapsedTime } from '../../hooks/useTimer'

interface Props {
    startTime: Date
}

export const CountDown = ({ startTime }: Props) => {
    const { elapsedTime, setStartDate } = useElapsedTime()

    useEffect(() => {
        if (startTime) {
            setStartDate(startTime)
        }
    }, [setStartDate, startTime])
    return <span>{elapsedTime}</span>
}
