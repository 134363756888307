import { zodResolver } from '@hookform/resolvers/zod'
import { EditProjectSchema, editProjectSchema, ProjectData } from '@schema'
import { useForm } from 'react-hook-form'

import { useToast } from '../../hooks/useToast'
import { trpc } from '../../utils/trpc'
import { Button } from '../Primitives/Button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../Primitives/Form'
import { Input } from '../Primitives/Input'
import { Switch } from '../Primitives/Switch'

interface ProjectSettingsFormProps {
    project: ProjectData
}

export const ProjectSettingsForm = ({ project }: ProjectSettingsFormProps) => {
    const form = useForm<EditProjectSchema>({
        defaultValues: {
            id: project.id,
            description: project.description || '',
            name: project.name,
            github: {
                owner: project.githubOptions?.owner || '',
                repo: project.githubOptions?.repo || '',
                defaultBranch: project.githubOptions?.defaultBranch || '',
                githubEnabled: project.githubOptions?.githubEnabled || false,
            },
        },
        resolver: zodResolver(editProjectSchema),
    })
    const { toast } = useToast()

    const { mutateAsync, isPending } = trpc.project.edit.useMutation({
        onSuccess: () => {
            toast({
                title: 'Project updated',
                description: 'Project has been updated',
            })
        },
    })

    const onSubmit = async (input: EditProjectSchema) => {
        const githubOptions = input.github?.githubEnabled ? input.github : null

        await mutateAsync({
            id: project.id,
            name: input.name,
            description: input.description,
            github: githubOptions,
        })
    }

    return (
        <Form {...form}>
            <form className="grid w-full grid-cols-2 gap-8">
                <div className="space-y-4">
                    <FormField
                        control={form.control}
                        name="name"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Project name</FormLabel>
                                <FormControl>
                                    <Input {...field} autoFocus id="name" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="description"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Project description</FormLabel>
                                <FormControl>
                                    <Input
                                        {...field}
                                        value={field.value || ''}
                                        onChange={(e) => {
                                            field.onChange(e)
                                        }}
                                        id="description"
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="space-y-4">
                    <FormField
                        control={form.control}
                        name="github.githubEnabled"
                        render={({ field }) => (
                            <FormItem className="flex flex-row items-center justify-between">
                                <div className="space-y-0.5">
                                    <FormLabel className="text-base">Github Integration</FormLabel>
                                </div>
                                <FormControl>
                                    <Switch
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                    />
                    {form.watch('github.githubEnabled') && (
                        <>
                            <FormField
                                control={form.control}
                                name="github.owner"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Organization name</FormLabel>
                                        <FormControl>
                                            <Input {...field} autoFocus id="name" />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="github.repo"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Repo name</FormLabel>
                                        <FormControl>
                                            <Input
                                                {...field}
                                                value={field.value || ''}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                }}
                                                id="description"
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="github.defaultBranch"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Default Branch</FormLabel>
                                        <FormControl>
                                            <Input
                                                {...field}
                                                value={field.value || ''}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                }}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </>
                    )}
                </div>
                <div className="col-span-2 flex justify-end">
                    <Button disabled={isPending} onClick={() => form.handleSubmit(onSubmit)()}>
                        Edit project
                    </Button>
                </div>
            </form>
        </Form>
    )
}
