import { State } from '@flow/db'
import { zodResolver } from '@hookform/resolvers/zod'
import { DashboardIcon } from '@radix-ui/react-icons'
import { CreateIssueInput, createIssueSchema } from '@schema'
import { useParams, useSearch } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { trpc } from '../../utils/trpc'
import { Button } from '../Primitives/Button'
import { Dialog, DialogContent, DialogTrigger } from '../Primitives/Dialog'
import { Form, FormControl, FormField, FormItem, FormMessage } from '../Primitives/Form'
import { Input } from '../Primitives/Input'
import { Label } from '../Primitives/Label'
import { Switch } from '../Primitives/Switch'

interface NewIssueDialogProps {
    source?: 'board' | 'backlog'
}

export const NewIssueDialog = ({ source }: NewIssueDialogProps) => {
    const [open, setOpen] = useState(false)
    const [addMore, setAddMore] = useState(false)
    const context = trpc.useUtils()
    const { cycleId, epicId, members } = useSearch({
        strict: false,
    })
    const { projectId } = useParams({
        strict: false,
    })

    const { data: project } = trpc.project.getOne.useQuery({
        id: projectId || '',
    })

    const form = useForm<CreateIssueInput>({
        defaultValues: {
            name: '',
            projectId,
            epicId,
            cycleId,
            assignees: members || [],
        },
        resolver: zodResolver(createIssueSchema),
    })

    useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === 'i' && e.metaKey) {
                setOpen((open) => !open)
            }
        }
        document.addEventListener('keydown', down)
    }, [])

    const { mutateAsync } = trpc.issue.create.useMutation({
        onSuccess: async () => {
            await context.project.invalidate()
            await context.issue.invalidate()
            form.reset()
            if (!addMore) {
                setOpen(false)
            }
        },
    })

    if (!projectId || !project) return

    const onSubmit = async (input: CreateIssueInput) => {
        if (input.name === '') return
        await mutateAsync({
            ...input,
            epicId,
            cycleId,
            assignees: input.assignees,
            status: source === 'board' ? State.UNSTARTED : State.BACKLOG,
        })
    }

    return (
        <Dialog onOpenChange={setOpen} open={open}>
            <DialogTrigger asChild>
                <Button variant="secondary">
                    New issue
                    <kbd className="pointer-events-none ml-2 inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
                        <span className="text-xs">⌘</span>I
                    </kbd>
                </Button>
            </DialogTrigger>
            <DialogContent className="top-0 mt-52">
                <div className="flex flex-col justify-start space-y-4">
                    <div className="mb-4 flex items-center space-x-1 self-start ">
                        <div className="flex items-center space-x-1 rounded-lg bg-secondary px-2 py-1">
                            <DashboardIcon />
                            <span className="rounded-sm bg-muted-foreground px-1 py-[.5px] text-sm">
                                {project?.id}
                            </span>
                            <span className="font-light">{project?.name}</span>
                        </div>
                        <span className="text-sm">New Issue</span>
                        <span />
                    </div>
                    {epicId && <div>Epic: {project.epics.find((m) => epicId === m.id)?.name}</div>}
                    {cycleId && (
                        <div>Cycle: {project.cycles.find((c) => cycleId === c.id)?.name}</div>
                    )}
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)}>
                            <FormField
                                control={form.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input autoFocus {...field} placeholder="Issue name" />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <div className="mt-4 flex justify-end space-x-2">
                                <div className="flex items-center space-x-2">
                                    <Label htmlFor="add-more">Add more</Label>
                                    <Switch
                                        onCheckedChange={(e) => setAddMore(e)}
                                        checked={addMore}
                                        id="add-more"
                                    />
                                </div>
                                <Button type="submit" className="self-end" variant={'secondary'}>
                                    Add
                                </Button>
                            </div>
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    )
}
