import { Label } from '../Primitives/Label'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../Primitives/Select'
import { DatePicker } from '../Tracker/DatePicker'

interface Props {
    date: Date | undefined
    setDate: (date: Date | undefined) => void
    numberOfDays: number
    setNumberOfDays: (days: number) => void
}

export const TimeSelect = ({ date, setDate, numberOfDays, setNumberOfDays }: Props) => {
    return (
        <div className="flex items-center  space-x-2">
            <div className="flex flex-col space-y-2">
                <Label htmlFor="date-picker">Start Date</Label>
                <DatePicker setDate={setDate} date={date} />
            </div>
            <div className="flex flex-col space-y-2">
                <Label htmlFor="number-of-days">Number of days</Label>

                <Select
                    value={numberOfDays.toString()}
                    onValueChange={(val) => setNumberOfDays(Number(val))}
                >
                    <SelectTrigger id="number-of-days" className="w-[180px]">
                        <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectItem value="1">1</SelectItem>
                            <SelectItem value="2">2</SelectItem>
                            <SelectItem value="3">3</SelectItem>
                            <SelectItem value="4">4</SelectItem>
                            <SelectItem value="5">5</SelectItem>
                            <SelectItem value="6">6</SelectItem>
                            <SelectItem value="7">7</SelectItem>
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
        </div>
    )
}
