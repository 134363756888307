import { getProjectByIdFilterSchema } from '@schema'
import { createFileRoute, getRouteApi } from '@tanstack/react-router'

import { Columns } from '../../../components/TaskList/Columns'
import { DataTable } from '../../../components/TaskList/DataTable'
import { ProjectContextProvider } from '../../../contexts/project.context'
import { BreadCrumbs } from '../../../utils/breadCrumbs'
import { apiUtils } from '../../../utils/trpc'

const PATH = '/_auth/project/$projectId/list'
const routeApi = getRouteApi(PATH)

export const List = () => {
    const { projectId } = routeApi.useParams()
    const { projectData } = routeApi.useLoaderData()

    return (
        <ProjectContextProvider projectId={projectId}>
            <DataTable columns={Columns} data={projectData.issues} />
        </ProjectContextProvider>
    )
}

export const Route = createFileRoute('/_auth/project/$projectId/list')({
    component: List,
    beforeLoad: () => {
        return {
            breadCrumb: BreadCrumbs.List,
        }
    },
    validateSearch: getProjectByIdFilterSchema,
    loaderDeps: ({ search }) => ({
        search,
    }),
    loader: async ({ params, deps }) => {
        const projectData = await apiUtils.issue.getByProjectId.ensureData({
            projectId: params.projectId,
            states: deps.search.states,
            statuses: deps.search.statuses,
            epicId: deps.search.epicId,
            cycleId: deps.search.cycleId,
        })
        return {
            projectData,
        }
    },
})
