import { GetIssueByAssigneOutput } from '@schema'
import { Link } from '@tanstack/react-router'

import { UserOutput } from '../../contexts/auth.context'
import { useTaskFilters } from '../../utils/useTaskFilters'
import { Avatar, AvatarImage } from '../Primitives/Avatar'
import { Card, CardTitle } from '../Primitives/Card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../Primitives/Tabs'
import { TaskListElement } from './TaskListElement'

export const TaskSummaryCard: React.FC<{
    user: UserOutput
    taskList: GetIssueByAssigneOutput[]
}> = ({ user, taskList }) => {
    const { upcomingTasks, overdueTasks, completedTasks } = useTaskFilters(taskList)

    return (
        <Card className="p-6">
            <Tabs defaultValue="upcoming" className="w-[600px]">
                <div className="flex w-full items-center gap-4">
                    <Avatar>
                        <AvatarImage src={user.avatar} />
                    </Avatar>
                    <div className="flex flex-col gap-2">
                        <CardTitle>My priorities</CardTitle>
                        <TabsList className="gap-4">
                            <TabsTrigger value="upcoming">Upcoming</TabsTrigger>
                            <TabsTrigger value="overdue">Overdue</TabsTrigger>
                            <TabsTrigger value="completed">Completed</TabsTrigger>
                        </TabsList>
                    </div>
                </div>
                <TabsContent value="upcoming">
                    <div className="flex w-full flex-col gap-2">
                        {upcomingTasks.map((task) => (
                            <Link key={task.id} to={`/project/${task.projectId}/issue/${task.id}`}>
                                <TaskListElement task={task} />
                            </Link>
                        ))}
                    </div>
                </TabsContent>
                <TabsContent value="overdue">
                    <div className="flex w-full flex-col gap-2">
                        {overdueTasks.map((task) => (
                            <Link key={task.id} to={`/project/${task.projectId}/issue/${task.id}`}>
                                <TaskListElement task={task} />
                            </Link>
                        ))}
                    </div>
                </TabsContent>
                <TabsContent value="completed">
                    <div className="flex w-full flex-col gap-2">
                        {completedTasks.map((task) => (
                            <Link key={task.id} to={`/project/${task.projectId}/issue/${task.id}`}>
                                <TaskListElement task={task} />
                            </Link>
                        ))}
                    </div>
                </TabsContent>
            </Tabs>
        </Card>
    )
}
