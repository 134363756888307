import { withProps } from '@udecode/cn'
import { createAlignPlugin } from '@udecode/plate-alignment'
import { createAutoformatPlugin } from '@udecode/plate-autoformat'
import {
    createBoldPlugin,
    createCodePlugin,
    createItalicPlugin,
    createStrikethroughPlugin,
    createSubscriptPlugin,
    createSuperscriptPlugin,
    createUnderlinePlugin,
    MARK_BOLD,
    MARK_CODE,
    MARK_ITALIC,
    MARK_STRIKETHROUGH,
    MARK_SUBSCRIPT,
    MARK_SUPERSCRIPT,
    MARK_UNDERLINE,
} from '@udecode/plate-basic-marks'
import { createBlockquotePlugin, ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote'
import { createExitBreakPlugin, createSoftBreakPlugin } from '@udecode/plate-break'
import { createCaptionPlugin } from '@udecode/plate-caption'
import {
    createCodeBlockPlugin,
    ELEMENT_CODE_BLOCK,
    ELEMENT_CODE_LINE,
    ELEMENT_CODE_SYNTAX,
    isCodeBlockEmpty,
    isSelectionAtCodeBlockStart,
    unwrapCodeBlock,
} from '@udecode/plate-code-block'
import {
    createPlugins,
    isBlockAboveEmpty,
    isSelectionAtBlockStart,
    PlateLeaf,
    RenderAfterEditable,
    someNode,
} from '@udecode/plate-common'
import { createExcalidrawPlugin, ELEMENT_EXCALIDRAW } from '@udecode/plate-excalidraw'
import {
    createFontBackgroundColorPlugin,
    createFontColorPlugin,
    createFontSizePlugin,
} from '@udecode/plate-font'
import {
    createHeadingPlugin,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
    KEYS_HEADING,
} from '@udecode/plate-heading'
import { createHighlightPlugin, MARK_HIGHLIGHT } from '@udecode/plate-highlight'
import { createHorizontalRulePlugin, ELEMENT_HR } from '@udecode/plate-horizontal-rule'
import { createIndentPlugin } from '@udecode/plate-indent'
import { createIndentListPlugin, KEY_LIST_STYLE_TYPE } from '@udecode/plate-indent-list'
import { createJuicePlugin } from '@udecode/plate-juice'
import { createKbdPlugin, MARK_KBD } from '@udecode/plate-kbd'
import { createColumnPlugin, ELEMENT_COLUMN, ELEMENT_COLUMN_GROUP } from '@udecode/plate-layout'
import { createLineHeightPlugin } from '@udecode/plate-line-height'
import { createLinkPlugin, ELEMENT_LINK } from '@udecode/plate-link'
import { createTodoListPlugin, ELEMENT_LI, ELEMENT_TODO_LI } from '@udecode/plate-list'
import {
    createImagePlugin,
    createMediaEmbedPlugin,
    ELEMENT_IMAGE,
    ELEMENT_MEDIA_EMBED,
} from '@udecode/plate-media'
import { createMentionPlugin, ELEMENT_MENTION, ELEMENT_MENTION_INPUT } from '@udecode/plate-mention'
import { createNodeIdPlugin } from '@udecode/plate-node-id'
import { createParagraphPlugin, ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph'
import { createResetNodePlugin } from '@udecode/plate-reset-node'
import { createDeletePlugin } from '@udecode/plate-select'
import { createBlockSelectionPlugin } from '@udecode/plate-selection'
import { createDeserializeCsvPlugin } from '@udecode/plate-serializer-csv'
import { createDeserializeDocxPlugin } from '@udecode/plate-serializer-docx'
import { createDeserializeMdPlugin } from '@udecode/plate-serializer-md'
import { createTabbablePlugin } from '@udecode/plate-tabbable'
import {
    createTablePlugin,
    ELEMENT_TABLE,
    ELEMENT_TD,
    ELEMENT_TH,
    ELEMENT_TR,
} from '@udecode/plate-table'
import { createTogglePlugin, ELEMENT_TOGGLE } from '@udecode/plate-toggle'
import { createTrailingBlockPlugin } from '@udecode/plate-trailing-block'

import { BlockquoteElement } from './_plate-ui/blockquote-element'
import { CodeBlockElement } from './_plate-ui/code-block-element'
import { CodeLeaf } from './_plate-ui/code-leaf'
import { CodeLineElement } from './_plate-ui/code-line-element'
import { CodeSyntaxLeaf } from './_plate-ui/code-syntax-leaf'
import { ColumnElement } from './_plate-ui/column-element'
import { ColumnGroupElement } from './_plate-ui/column-group-element'
import { ExcalidrawElement } from './_plate-ui/excalidraw-element'
import { HeadingElement } from './_plate-ui/heading-element'
import { HighlightLeaf } from './_plate-ui/highlight-leaf'
import { HrElement } from './_plate-ui/hr-element'
import { ImageElement } from './_plate-ui/image-element'
import { KbdLeaf } from './_plate-ui/kbd-leaf'
import { LinkElement } from './_plate-ui/link-element'
import { LinkFloatingToolbar } from './_plate-ui/link-floating-toolbar'
import { MediaEmbedElement } from './_plate-ui/media-embed-element'
import { MentionElement } from './_plate-ui/mention-element'
import { MentionInputElement } from './_plate-ui/mention-input-element'
import { ParagraphElement } from './_plate-ui/paragraph-element'
import { withPlaceholders } from './_plate-ui/placeholder'
import { TabbableElement } from './_plate-ui/tabbable-element'
import { TableCellElement, TableCellHeaderElement } from './_plate-ui/table-cell-element'
import { TableElement } from './_plate-ui/table-element'
import { TableRowElement } from './_plate-ui/table-row-element'
import { TodoListElement } from './_plate-ui/todo-list-element'
import { ToggleElement } from './_plate-ui/toggle-element'
import { autoformatPlugin } from './autoformatPlugin/autoformatPlugin'
import {
    createAttachmentPluginEl,
    createAttchmentsPlugin,
    ELEMENT_ATTACHMENT,
} from './FileAttachment'
import { AttachmentElement } from './FileAttachment/attachmentElement/AttachmentElement'

const resetBlockTypesCommonRule = {
    types: [ELEMENT_BLOCKQUOTE, ELEMENT_TODO_LI],
    defaultType: ELEMENT_PARAGRAPH,
}

const resetBlockTypesCodeBlockRule = {
    types: [ELEMENT_CODE_BLOCK],
    defaultType: ELEMENT_PARAGRAPH,
    onReset: unwrapCodeBlock,
}

const createPlugin = (witPlaceholder: boolean) => {
    const components = {
        [ELEMENT_BLOCKQUOTE]: BlockquoteElement,
        [ELEMENT_CODE_BLOCK]: CodeBlockElement,
        [ELEMENT_CODE_LINE]: CodeLineElement,
        [ELEMENT_CODE_SYNTAX]: CodeSyntaxLeaf,
        [ELEMENT_EXCALIDRAW]: ExcalidrawElement,
        [ELEMENT_HR]: HrElement,
        [ELEMENT_IMAGE]: ImageElement,
        [ELEMENT_LINK]: LinkElement,
        [ELEMENT_TOGGLE]: ToggleElement,
        [ELEMENT_COLUMN_GROUP]: ColumnGroupElement,
        [ELEMENT_COLUMN]: ColumnElement,
        [ELEMENT_H1]: withProps(HeadingElement, { variant: 'h1' }),
        [ELEMENT_H2]: withProps(HeadingElement, { variant: 'h2' }),
        [ELEMENT_H3]: withProps(HeadingElement, { variant: 'h3' }),
        [ELEMENT_H4]: withProps(HeadingElement, { variant: 'h4' }),
        [ELEMENT_H5]: withProps(HeadingElement, { variant: 'h5' }),
        [ELEMENT_H6]: withProps(HeadingElement, { variant: 'h6' }),
        [ELEMENT_MEDIA_EMBED]: MediaEmbedElement,
        [ELEMENT_MENTION]: MentionElement,
        [ELEMENT_MENTION_INPUT]: MentionInputElement,
        [ELEMENT_PARAGRAPH]: ParagraphElement,
        [ELEMENT_TABLE]: TableElement,
        [ELEMENT_TR]: TableRowElement,
        [ELEMENT_TD]: TableCellElement,
        [ELEMENT_TH]: TableCellHeaderElement,
        [ELEMENT_TODO_LI]: TodoListElement,
        [MARK_BOLD]: withProps(PlateLeaf, { as: 'strong' }),
        [MARK_CODE]: CodeLeaf,
        [MARK_HIGHLIGHT]: HighlightLeaf,
        [MARK_ITALIC]: withProps(PlateLeaf, { as: 'em' }),
        [MARK_KBD]: KbdLeaf,
        [MARK_STRIKETHROUGH]: withProps(PlateLeaf, { as: 's' }),
        [MARK_SUBSCRIPT]: withProps(PlateLeaf, { as: 'sub' }),
        [MARK_SUPERSCRIPT]: withProps(PlateLeaf, { as: 'sup' }),
        [MARK_UNDERLINE]: withProps(PlateLeaf, { as: 'u' }),
        [ELEMENT_ATTACHMENT]: AttachmentElement,
    }

    return createPlugins(
        [
            createParagraphPlugin(),
            createHeadingPlugin(),
            createBlockquotePlugin(),
            createCodeBlockPlugin(),
            createHorizontalRulePlugin(),
            createLinkPlugin({
                options: {
                    defaultLinkAttributes: {
                        target: '_blank',
                    },
                },
                renderAfterEditable: LinkFloatingToolbar as RenderAfterEditable,
            }),
            createImagePlugin(),
            createExcalidrawPlugin(),
            createTogglePlugin(),
            createColumnPlugin(),
            createMediaEmbedPlugin(),
            createCaptionPlugin({
                options: {
                    pluginKeys: [ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED],
                },
            }),
            createTablePlugin(),
            createTodoListPlugin(),
            createBoldPlugin(),
            createItalicPlugin(),
            createUnderlinePlugin(),
            createStrikethroughPlugin(),
            createCodePlugin(),
            createSubscriptPlugin(),
            createSuperscriptPlugin(),
            createFontColorPlugin(),
            createFontBackgroundColorPlugin(),
            createFontSizePlugin(),
            createHighlightPlugin(),
            createKbdPlugin(),
            createAlignPlugin({
                inject: {
                    props: {
                        validTypes: [ELEMENT_PARAGRAPH, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3],
                    },
                },
            }),
            createIndentPlugin({
                inject: {
                    props: {
                        validTypes: [
                            ELEMENT_PARAGRAPH,
                            ELEMENT_H1,
                            ELEMENT_H2,
                            ELEMENT_H3,
                            ELEMENT_BLOCKQUOTE,
                            ELEMENT_CODE_BLOCK,
                        ],
                    },
                },
            }),
            createIndentListPlugin({
                inject: {
                    props: {
                        validTypes: [
                            ELEMENT_PARAGRAPH,
                            ELEMENT_H1,
                            ELEMENT_H2,
                            ELEMENT_H3,
                            ELEMENT_BLOCKQUOTE,
                            ELEMENT_CODE_BLOCK,
                        ],
                    },
                },
            }),
            createLineHeightPlugin({
                inject: {
                    props: {
                        defaultNodeValue: 1.5,
                        validNodeValues: [1, 1.2, 1.5, 2, 3],
                        validTypes: [ELEMENT_PARAGRAPH, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3],
                    },
                },
            }),
            createAutoformatPlugin(autoformatPlugin),
            createBlockSelectionPlugin({
                options: {
                    sizes: {
                        top: 0,
                        bottom: 0,
                    },
                },
            }),
            createExitBreakPlugin({
                options: {
                    rules: [
                        {
                            hotkey: 'mod+enter',
                        },
                        {
                            hotkey: 'mod+shift+enter',
                            before: true,
                        },
                        {
                            hotkey: 'enter',
                            query: {
                                start: true,
                                end: true,
                                allow: KEYS_HEADING,
                            },
                            relative: true,
                            level: 1,
                        },
                    ],
                },
            }),
            createNodeIdPlugin(),
            createResetNodePlugin({
                options: {
                    rules: [
                        {
                            ...resetBlockTypesCommonRule,
                            hotkey: 'Enter',
                            predicate: isBlockAboveEmpty,
                        },
                        {
                            ...resetBlockTypesCommonRule,
                            hotkey: 'Backspace',
                            predicate: isSelectionAtBlockStart,
                        },
                        {
                            ...resetBlockTypesCodeBlockRule,
                            hotkey: 'Enter',
                            predicate: isCodeBlockEmpty,
                        },
                        {
                            ...resetBlockTypesCodeBlockRule,
                            hotkey: 'Backspace',
                            predicate: isSelectionAtCodeBlockStart,
                        },
                    ],
                },
            }),
            createDeletePlugin(),
            createSoftBreakPlugin({
                options: {
                    rules: [
                        { hotkey: 'shift+enter' },
                        {
                            hotkey: 'enter',
                            query: {
                                allow: [ELEMENT_CODE_BLOCK, ELEMENT_BLOCKQUOTE, ELEMENT_TD],
                            },
                        },
                    ],
                },
            }),
            createTabbablePlugin({
                options: {
                    query: (editor) => {
                        if (isSelectionAtBlockStart(editor)) return false
                        return !someNode(editor, {
                            match: (n) => {
                                return !!(
                                    n.type &&
                                    ([ELEMENT_TABLE, ELEMENT_LI, ELEMENT_CODE_BLOCK].includes(
                                        n.type as string,
                                    ) ||
                                        n[KEY_LIST_STYLE_TYPE])
                                )
                            },
                        })
                    },
                },
                plugins: [
                    {
                        key: 'tabbable_element',
                        isElement: true,
                        isVoid: true,
                        component: TabbableElement,
                    },
                ],
            }),
            createTrailingBlockPlugin({
                options: { type: ELEMENT_PARAGRAPH },
            }),
            // Custom
            createAttchmentsPlugin(),
            createAttachmentPluginEl(),
            createDeserializeDocxPlugin(),
            createDeserializeCsvPlugin(),
            createDeserializeMdPlugin(),
            createJuicePlugin(),
            createMentionPlugin(),
        ],
        {
            components: witPlaceholder ? withPlaceholders(components) : components,
        },
    )
}

export const plugins = createPlugin(true)

export const withOutPlaceholderPlugins = createPlugin(false)
