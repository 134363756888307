import { DownloadIcon, EyeOpenIcon } from '@radix-ui/react-icons'
import { PlateElement, PlateElementProps, Value } from '@udecode/plate-common'
import { useEffect, useState } from 'react'

import { cn } from '../../../../utils/cn'
import { Button } from '../../../Primitives/Button'
import { Dialog, DialogContent, DialogTrigger } from '../../../Primitives/Dialog'
import { Icons } from '../../Icons'
import { TCloudAttachmentElement } from './types'

export interface CloudAttachmentElementProps
    extends PlateElementProps<Value, TCloudAttachmentElement> {}

export function AttachmentElement({ className, ...props }: CloudAttachmentElementProps) {
    const { children, element } = props
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 })

    useEffect(() => {
        if (!element.url || !element.fileType.startsWith('image')) return

        const img = new Image()
        img.src = element.url
        img.onload = () => setImageSize({ width: img.naturalWidth, height: img.naturalHeight })
    }, [element.url, element.fileType])

    if (element.fileType.startsWith('image')) {
        return (
            <PlateElement className={cn('relative my-4', className)} draggable {...props}>
                <span
                    contentEditable={false}
                    style={{
                        /**
                         * NOTE:
                         * This code pretty much needs to be this way or things stop working
                         * so this cannot be overrided in the `.styles.ts` file.
                         */
                        position: 'relative',
                        display: 'inline-block',
                        /**
                         * This is required so that we don't get an extra gap at the bottom.
                         * When display is 'inline-block' we get some extra space at the bottom
                         * for the descenders because the content is expected to co-exist with text.
                         *
                         * Setting vertical-align to top, bottom or middle fixes this because it is
                         * no longer baseline which causes the issue.
                         *
                         * This is usually an issue with 'img' but also affects this scenario.
                         *
                         * https://stackoverflow.com/questions/5804256/image-inside-div-has-extra-space-below-the-image
                         *
                         * Also, make sure that <img> on the inside is display: 'block'.
                         */
                        verticalAlign: 'top',
                        /**
                         * Disable user select. We use our own selection display.
                         */
                        userSelect: 'none',
                    }}
                >
                    {element.url === '' ? (
                        <div
                            className={cn('block rounded-lg')}
                            style={{
                                width: 40,
                                height: 40,
                                background: '#e0e0e0',
                            }}
                        />
                    ) : (
                        <>
                            <img
                                className={cn('block rounded-lg border-2 border-white')}
                                src={element.url}
                                width={400}
                                height={400}
                                alt=""
                            />
                            {element.url && (
                                <>
                                    <a
                                        href={element.url}
                                        className="absolute bottom-0 right-0 z-10 mb-4 mr-4 rounded-lg border-white bg-primary p-2 text-white"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <DownloadIcon
                                            className="cursor-pointer text-muted-foreground hover:text-foreground"
                                            width={20}
                                            height={20}
                                        />
                                    </a>
                                    <Dialog>
                                        <DialogTrigger asChild>
                                            <Button
                                                size="icon"
                                                variant="outline"
                                                className="absolute bottom-0 right-12 z-10 mb-4 mr-4 rounded-lg border-white p-2"
                                            >
                                                <EyeOpenIcon className="size-4" />
                                            </Button>
                                        </DialogTrigger>
                                        <DialogContent
                                            className="max-h-[90vh] max-w-[90vw] overflow-y-scroll"
                                            style={{
                                                width: imageSize.width,
                                                height: imageSize.height,
                                            }}
                                        >
                                            <img
                                                src={element.url}
                                                width={imageSize.width}
                                                height={imageSize.height}
                                                alt=""
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </>
                            )}
                        </>
                    )}
                </span>
                {children}
            </PlateElement>
        )
    }

    return (
        <PlateElement
            className={cn(
                'relative my-4 flex h-10 max-w-sm items-center gap-2 rounded-lg border border-border bg-background p-4',
                className,
            )}
            draggable
            {...props}
        >
            <div className="shrink-0 text-muted-foreground" contentEditable={false}>
                <Icons.attachment width={24} height={24} />
            </div>
            <div className="grow" contentEditable={false}>
                <div className="text-xs">{element.name}</div>
            </div>
            <div
                className="ml-4 flex size-8 shrink-0 items-center justify-center duration-200"
                contentEditable={false}
            >
                {element.url && (
                    <a href={element.url} target="_blank" rel="noreferrer">
                        <DownloadIcon
                            className="cursor-pointer text-muted-foreground hover:text-foreground"
                            width={20}
                            height={20}
                        />
                    </a>
                )}
            </div>
            {children}
        </PlateElement>
    )
}
