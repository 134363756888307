import { Trash2 as DeleteIcon } from 'lucide-react'
import { useState } from 'react'

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTrigger,
} from '../../components/Primitives/Dialog'
import { useToast } from '../../hooks/useToast'
import { trpc } from '../../utils/trpc'
import { Button } from '../Primitives/Button'

interface Props {
    id: string
}
export const DeleteHoliday = ({ id }: Props) => {
    const [open, setOpen] = useState(false)
    const context = trpc.useUtils()

    const { toast } = useToast()

    const { mutateAsync: deleteHoliday } = trpc.holiday.delete.useMutation({
        onSuccess: async () => {
            toast({
                title: 'Holiday deleted',
            })
            await context.holiday.invalidate()
        },
    })

    const handleDelete = async () => {
        await deleteHoliday({ id })
        setOpen(false)
    }

    return (
        <Dialog onOpenChange={setOpen} open={open}>
            <DialogTrigger asChild>
                <Button size="icon" variant="ghost">
                    <DeleteIcon className="size-4" />
                </Button>
            </DialogTrigger>
            <DialogContent className="flex flex-col items-center sm:max-w-[425px]">
                <DialogHeader>
                    <DialogDescription>
                        Are you sure you want to delete this holiday? This cannot be undone.
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <Button variant="destructive" type="submit" onClick={handleDelete}>
                        Delete
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
