import { DndContext, DragOverlay, MouseSensor, rectIntersection, useSensor } from '@dnd-kit/core'
import { State } from '@flow/db'
import { ProjectBoard } from '@schema'
import { keepPreviousData } from '@tanstack/react-query'
import { getRouteApi } from '@tanstack/react-router'
import { useEffect } from 'react'
import { createPortal } from 'react-dom'

import { useDnd } from '../../hooks/useDnd'
import { trpc } from '../../utils/trpc'
import { Card } from './Card'
import { StateCol } from './State'

interface BoardProps {
    projectId: string
}

const routeApi = getRouteApi('/_auth/project/$projectId/')

export const Board = ({ projectId }: BoardProps) => {
    const { search } = routeApi.useLoaderDeps()
    const { projectBoardData } = routeApi.useLoaderData()
    const { data: projectData } = trpc.project.getForBoard.useQuery(
        {
            id: projectId,
            cycleId: search.cycleId,
            epicId: search.epicId,
            members: search.members,
        },
        {
            ...(projectBoardData && { initialData: projectBoardData }),
            placeholderData: keepPreviousData,
        },
    )

    if (!projectData) {
        return (
            <div className="flex min-h-screen w-full items-center justify-center text-white">
                <div
                    className="inline-block size-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                >
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                    </span>
                </div>
            </div>
        )
    }
    return <BoardDnd project={projectData} />
}

interface BoardDndProps {
    project: ProjectBoard
}

const BoardDnd = ({ project }: BoardDndProps) => {
    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: 10,
        },
    })

    // THIS IS SHIT, but works if someone finds this please refactor for reuseable story
    const { activeIssue, handleDragEnd, handleDragOver, handleDragStart, states, setStates } =
        useDnd({
            initialStatuses: project.statuses.filter(
                (s) => s.state !== State.ARCHIVE && s.state !== State.BACKLOG,
            ),
        })

    useEffect(() => {
        setStates(
            project.statuses.filter((s) => s.state !== State.ARCHIVE && s.state !== State.BACKLOG),
        )
    }, [project, setStates])

    return (
        <DndContext
            collisionDetection={rectIntersection}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            sensors={[mouseSensor]}
        >
            <div className="flex size-full grow-0 items-stretch">
                {states?.map((state) => {
                    return <StateCol key={state.id} state={state} />
                })}
                {'document' in window &&
                    createPortal(
                        <DragOverlay>
                            {activeIssue ? <Card issue={activeIssue} isOverlay={true} /> : null}
                        </DragOverlay>,
                        document.body,
                    )}
            </div>
        </DndContext>
    )
}
