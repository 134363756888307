import { LapTimerIcon } from '@radix-ui/react-icons'
import { useEffect, useState } from 'react'

import { useElapsedTime } from '../../hooks/useTimer'
import { trpc } from '../../utils/trpc'
import { Button } from '../Primitives/Button'
import { Popover, PopoverContent, PopoverTrigger } from '../Primitives/Popover'
import { TrackerPopover } from './Tracker/TrackerPopover'

interface TrackerProps {
    projectId: string | undefined
    issueId?: string | undefined
}

export const Tracker = ({ projectId, issueId }: TrackerProps) => {
    const [open, setOpen] = useState(false)
    const { data: onGoingIssue, isLoading } = trpc.tracker.getOngoingEntry.useQuery()
    const { elapsedTime, setStartDate } = useElapsedTime()

    useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === 'u' && e.metaKey) {
                setOpen((open) => !open)
            }
        }
        document.addEventListener('keydown', down)
        return () => document.removeEventListener('keydown', down)
    }, [])

    useEffect(() => {
        if (onGoingIssue) {
            document.title = `flow (${elapsedTime})`
        } else {
            document.title = 'flow'
        }
    }, [elapsedTime, onGoingIssue])

    useEffect(() => {
        if (onGoingIssue) {
            setStartDate(new Date(onGoingIssue.startDate))
        }
    }, [onGoingIssue, setStartDate])

    if (isLoading) return null
    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button variant="ghost">
                    <LapTimerIcon className="h-5 text-yellow-400" />
                    <kbd className="pointer-events-none ml-2 inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
                        <span className="text-xs">⌘</span>U
                    </kbd>
                    {onGoingIssue ? <span className="ml-2">{elapsedTime}</span> : null}
                </Button>
            </PopoverTrigger>
            <PopoverContent side="bottom" align="end" className="mt-1 w-auto bg-secondary">
                <TrackerPopover projectId={projectId} issueId={issueId} />
            </PopoverContent>
        </Popover>
    )
}
