import { useNavigate } from '@tanstack/react-router'

import { useAuth } from '../../contexts/auth.context'
import { Button } from '../Primitives/Button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '../Primitives/DropdownMenu'

export const UserMenu = () => {
    const { user, logout } = useAuth()
    const navigate = useNavigate()

    const onLogout = async () => {
        await logout()
        navigate({ to: '/login' })
    }

    const onSettings = () => {
        navigate({ to: '/settings' })
    }
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                    <img className="size-6 rounded-full" src={user?.avatar} alt="" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="center" className="w-[160px]">
                <DropdownMenuItem onClick={onSettings}>Settings</DropdownMenuItem>
                <DropdownMenuItem onClick={onLogout}>Logout</DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
