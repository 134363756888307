import { createFileRoute } from '@tanstack/react-router'
import { Link, useParams } from '@tanstack/react-router'

import { DeleteEpicDialog } from '../../../components/Epics/DeleteEpicDialog'
import { EditEpicDialog } from '../../../components/Epics/EditEpicDialog'
import { NewEpicDialog } from '../../../components/Epics/NewEpicDialog'
import { Card, CardDescription, CardTitle } from '../../../components/Primitives/Card'
import { Progress } from '../../../components/Primitives/Progress'
import { BreadCrumbs } from '../../../utils/breadCrumbs'
import { formatDuration } from '../../../utils/date'
import { trpc } from '../../../utils/trpc'

export const Epics = () => {
    const { projectId } = useParams({
        strict: false,
    })
    const { data } = trpc.epic.getByProject.useQuery({
        projectId: projectId!,
    })

    if (!projectId) return null

    return (
        <div className="flex flex-col space-y-2">
            <div>
                <NewEpicDialog />
            </div>
            <div className="flex flex-1 flex-wrap gap-2">
                {data?.map((epic) => {
                    const percentage =
                        epic.issues.length > 0 ? (epic._count.issues / epic.issues.length) * 100 : 0
                    return (
                        <Card
                            key={epic.id}
                            className="relative flex w-[500px] flex-col space-y-2 p-4"
                        >
                            <Link
                                to={`/project/$projectId/list`}
                                params={{
                                    projectId: projectId,
                                }}
                                search={{
                                    epicId: epic.id,
                                }}
                                className="space-y-2"
                            >
                                <CardTitle>{epic.name}</CardTitle>
                                <CardDescription>{epic.description}</CardDescription>
                            </Link>
                            <div className="flex space-x-2 text-xs">
                                <div className="w-1/3 rounded-lg bg-muted-foreground p-2 text-muted">
                                    <p>Issues: {epic.issues.length}</p>
                                    <p>Completed: {epic._count.issues}</p>
                                    <p>Percentage: {percentage.toFixed(0)}%</p>
                                </div>
                                <div className="w-2/3 rounded-lg bg-muted-foreground p-2 text-muted">
                                    <p>
                                        Estimation:{' '}
                                        {epic.estimation ? formatDuration(epic.estimation) : 'n/a'}
                                    </p>
                                    <p>
                                        Estimation(issues):{' '}
                                        {formatDuration(
                                            epic.issues.reduce(
                                                (acc, issue) => acc + (issue?.estimation || 0),
                                                0,
                                            ),
                                        ) || 'n/a'}
                                    </p>
                                    <p>
                                        Clocked:{' '}
                                        {formatDuration(
                                            epic.issues.reduce((acc, curr) => {
                                                return (
                                                    acc +
                                                    curr.timeEntries.reduce((a, c) => {
                                                        return a + (c?.timeSpent || 0)
                                                    }, 0)
                                                )
                                            }, 0),
                                        ) || 'n/a'}
                                    </p>
                                </div>
                            </div>
                            <Progress value={percentage} />
                            <div className="absolute right-4 top-0 space-x-2">
                                <EditEpicDialog epic={epic} />
                                <DeleteEpicDialog id={epic.id} />
                            </div>
                        </Card>
                    )
                })}
            </div>
        </div>
    )
}

export const Route = createFileRoute('/_auth/project/$projectId/epics')({
    component: Epics,
    beforeLoad: () => {
        return {
            breadCrumb: BreadCrumbs.Epics,
        }
    },
})
