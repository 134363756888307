import { zodResolver } from '@hookform/resolvers/zod'
import { EditHolidaySchema, editHolidaySchema } from '@schema'
import { Edit2 as EditIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTrigger,
} from '../../components/Primitives/Dialog'
import { useToast } from '../../hooks/useToast'
import { trpc } from '../../utils/trpc'
import { Button } from '../Primitives/Button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../Primitives/Form'
import { Input } from '../Primitives/Input'
import { DatePicker } from '../Tracker/DatePicker'

interface Props {
    id: string
    userId: string
    start: Date
    end: Date
    comment: string | null
}

export const EditHoliday = (props: Props) => {
    const [open, setOpen] = useState(false)
    const context = trpc.useUtils()

    const form = useForm<EditHolidaySchema>({
        resolver: zodResolver(editHolidaySchema),
        defaultValues: {
            id: props.id,
            userId: props.userId,
            start: props.start,
            end: props.end,
            comment: props.comment || '',
        },
    })

    useEffect(() => {
        form.reset({
            id: props.id,
            userId: props.userId,
            start: props.start,
            end: props.end,
            comment: props.comment || '',
        })
    }, [props, form])

    const { toast } = useToast()

    const { mutateAsync } = trpc.holiday.edit.useMutation({
        onSuccess: async () => {
            toast({
                title: 'Holiday updated',
            })
            await context.holiday.invalidate()
        },
    })

    const { control, handleSubmit } = form

    const onSubmit = async (data: EditHolidaySchema) => {
        await mutateAsync(data)
        setOpen(false)
    }

    return (
        <Dialog onOpenChange={setOpen} open={open}>
            <DialogTrigger asChild>
                <Button size="icon" variant="ghost">
                    <EditIcon className="size-4" />
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogDescription>Edit holiday</DialogDescription>
                </DialogHeader>
                <Form {...form}>
                    <form
                        className="grid grid-cols-2 grid-rows-3 gap-3"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <FormField
                            control={control}
                            name="start"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <FormItem className="flex flex-col self-center">
                                        <FormLabel>Start date</FormLabel>
                                        <FormControl>
                                            <DatePicker
                                                setDate={(val) => onChange(val)}
                                                date={value}
                                            />
                                        </FormControl>
                                    </FormItem>
                                )
                            }}
                        />
                        <FormField
                            control={control}
                            name="end"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <FormItem className="flex flex-col self-center">
                                        <FormLabel>End date</FormLabel>
                                        <FormControl>
                                            <DatePicker
                                                setDate={(val) => onChange(val)}
                                                date={value}
                                            />
                                        </FormControl>
                                    </FormItem>
                                )
                            }}
                        />
                        <FormField
                            control={control}
                            name="comment"
                            render={({ field }) => (
                                <FormItem className="col-span-2">
                                    <FormLabel>Comment</FormLabel>
                                    <FormControl>
                                        <Input autoFocus={true} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button
                            variant="outline"
                            type="submit"
                            className="col-span-2 row-start-3 place-self-end"
                        >
                            Update
                        </Button>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}
