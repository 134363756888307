import { UserRole } from '@flow/db'
import { Navigate } from '@tanstack/react-router'

import { useAuth } from '../../contexts/auth.context'

interface Props {
    authorizedRoles: UserRole[]
    children: React.ReactNode
}

export const ProtectedElement = ({ authorizedRoles, children }: Props) => {
    const { user } = useAuth()
    return user?.role && authorizedRoles.includes(user?.role) && children
}

export const ProtectedRoute = ({ authorizedRoles, children }: Props) => {
    const { user } = useAuth()
    return user?.role && authorizedRoles.includes(user?.role) ? (
        children
    ) : (
        <Navigate to="/" replace />
    )
}
