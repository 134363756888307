import { State } from '@flow/db'
import { GetIssueByAssigneOutput } from '@schema'
import { useMemo } from 'react'

export const useTaskFilters = (taskList: GetIssueByAssigneOutput[]) => {
    const upcomingTasks = useMemo(
        () =>
            taskList.filter(
                (task) => task.status.state !== State.DONE && task.status.state !== State.ARCHIVE,
            ),
        [taskList],
    )

    const overdueTasks = useMemo(
        () => taskList.filter((task) => task.targetDate && task.targetDate < new Date()),
        [taskList],
    )

    const completedTasks = useMemo(
        () => taskList.filter((task) => task.status.state === State.DONE),
        [taskList],
    )

    const completedTasksThisWeek = useMemo(() => {
        const currentWeekStart = new Date()
        currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay())

        const currentWeekEnd = new Date(currentWeekStart)
        currentWeekEnd.setDate(currentWeekStart.getDate() + 7)

        return taskList.filter(
            (task) =>
                task.status.state === State.DONE &&
                task.updatedAt > currentWeekStart &&
                task.updatedAt < currentWeekEnd,
        )
    }, [taskList])

    return {
        upcomingTasks,
        overdueTasks,
        completedTasks,
        completedTasksThisWeek,
    }
}
