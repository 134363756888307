import { Project } from '@flow/db'

import { Card, CardTitle } from '../Primitives/Card'
import { ProjectList } from '../Projects/ProjectList'

export const ProjectSummaryCard: React.FC<{ projects: Project[] }> = ({ projects }) => {
    return (
        <Card className="p-6">
            <div className="flex w-full items-center gap-4">
                <div className="flex flex-col gap-2">
                    <CardTitle>My projects</CardTitle>
                    <ProjectList projects={projects} />
                </div>
            </div>
        </Card>
    )
}
