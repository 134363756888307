import { Issue, Project, TimeEntry, User } from '@flow/db'
import { z } from 'zod'

export const authSchema = z.object({
    email: z.string().email(),
    password: z.string().min(6),
})

export const editUserSchema = z.object({
    name: z.string().min(1).max(255),
    email: z.string().email(),
})

export const editUserSalarySchema = z.object({
    id: z.string(),
    salary: z.number().min(0),
})

export const editPasswordSchema = z.object({
    password: z.string().min(6),
    newPassword: z.string().min(6, 'Password must be at least 6 characters long'),
    confirmNewPassword: z.string().min(6, 'Password must be at least 6 characters long'),
})

export type AuthSchema = z.infer<typeof authSchema>
export type EditUserSchema = z.infer<typeof editUserSchema>
export type EditUserSalarySchema = z.infer<typeof editUserSalarySchema>
export type EditPasswordSchema = z.infer<typeof editPasswordSchema>

type TimeEntryWithIssue = TimeEntry & {
    issue: Issue & {
        project: Project
    }
}

export type GetUsersAdminOutput = User & {
    projects: Project[]
    timeEntries: TimeEntryWithIssue[]
}
