import { createFileRoute, getRouteApi, useParams } from '@tanstack/react-router'
import { z } from 'zod'

import { IssueEdit } from '../../../components/Issue/IssueEdit'
import { ScrollArea } from '../../../components/Primitives/ScrollArea'
import { BreadCrumbs } from '../../../utils/breadCrumbs'
import { apiUtils, trpc } from '../../../utils/trpc'

const PATH = '/_auth/project/$projectId/issue/$issueId'
const routeApi = getRouteApi(PATH)

export const Issue = () => {
    const params = useParams({
        strict: false,
    })

    const { issue: initialData } = routeApi.useLoaderData()

    const { data, isLoading } = trpc.issue.getById.useQuery(
        {
            issueId: params.issueId || '',
        },
        {
            initialData,
        },
    )

    if (!data || isLoading) {
        return <div>Loading...</div>
    }

    return (
        <ScrollArea className="flex w-full items-center justify-center">
            <IssueEdit issue={data} />
        </ScrollArea>
    )
}

export const Route = createFileRoute('/_auth/project/$projectId/issue/$issueId')({
    component: Issue,
    beforeLoad: () => {
        return {
            breadCrumb: BreadCrumbs.Issue,
        }
    },
    validateSearch: z.object({
        commentId: z.string().optional(),
    }),
    loader: async ({ params }) => {
        const issue = await apiUtils.issue.getById.ensureData({
            issueId: params.issueId,
        })

        return {
            issue,
        }
    },
})
