import { GetTimeEntriesOutput } from '@schema'
import { groupBy } from 'lodash'

import { UserEntry } from './UserEntry'

interface Props {
    date: Date | undefined
    numberOfDays: number
    timeEntries?: GetTimeEntriesOutput[]
}

export const ProjectEntry = ({ date, numberOfDays, timeEntries }: Props) => {
    return (
        <>
            {Object.values(groupBy(timeEntries, 'userId')).map((timeEntries) => {
                const user = timeEntries[0].user
                return (
                    <UserEntry
                        key={user.id}
                        user={user}
                        date={date}
                        numberOfDays={numberOfDays}
                        timeEntries={timeEntries}
                    />
                )
            })}
        </>
    )
}
