import { createPluginFactory, Value } from '@udecode/plate-common'

import { onDropAttachments, onPasteCloud } from './handlers'
import { AttachmentsPlugin, PlateCloudEditor } from './types'
import { withAttachments } from './withAttachments'

export const KEY_ATTACHMENTS = 'attachments'

export const createAttchmentsPlugin = createPluginFactory<
    AttachmentsPlugin,
    Value,
    PlateCloudEditor
>({
    key: KEY_ATTACHMENTS,
    withOverrides: withAttachments,
    handlers: {
        onDrop: (editor) => (e) => onDropAttachments(editor, e),
        onPaste: (editor) => (e) => onPasteCloud(editor, e),
    },
})
