import { useParams } from '@tanstack/react-router'
import { ArchiveIcon } from 'lucide-react'

import { useProject } from '../../contexts/project.context'
import { useToast } from '../../hooks/useToast'
import { trpc } from '../../utils/trpc'
import { Button } from '../Primitives/Button'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../Primitives/Tooltip'

export const ArchiveButton = () => {
    const { project } = useProject()
    const { projectId } = useParams({
        strict: false,
    })
    const context = trpc.useUtils()
    const { toast } = useToast()

    const { data } = trpc.issue.getByProjectId.useQuery({
        projectId: projectId || '',
        states: ['DONE'],
    })

    const { mutateAsync: moveTasks } = trpc.issue.modifyStatus.useMutation({
        onSuccess: async () => {
            toast({
                title: 'Tasks archived',
                description: 'All "Done" tasks have been archived',
            })

            await context.issue.getByProjectId.invalidate({ projectId })
            await context.project.getForBoard.invalidate()
        },
    })

    const archiveStatusId = project.statuses.find((status) => status.name === 'Archive')?.id

    const handleArchiveTasks = async () => {
        if (!data || !projectId || !archiveStatusId) return

        const idsToMove = data.issues.map((issue) => issue.id)
        await moveTasks({
            issues: idsToMove,
            projectId: projectId,
            statusId: archiveStatusId,
        })
    }

    return (
        <TooltipProvider>
            <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                    <Button
                        variant="ghost"
                        size="icon"
                        className="size-6"
                        onClick={handleArchiveTasks}
                        disabled={!data?.issues.length}
                    >
                        <ArchiveIcon className="size-4" />
                    </Button>
                </TooltipTrigger>
                <TooltipContent>Archive all "Done" tasks</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}
