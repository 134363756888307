import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import { lazy, Suspense } from 'react'

import { AuthContextType } from '../contexts/auth.context'
import { BreadCrumbKeys } from '../utils/breadCrumbs'

const TanStackRouterDevtools =
    process.env.NODE_ENV === 'production'
        ? () => null // Render nothing in production
        : lazy(() =>
              import('@tanstack/router-devtools').then((res) => ({
                  default: res.TanStackRouterDevtools,
              })),
          )

const TanStackReactQueryDevtools =
    process.env.NODE_ENV === 'production'
        ? () => null // Render nothing in production
        : lazy(() =>
              import('@tanstack/react-query-devtools').then((res) => ({
                  default: res.ReactQueryDevtools,
              })),
          )

export type RooterContext = {
    breadCrumb?: BreadCrumbKeys | null
    projectId?: string | null
    auth: AuthContextType['user'] | undefined
}

const App = () => {
    return (
        <>
            <Outlet />
            <Suspense>
                <TanStackRouterDevtools />
            </Suspense>
            <Suspense>
                <TanStackReactQueryDevtools initialIsOpen={false} />
            </Suspense>
        </>
    )
}

export const Route = createRootRouteWithContext<RooterContext>()({
    component: App,
})
