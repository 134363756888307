import { EElementOrText, insertNode, Value } from '@udecode/plate-common'

import { PlateCloudEditor } from '../attachmentsPlugin/types'
import { TCloudAttachmentElement } from './types'

type CloudAttachmentValue = TCloudAttachmentElement[]

export const withCloudAttachment = <
    V extends Value = Value,
    E extends PlateCloudEditor<V> = PlateCloudEditor<V>,
>(
    editor: E,
    // plugin: WithPlatePlugin<CloudAttachmentPlugin, V, E>,
) => {
    editor.attachments.genericFileHandlers = {
        onSuccess(e) {
            const node: EElementOrText<CloudAttachmentValue> = {
                type: 'cloud_attachment',
                url: e.url,
                id: e.id,
                name: e.name,
                fileType: e.fileType,
                children: [{ text: '' }],
            }
            const anotherNode = {
                type: 'paragraph',
                children: [{ text: '' }],
            }
            insertNode<Value>(editor, node)
            insertNode<Value>(editor, anotherNode)
        },
    }

    return editor
}
