import { Value } from '@udecode/slate'
import { create } from 'zustand'

type State = {
    description: Value | null
    setDescription: (description: Value | null) => void
    getDescription: () => Value | null
    activeReplyId: string | null
    setActiveReplyId: (activeReplyId: string | null) => void
    reset: () => void
}

export const useTaskStore = create<State>((set, get) => ({
    description: null,
    getDescription: () => {
        const { description } = get()
        return description
    },
    setDescription: (description) => set({ description }),
    activeReplyId: null,
    setActiveReplyId: (activeReplyId) => set({ activeReplyId }),
    reset: () => set({ description: null, activeReplyId: null }),
}))
