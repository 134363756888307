import { BACKEND_URL } from './env'

export const uploadFile = async (file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    const response = await fetch(BACKEND_URL + '/upload', {
        method: 'POST',
        body: formData,
    })
    return await response.json()
}
