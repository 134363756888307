import { getRouteApi, Link } from '@tanstack/react-router'

import { useProject } from '../../contexts/project.context'
import { AssigneeBox } from '../Issue/MultiSelect'
import { Button } from '../Primitives/Button'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../Primitives/Select'

const ROUTE = '/_auth/project/$projectId/'
const routeApi = getRouteApi(ROUTE)

export const BoardFilters = () => {
    const { project } = useProject()
    const navigate = routeApi.useNavigate()
    const { search } = routeApi.useLoaderDeps()

    const onEpicChange = (epicId: string | null) => {
        navigate({
            search: (search) => ({
                ...search,
                epicId: epicId || undefined,
            }),
        })
    }

    const onCycleChange = (cycleId: string | null) => {
        navigate({
            search: (search) => ({
                ...search,
                cycleId: cycleId || undefined,
            }),
        })
    }

    const onMemberChange = (members: string[]) => {
        navigate({
            search: (search) => ({
                ...search,
                members,
            }),
        })
    }

    return (
        <div className="flex space-x-4">
            <Link to="/project/$projectId" params={{ projectId: project.id }} search={{}}>
                <Button variant="outline">X</Button>
            </Link>
            <AssigneeBox
                className="w-[200px]"
                selectedPeople={project.members.filter((member) =>
                    search.members?.includes(member.id),
                )}
                peopleOptions={project.members}
                onChange={onMemberChange}
            />

            {project.epics.length > 0 && (
                <div className="w-[200px]">
                    <Select onValueChange={onEpicChange} value={search.epicId ?? undefined}>
                        <SelectTrigger className=" bg-background">
                            <SelectValue>
                                {project.epics?.find((m) => m.id === search.epicId)?.name ??
                                    'Select epic'}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectItem value={null as any}>No epic</SelectItem>
                                {project.epics.map((status) => {
                                    return (
                                        <SelectItem key={status.id} value={status.id}>
                                            {status.name}
                                        </SelectItem>
                                    )
                                })}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            )}
            {project.cycles.length > 0 && (
                <div className="w-[200px]">
                    <Select onValueChange={onCycleChange} value={search.cycleId ?? undefined}>
                        <SelectTrigger className=" bg-background">
                            <SelectValue>
                                {project.cycles?.find((m) => m.id === search.cycleId)?.name ||
                                    'Select cycle'}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectItem value={null as any}>No cycle</SelectItem>
                                {project.cycles.map((status) => {
                                    return (
                                        <SelectItem key={status.id} value={status.id}>
                                            {status.name}
                                        </SelectItem>
                                    )
                                })}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            )}
        </div>
    )
}
