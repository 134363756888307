import { useRef, useState } from 'react'

import { toast } from '../../hooks/useToast'
import { serialize } from '../../utils/editorSerialize'
import { trpc } from '../../utils/trpc'
import { CommentEditor } from '../Plate/CommentEditor'
import { Button } from '../Primitives/Button'

interface AddCommentProps {
    issueId: string
    parentId?: string
    onCommentCb?: () => void
    cancelReply?: () => void
}

const initialBody = [
    {
        type: 'p',
        children: [{ text: '' }],
    },
]

export const AddComment = ({ issueId, parentId, onCommentCb, cancelReply }: AddCommentProps) => {
    const [body, setBody] = useState<any>(null)
    const ref = useRef(null)
    const context = trpc.useUtils()

    const { mutateAsync } = trpc.issue.createComment.useMutation()

    const submit = async () => {
        const bodyText = serialize(body ?? [])

        await mutateAsync({
            issueId,
            body,
            bodyText,
            parentId,
        })
        setBody(initialBody)
        await context.issue.getCommentsByIssueId.invalidate({ issueId })
        toast({
            title: parentId ? 'Reply added' : 'Comment added',
            description: `Your ${parentId ? 'reply' : 'comment'} has been added to the issue.`,
        })
        onCommentCb?.()
    }

    return (
        <div ref={ref} className="flex flex-col rounded-lg bg-secondary p-4 text-primary">
            <CommentEditor
                onChange={setBody}
                value={body}
                placeholder={parentId ? 'Write a reply...' : 'Write a comment...'}
            />
            <div className="flex justify-end">
                {parentId && <Button onClick={cancelReply}>Cancel</Button>}
                <Button onClick={submit} variant="outline" size="xs">
                    {parentId ? 'Reply' : 'Comment'}
                </Button>
            </div>
        </div>
    )
}
