import { User } from '@flow/db'
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Transition,
} from '@headlessui/react'
import { CheckIcon } from '@radix-ui/react-icons'
import { Fragment } from 'react'

import { cn } from '../../utils/cn'
import { Avatar, AvatarImage } from '../Primitives/Avatar'
import { ScrollArea } from '../Primitives/ScrollArea'

interface AssigneeBoxProps {
    selectedPeople: User[]
    peopleOptions: User[]
    className?: string
    onChange: (user: string[]) => void
}

export const AssigneeBox = ({
    selectedPeople,
    peopleOptions,
    onChange,
    className,
}: AssigneeBoxProps) => {
    return (
        <Listbox
            value={selectedPeople}
            multiple
            onChange={(ppls) => onChange(ppls.map((p) => p.id))}
        >
            {({ open }) => (
                <div className="relative">
                    <ListboxButton
                        className={cn(
                            'flex h-9 w-[250px] items-center rounded-md border border-input bg-background px-3 py-2 text-sm shadow-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none disabled:cursor-not-allowed disabled:opacity-50',
                            className,
                        )}
                    >
                        {selectedPeople.length > 0 ? (
                            selectedPeople.map((people) => (
                                <span key={people.id} className="flex items-center">
                                    <Avatar className="size-6">
                                        <AvatarImage src={people.avatar} />
                                    </Avatar>
                                </span>
                            ))
                        ) : (
                            <span>Select assignees</span>
                        )}
                    </ListboxButton>

                    <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <ListboxOptions className="absolute z-10 mt-1 w-full rounded-md border bg-secondary py-1 text-sm text-popover-foreground shadow-md">
                            <ScrollArea className="h-40">
                                <div>
                                    {peopleOptions.map((person) => (
                                        <ListboxOption
                                            key={person.id}
                                            className={({ active, selected }) =>
                                                cn(
                                                    'relative flex w-full cursor-default select-none items-center px-4 py-2',
                                                    {
                                                        'bg-muted/50': active,
                                                        'bg-muted': selected,
                                                    },
                                                )
                                            }
                                            value={person}
                                        >
                                            {({ selected, focus }) => (
                                                <>
                                                    <div className="flex items-center">
                                                        <img
                                                            src={person.avatar}
                                                            alt=""
                                                            className="size-5 shrink-0 rounded-full"
                                                        />
                                                        <span className={cn('ml-3 block truncate')}>
                                                            {person.name}
                                                        </span>
                                                    </div>

                                                    {selected ? (
                                                        <span
                                                            className={cn(
                                                                focus
                                                                    ? 'text-secondary'
                                                                    : 'text-primary',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                                            )}
                                                        >
                                                            <CheckIcon
                                                                className="size-5 text-primary"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </ListboxOption>
                                    ))}
                                </div>
                            </ScrollArea>
                        </ListboxOptions>
                    </Transition>
                </div>
            )}
        </Listbox>
    )
}
