import { createFileRoute } from '@tanstack/react-router'
import { useParams } from '@tanstack/react-router'
import { useState } from 'react'

import { UserSelector } from '../../../components/Project/UserSelector'
import { ProjectSettingsForm } from '../../../components/Settings/Project'
import { BreadCrumbs } from '../../../utils/breadCrumbs'
import { trpc } from '../../../utils/trpc'

export const ProjectSettings = () => {
    const { projectId } = useParams({
        strict: false,
    })

    const { data } = trpc.project.getOne.useQuery({
        id: projectId || '',
    })
    const [userToAdd, setUserToAdd] = useState<string | null>(null)
    const utils = trpc.useUtils()

    const { mutateAsync: addMember } = trpc.project.addMember.useMutation()
    const { mutateAsync: removeMember } = trpc.project.removeMember.useMutation()

    const handleAddMember = async () => {
        if (!userToAdd || !projectId) {
            return
        }
        await addMember({
            id: projectId,
            userId: userToAdd,
        })
        await utils.project.getOne.invalidate({ id: projectId })
    }

    const handleRemoveMember = async (userId: string) => {
        if (!projectId) {
            return
        }
        await removeMember({
            id: projectId,
            userId,
        })
        await utils.project.getOne.invalidate({ id: projectId })
    }

    const { data: users } = trpc.auth.getUsers.useQuery()

    if (!data || !users) {
        return <div>Loading...</div>
    }

    return (
        <div className="relative">
            <div className="divide-y divide-white/5">
                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-white">General</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-400">Basic stuff</p>
                    </div>
                    <div className="md:col-span-2">
                        <ProjectSettingsForm project={data} />
                    </div>
                </div>

                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-white">Users</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-400">
                            Manage who has works on this project
                        </p>
                    </div>
                    <ul className="col-span-2">
                        <div className="flex items-center space-x-8">
                            <UserSelector users={users} value={userToAdd} onChange={setUserToAdd} />
                            <button
                                onClick={handleAddMember}
                                className="rounded-md bg-brand-blue px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-brand-blue/60 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-blue"
                            >
                                Add
                            </button>
                        </div>
                        {data.members.map((user) => {
                            return (
                                <li className="flex justify-between gap-x-6 py-5" key={user.id}>
                                    <div className="flex gap-x-4">
                                        <img
                                            className="size-12 flex-none rounded-full bg-gray-50"
                                            src={user.avatar}
                                            alt=""
                                        />
                                        <div className="min-w-0 flex-auto">
                                            <p className="text-sm font-semibold leading-6 text-white">
                                                {user.name}
                                            </p>
                                            <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                                                {user.email}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => handleRemoveMember(user.id)}
                                            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600/60 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-blue"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export const Route = createFileRoute('/_auth/project/$projectId/settings')({
    component: ProjectSettings,
    beforeLoad: () => {
        return {
            breadCrumb: BreadCrumbs.ProjectSettings,
        }
    },
})
