import { EditUserSalarySchema, GetUsersAdminOutput } from '@schema'
import { useState } from 'react'

import { toast } from '../../hooks/useToast'
import { formatToCurrency } from '../../utils/formatToCurrency'
import { trpc } from '../../utils/trpc'
import { Avatar, AvatarImage } from '../Primitives/Avatar'
import { Button } from '../Primitives/Button'
import { Card, CardContent, CardHeader, CardTitle } from '../Primitives/Card'
import { Input } from '../Primitives/Input'
import { Label } from '../Primitives/Label'

interface Props {
    user: GetUsersAdminOutput
    startDate: Date | undefined
    endDate: Date | undefined
}

export const UserCard = ({ user, startDate, endDate }: Props) => {
    const context = trpc.useUtils()
    const [salary, setSalary] = useState<number>(user.salary || 0)

    const { mutateAsync: editUserSalary } =
        trpc.auth.editUserSalary.useMutation<EditUserSalarySchema>({
            async onSuccess() {
                toast({
                    title: 'Salary updated',
                    description: 'User salary has been updated',
                })
                await context.auth.getUsers.invalidate()
            },
        })

    const updateSalary = async () => {
        await editUserSalary({ id: user.id, salary })
    }

    const calculateLoggedHours = (projectId?: string) => {
        const loggedMinutes = user.timeEntries
            .filter((entry) => (projectId ? entry.issue.projectId === projectId : true))
            .filter((entry) =>
                startDate && endDate && entry.endDate
                    ? entry.startDate >= startDate && entry.endDate <= endDate
                    : entry.endDate,
            )
            .map((entry) => entry.timeSpent || 0)
        const totalMinutes = loggedMinutes.reduce((acc, curr) => acc + curr, 0)
        return Math.round(totalMinutes / 60)
    }

    return (
        <Card>
            <CardHeader className="mb-5 flex items-center space-x-2 rounded-t-lg border-b bg-slate-200 dark:bg-slate-800">
                <Avatar>
                    <AvatarImage src={user.avatar} alt={user.name} />
                </Avatar>
                <CardTitle>{user.name}</CardTitle>
                <a href={`mailto:${user.email}`} className="text-sm">
                    {user.email}
                </a>
            </CardHeader>
            <CardContent className="space-y-3 text-sm">
                <div className="flex items-center space-x-3">
                    <Label>Monthly gross salary (HUF):</Label>
                    <Input
                        type="number"
                        value={salary || ''}
                        onChange={(e) => setSalary(Number(e.target.value))}
                        className="max-w-[200px] [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    />
                    <Button variant="outline" onClick={updateSalary}>
                        Save
                    </Button>
                </div>
                <div>Hourly rate: {formatToCurrency(salary / 160)}</div>
                <div className="flex items-center space-x-2">
                    <div>Total logged hours: {calculateLoggedHours()}</div>
                    <div>
                        Total cost: {formatToCurrency((salary / 160) * calculateLoggedHours())}
                    </div>
                </div>
                <div className="border-t pt-2">Projects</div>
                <ul className="space-y-2">
                    {user.projects.map((project) => (
                        <li key={project.id} className="flex items-center space-x-3">
                            <div className="flex items-center space-x-2">
                                <span className="rounded-sm bg-brand-purple px-1 py-[.2px] text-muted dark:text-primary">
                                    {project.id}
                                </span>
                                <span className="ml-1 flex flex-1 justify-start text-xs font-semibold text-muted-foreground">
                                    {project.name}
                                </span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <div>Logged hours: {calculateLoggedHours(project.id)}</div>
                                <div>
                                    Cost:{' '}
                                    {formatToCurrency(
                                        (salary / 160) * calculateLoggedHours(project.id),
                                    )}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </CardContent>
        </Card>
    )
}
