import { zodResolver } from '@hookform/resolvers/zod'
import { PlusIcon } from '@radix-ui/react-icons'
import { CreateHolidaySchema, createHolidaySchema } from '@schema'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Dialog, DialogContent, DialogTrigger } from '../../components/Primitives/Dialog'
import { useAuth } from '../../contexts/auth.context'
import { useToast } from '../../hooks/useToast'
import { trpc } from '../../utils/trpc'
import { Button } from '../Primitives/Button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../Primitives/Form'
import { Input } from '../Primitives/Input'
import { DatePicker } from '../Tracker/DatePicker'

export const AddHoliday = () => {
    const { user } = useAuth()
    const [open, setOpen] = useState(false)
    const context = trpc.useUtils()

    const form = useForm<CreateHolidaySchema>({
        resolver: zodResolver(createHolidaySchema),
        defaultValues: {
            userId: user?.id,
            start: new Date(),
            end: new Date(),
            comment: '',
        },
    })

    const { toast } = useToast()

    const { mutateAsync } = trpc.holiday.create.useMutation({
        onSuccess: async () => {
            toast({
                title: 'Holiday created',
            })
            await context.holiday.invalidate()
        },
    })

    const { control, handleSubmit } = form

    const onSubmit = async (data: CreateHolidaySchema) => {
        await mutateAsync(data)
        setOpen(false)
        form.reset()
    }

    return (
        <Dialog onOpenChange={setOpen} open={open}>
            <DialogTrigger asChild>
                <Button variant="outline" className="mb-5">
                    Add holiday
                </Button>
            </DialogTrigger>
            <DialogContent>
                <span className="mb-5 text-sm">Add Holiday for {user?.name}:</span>
                <Form {...form}>
                    <form
                        className="grid grid-cols-2 grid-rows-3 gap-3"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <FormField
                            control={control}
                            name="start"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <FormItem className="flex flex-col self-center">
                                        <FormLabel>Start date</FormLabel>
                                        <FormControl>
                                            <DatePicker
                                                setDate={(val) => onChange(val)}
                                                date={value}
                                            />
                                        </FormControl>
                                    </FormItem>
                                )
                            }}
                        />
                        <FormField
                            control={control}
                            name="end"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <FormItem className="flex flex-col self-center">
                                        <FormLabel>End date</FormLabel>
                                        <FormControl>
                                            <DatePicker
                                                setDate={(val) => onChange(val)}
                                                date={value}
                                            />
                                        </FormControl>
                                    </FormItem>
                                )
                            }}
                        />
                        <FormField
                            control={control}
                            name="comment"
                            render={({ field }) => (
                                <FormItem className="col-span-2">
                                    <FormLabel>Comment</FormLabel>
                                    <FormControl>
                                        <Input autoFocus={true} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button
                            variant="outline"
                            type="submit"
                            className="col-span-2 row-start-3 place-self-end"
                        >
                            <PlusIcon className="mr-2" />
                            Add
                        </Button>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}
