import { QueryClientProvider } from '@tanstack/react-query'
import {
    createRouter,
    parseSearchWith,
    RouterProvider,
    stringifySearchWith,
} from '@tanstack/react-router'
import { parse, stringify } from 'jsurl2'

import { Toaster } from './components/Primitives/Toaster'
import { AuthContextProvider, useAuth } from './contexts/auth.context'
import { ThemeProvider } from './contexts/theme.context'
import { routeTree } from './routeTree.gen'
import { queryClient, trpc, trpcClient } from './utils/trpc'

const router = createRouter({
    routeTree,
    defaultPreload: 'intent',
    context: {
        breadCrumb: null,
        auth: undefined!,
    },
    parseSearch: parseSearchWith(parse),
    stringifySearch: stringifySearchWith(stringify),
})

declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router
    }
}

export const InnerApp = () => {
    const { user } = useAuth()
    return (
        <RouterProvider
            router={router}
            context={{
                auth: user,
            }}
        />
    )
}

export const App = () => {
    return (
        <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
            <trpc.Provider client={trpcClient} queryClient={queryClient}>
                <QueryClientProvider client={queryClient}>
                    <AuthContextProvider>
                        <InnerApp />
                    </AuthContextProvider>
                </QueryClientProvider>
                <Toaster />
            </trpc.Provider>
        </ThemeProvider>
    )
}
