import { zodResolver } from '@hookform/resolvers/zod'
import { CreateBranchSchema, createBranchSchema, GetIssueOutput } from '@schema'
import { GitBranch, GitBranchPlus, Github, GitPullRequest } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { useToast } from '../../hooks/useToast'
import { copyToClipboard } from '../../utils/clipboard'
import { trpc } from '../../utils/trpc'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '../Primitives/AlertDialog'
import { Button } from '../Primitives/Button'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '../Primitives/Dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../Primitives/Form'
import { Input } from '../Primitives/Input'
import { Label } from '../Primitives/Label'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../Primitives/Tooltip'

interface GithubActionsProps {
    issue: GetIssueOutput
}

export const GithubActions = ({ issue }: GithubActionsProps) => {
    const { data } = trpc.github.getBranches.useQuery({
        issueId: issue.id,
    })

    if (!data) return null

    return (
        <div className="flex flex-col space-y-2">
            <Label className="flex space-x-4">
                Github
                <Github size={16} className="ml-1" />
            </Label>
            <div className="">
                <div className="flex flex-col space-y-2">
                    {data.map((branch) => (
                        <div key={branch.id} className="flex flex-col space-y-2 rounded-md p-2">
                            <div>
                                <TooltipProvider delayDuration={0}>
                                    <Tooltip>
                                        <TooltipTrigger>
                                            <div
                                                onClick={() =>
                                                    copyToClipboard(`git checkout ${branch.name}`)
                                                }
                                                className="inline-flex items-start gap-1 rounded border bg-muted p-1.5 font-mono text-xs font-medium text-white opacity-100"
                                            >
                                                <GitBranch size={14} />
                                                <p className="text-left">{branch.name}</p>
                                            </div>
                                        </TooltipTrigger>
                                        <TooltipContent align="start">
                                            Copy as git command
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            </div>

                            {branch.pullRequest ? (
                                <Button asChild>
                                    <a
                                        href={branch.pullRequest.url}
                                        className="mt-4 flex items-center space-x-2 text-sm"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <GitPullRequest size={14} />
                                        <p className="text-left">
                                            #{branch.pullRequest.number} {branch.pullRequest.url}
                                        </p>
                                    </a>
                                </Button>
                            ) : (
                                <CreatePullRequestDialog branchId={branch.id} />
                            )}
                        </div>
                    ))}
                </div>
            </div>
            {data.length === 0 && (
                <div className="flex space-x-4">
                    <CreateBranchDialog issue={issue} />
                </div>
            )}
        </div>
    )
}

export const CreatePullRequestDialog = ({ branchId }: { branchId: string }) => {
    const { toast } = useToast()
    const context = trpc.useUtils()
    const { mutate } = trpc.github.createPullRequest.useMutation({
        onSuccess: () => {
            toast({
                title: 'Pull request created',
                description: 'Pull request has been created',
            })
        },
        onError: (err) => {
            toast({
                title: 'Pull request creation failed',
                description: err.message,
            })
        },
        onSettled: () => {
            context.github.getBranches.invalidate()
        },
    })

    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button size="xs" className="text-xs">
                    Create Pull Request
                    <GitPullRequest size={14} />
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Do you want to create a pull request?</AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                        onClick={() => {
                            mutate({
                                branchId,
                            })
                        }}
                    >
                        Continue
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export const CreateBranchDialog = ({ issue }: GithubActionsProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const form = useForm<CreateBranchSchema>({
        defaultValues: {
            branchName: slugify(issue.name),
            issueId: issue.id,
        },
        resolver: zodResolver(createBranchSchema),
    })
    const { toast } = useToast()

    const context = trpc.useUtils()

    const { mutateAsync, isPending } = trpc.github.createBranch.useMutation({
        onSuccess: () => {
            toast({
                title: 'Branch created',
                description: 'Branch has been created',
            })
        },
        onError: (err) => {
            toast({
                title: 'Branch creation failed',
                description: err.message,
            })
        },
        onSettled: () => {
            context.github.getBranches.invalidate()
        },
    })

    const namePrefix = `feat/#${issue.project.id}-${issue.key}`

    const onSubmit = async (input: CreateBranchSchema) => {
        await mutateAsync({
            ...input,
            branchName: `${namePrefix}${input.branchName}`,
        })
        setIsOpen(false)
    }

    return (
        <Dialog open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
            <DialogTrigger asChild>
                <Button size="sm">
                    Create Branch
                    <GitBranchPlus size={16} />
                </Button>
            </DialogTrigger>
            <Form {...form}>
                <form className="space-y-2">
                    <DialogContent className="sm:max-w-[450px]">
                        <DialogHeader>
                            <DialogTitle>Create Branch</DialogTitle>
                        </DialogHeader>
                        <FormField
                            control={form.control}
                            name="branchName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Branch name</FormLabel>
                                    <FormControl>
                                        <div className="flex h-9 items-center justify-stretch">
                                            <div className="flex h-full flex-col items-start justify-center whitespace-nowrap rounded-l-md border bg-white px-2 text-sm text-background ring-0">
                                                {namePrefix}
                                            </div>
                                            <Input
                                                {...field}
                                                value={field.value}
                                                onChange={(val) =>
                                                    field.onChange(slugify(val.target.value))
                                                }
                                                autoFocus
                                                id="branchName"
                                                className="col-span-3 rounded-l-none border-l-0 text-sm focus-visible:ring-0 active:ring-0"
                                            />
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <DialogFooter>
                            <Button
                                disabled={isPending}
                                onClick={() => form.handleSubmit(onSubmit)()}
                            >
                                Create Branch
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </form>
            </Form>
        </Dialog>
    )
}

function slugify(str: string): string {
    const trimmedStr = str.trimStart() // Trim leading and trailing spaces

    if (trimmedStr.length === 0) {
        return '' // Return empty string if the input is empty
    }

    const slug = trimmedStr
        .toLowerCase() // Convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // Remove all non-alphanumeric characters except hyphens and spaces
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/-+/g, '-') // Replace multiple consecutive hyphens with a single hyphen

    return slug.startsWith('_') ? slug : `_${slug}` // Prepend underscore if it doesn't start with one
}
