import { useTaskStore } from '../../hooks/useTaskStore'
import { trpc } from '../../utils/trpc'
import { AddComment } from '../Comment/AddComment'
import { CommentDisplay } from '../Comment/CommentDisplay'
import { Label } from '../Primitives/Label'

interface CommentBoxProps {
    issueId: string
}

export const CommentContainer = ({ issueId }: CommentBoxProps) => {
    const { data: comments } = trpc.issue.getCommentsByIssueId.useQuery({ issueId })

    const reply = useTaskStore((s) => s.activeReplyId)

    const lastComment = comments?.[comments.length - 1]
    return (
        <div className="flex flex-col space-y-4 pb-20">
            <Label>Comments</Label>
            {comments?.map((comment) => (
                <CommentDisplay issueId={issueId} key={comment.id} comment={comment} />
            ))}
            {reply === null && <AddComment key={lastComment?.id || ''} issueId={issueId} />}
        </div>
    )
}
