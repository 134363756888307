import { Cross2Icon } from '@radix-ui/react-icons'
import { GetIssueOutput } from '@schema'
import { Table } from '@tanstack/react-table'

import { useProject } from '../../contexts/project.context'
import { trpc } from '../../utils/trpc'
import { NewIssueDialog } from '../Issue/NewIssueDialog'
import { Button } from '../Primitives/Button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '../Primitives/DropdownMenu'
import { Input } from '../Primitives/Input'
import { DataTableViewOptions } from './DataTableViewOptions'
import { TaskFilters } from './TaskFilters'

interface DataTableToolbarProps {
    table: Table<GetIssueOutput>
}

export function DataTableToolbar({ table }: DataTableToolbarProps) {
    const { project } = useProject()
    const projectId = project?.id
    const isFiltered = table.getState().columnFilters.length > 0
    const context = trpc.useUtils()

    const { mutateAsync: moveTasks } = trpc.issue.modifyStatus.useMutation({
        onSuccess: async () => {
            await context.issue.getByProjectId.invalidate({ projectId })
        },
    })

    const selectedRows = table.getSelectedRowModel()
    const statuses = project.statuses.map((status) => ({
        name: status.name,
        id: status.id,
    }))

    if (!projectId) return null

    const handleMoveTasks = async (statusId: string) => {
        const idsToMove = selectedRows.rows.map((data) => data.original.id)
        await moveTasks({
            issues: idsToMove,
            projectId: projectId,
            statusId: statusId,
        })
        table.resetRowSelection()
    }

    return (
        <div className="flex flex-1 flex-col space-y-2">
            <div className="flex justify-between">
                <div className="flex items-center space-x-2">
                    <NewIssueDialog key={projectId} />
                    {selectedRows.rows.length > 0 ? (
                        <div className="px-2 lg:px-3">
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button variant="outline">Move</Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                    {statuses.map((status) => (
                                        <DropdownMenuItem
                                            key={status.id}
                                            onClick={() => handleMoveTasks(status.id)}
                                        >
                                            {status.name}
                                        </DropdownMenuItem>
                                    ))}
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    ) : null}
                </div>
                <DataTableViewOptions table={table} />
            </div>
            <div className="flex flex-1 space-x-2">
                <TaskFilters />
                <Input
                    placeholder="Filter tasks..."
                    value={(table.getColumn('name')?.getFilterValue() as string) ?? ''}
                    onChange={(event) =>
                        table.getColumn('name')?.setFilterValue(event.target.value)
                    }
                    className="h-9 w-[150px] lg:w-[250px]"
                />
                {isFiltered && (
                    <Button
                        variant="ghost"
                        onClick={() => table.resetColumnFilters()}
                        className="h-9 px-2 lg:px-3"
                    >
                        Reset
                        <Cross2Icon className="ml-2 size-4" />
                    </Button>
                )}
            </div>
        </div>
    )
}
