import * as Sentry from '@sentry/react'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import { App } from './app'

if (import.meta.env.PROD) {
    Sentry.init({
        dsn: 'https://95d2898e1dc631ba38fce148362c59f6@o1347694.ingest.sentry.io/4505663951732736',
        integrations: [Sentry.zodErrorsIntegration({}), Sentry.replayIntegration()],
        tracePropagationTargets: ['localhost', 'https://backend.flow.square21.dev/'],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

const rootElement = document.getElementById('root')!
if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement)
    root.render(
        <StrictMode>
            <App />
        </StrictMode>,
    )
}
