import { Epic } from '@flow/db'
import { zodResolver } from '@hookform/resolvers/zod'
import { DashboardIcon } from '@radix-ui/react-icons'
import { EditEpicSchema, editEpicSchema } from '@schema'
import { useParams } from '@tanstack/react-router'
import { Edit2 as EditIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { convertToTimeString } from '../../utils/date'
import { trpc } from '../../utils/trpc'
import { Button } from '../Primitives/Button'
import { Dialog, DialogContent, DialogTrigger } from '../Primitives/Dialog'
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../Primitives/Form'
import { Input } from '../Primitives/Input'
import { Textarea } from '../Primitives/Textarea'
import { DatePicker } from '../Tracker/DatePicker'

interface Props {
    epic: Epic
}

export const EditEpicDialog = ({ epic }: Props) => {
    const [open, setOpen] = useState(false)
    const context = trpc.useUtils()
    const { projectId } = useParams({
        strict: false,
    })

    const form = useForm<EditEpicSchema>({
        defaultValues: {
            id: epic.id,
            projectId: projectId,
            name: epic.name,
            description: epic.description || '',
            estimationString: convertToTimeString(epic.estimation || 0),
            startDate: epic.startDate,
            targetDate: epic.targetDate,
        },
        resolver: zodResolver(editEpicSchema),
    })

    useEffect(() => {
        form.reset({
            id: epic.id,
            projectId: projectId,
            name: epic.name,
            description: epic.description || '',
            estimationString: convertToTimeString(epic.estimation || 0),
            startDate: epic.startDate,
            targetDate: epic.targetDate,
        })
    }, [epic, form, projectId])

    const { data: project } = trpc.project.getOne.useQuery({ id: projectId || '' })

    if (!projectId || !project) return null

    const { mutateAsync } = trpc.epic.edit.useMutation({
        onSuccess: async () => {
            await context.epic.getByProject.invalidate()
            form.reset()
        },
    })

    const onSubmit = async (input: EditEpicSchema) => {
        if (input.name === '') return
        await mutateAsync({
            ...input,
        })
        setOpen(false)
    }

    return (
        <Dialog onOpenChange={setOpen} open={open}>
            <DialogTrigger asChild>
                <Button size="icon" variant="ghost">
                    <EditIcon className="size-4" />
                </Button>
            </DialogTrigger>
            <DialogContent className="mt-0 max-w-[600px]">
                <div className="flex flex-col justify-start space-y-4">
                    <div className="mb-4 flex items-center space-x-1 self-start ">
                        <div className="flex items-center space-x-1 rounded-lg bg-secondary px-2 py-1">
                            <DashboardIcon />
                            <span className="rounded-sm bg-muted-foreground px-1 py-[.5px] text-sm">
                                {project?.id}
                            </span>
                            <span className="font-light">{project?.name}</span>
                        </div>
                        <span className="text-sm">Edit epic</span>
                        <span />
                    </div>
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
                            <FormField
                                control={form.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem className="w-[300px]">
                                        <FormControl>
                                            <Input autoFocus {...field} placeholder="Epic name" />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="description"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Description</FormLabel>
                                        <FormControl>
                                            <Textarea {...field} placeholder="Description" />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="estimationString"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Estimation</FormLabel>
                                        <FormControl>
                                            <Input
                                                {...field}
                                                value={field.value}
                                                onChange={(val) => field.onChange(val)}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                        <FormDescription>
                                            Use the format: 2w 4d 6h 45m
                                        </FormDescription>
                                    </FormItem>
                                )}
                            />
                            <div className="flex space-x-2">
                                <FormField
                                    control={form.control}
                                    name="startDate"
                                    render={({ field }) => (
                                        <FormItem className="flex flex-col">
                                            <FormLabel>Start Date</FormLabel>
                                            <FormControl>
                                                <DatePicker
                                                    withClear={true}
                                                    setDate={(val) => field.onChange(val ?? null)}
                                                    date={field.value ?? undefined}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="targetDate"
                                    render={({ field }) => (
                                        <FormItem className="flex flex-col">
                                            <FormLabel>Target Date</FormLabel>
                                            <FormControl>
                                                <DatePicker
                                                    withClear={true}
                                                    setDate={(val) => field.onChange(val ?? null)}
                                                    date={field.value ?? undefined}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>

                            <div className="mt-4 flex justify-end space-x-2">
                                <Button type="submit" className="self-end" variant="secondary">
                                    Update
                                </Button>
                            </div>
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    )
}
