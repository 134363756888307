import { GetIssueByAssigneOutput } from '@schema'

export const TaskListElement = ({ task }: { task: GetIssueByAssigneOutput }) => {
    return (
        <div className="flex w-full cursor-pointer rounded-lg border p-2">
            <div className="mr-4 flex w-12">{task.projectId}</div>
            <div className="flex flex-1 space-x-2 ">{task.name}</div>
        </div>
    )
}
