import { State as IssueState } from '@flow/db'
import { ProjectData } from '@schema'
import { keepPreviousData } from '@tanstack/react-query'
import { createContext, useContext } from 'react'

import { trpc } from '../utils/trpc'

export const ProjectContext = createContext<ProjectContextType>({} as ProjectContextType)

export type ProjectContextType = {
    project: ProjectData
    issueFilter?: IssueState
}

export const ProjectContextProvider: React.FC<{
    children?: React.ReactNode
    projectId: string
    initialData?: ProjectData | null
}> = ({ children, projectId, initialData }) => {
    const { data: project } = trpc.project.getOne.useQuery(
        {
            id: projectId,
        },
        {
            ...(initialData && { initialData }),
            placeholderData: keepPreviousData,
        },
    )

    if (!project) {
        return <div />
    }
    return (
        <ProjectContext.Provider
            value={{
                project,
            }}
        >
            {children}
        </ProjectContext.Provider>
    )
}

export const useProject = (): ProjectContextType => {
    return useContext(ProjectContext)
}
