import type { AppRouter } from '@api'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import { createTRPCQueryUtils, createTRPCReact, httpBatchLink, loggerLink } from '@trpc/react-query'
import { inferRouterInputs, inferRouterOutputs } from '@trpc/server'
import superjson from 'superjson'

import { BACKEND_URL } from './env'
import { getJwt } from './ls'

export type ClinicRouterInput = inferRouterInputs<AppRouter>
export type ClinicRouterOutput = inferRouterOutputs<AppRouter>

export type ParsedTRPCErrorMessage = {
    code: string
    expected: string
    received: string
    path: [number, string]
    message: string
}[]

export const trpc = createTRPCReact<AppRouter>()

const createTRPCClient = () => {
    return trpc.createClient({
        links: [
            httpBatchLink({
                transformer: superjson,
                url: BACKEND_URL + '/trpc',
                headers() {
                    const token = getJwt()
                    return {
                        ...(token && { Authorization: `Bearer ${token}` }),
                    }
                },
            }),
            loggerLink({
                enabled: (opts) => opts.direction === 'down' && opts.result instanceof Error,
            }),
        ],
    })
}
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchInterval: 60000,
        },
    },
    queryCache: new QueryCache(),
    mutationCache: new MutationCache(),
})

export const trpcClient = createTRPCClient()

export const apiUtils = createTRPCQueryUtils({ queryClient, client: trpcClient })
