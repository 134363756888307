import {
    ActivityLogIcon,
    CardStackIcon,
    DashboardIcon,
    DoubleArrowRightIcon,
    IdCardIcon,
} from '@radix-ui/react-icons'
import { Link, useRouter } from '@tanstack/react-router'
import { ArrowLeft, CalendarRange, ChevronRight, InboxIcon, RadioTower } from 'lucide-react'
import { match } from 'ts-pattern'

import { BreadCrumbKeys, BreadCrumbs } from '../../utils/breadCrumbs'
import { trpc } from '../../utils/trpc'
import { Button } from '../Primitives/Button'

interface BreadCrumbsProps {
    crumb?: BreadCrumbKeys | undefined
    projectId: string | undefined
    issueId?: string | undefined
    epicId?: string | undefined
    cycleId?: string | undefined
}

export const BreadCrumb = ({ crumb, projectId, issueId, epicId, cycleId }: BreadCrumbsProps) => {
    const { history } = useRouter()
    const { data: project } = trpc.project.getOne.useQuery(
        { id: projectId || '' },
        {
            enabled: !!projectId,
        },
    )

    const { data: issue } = trpc.issue.getById.useQuery(
        { issueId: issueId || '' },
        {
            enabled: !!issueId,
        },
    )
    return (
        <div className="flex items-center space-x-1">
            <Button onClick={() => history.back()} variant="outline" size="sm">
                <ArrowLeft className="size-4" />
            </Button>
            {match(crumb)
                .with(BreadCrumbs.AdminDashboard, () => {
                    return (
                        <div className="flex items-center">
                            <Link to="/admin">
                                <Button variant="ghost" size="sm" className="space-x-1">
                                    <DashboardIcon />
                                    <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                        Admin
                                    </span>
                                    <span className="font-light">{project?.name}</span>
                                </Button>
                            </Link>
                            <ChevronRight />
                            <Button variant="ghost" size="sm">
                                <span>Dashboard</span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.AdminUsers, () => {
                    return (
                        <div className="flex items-center">
                            <Link to="/admin">
                                <Button variant="ghost" size="sm" className="space-x-1">
                                    <DashboardIcon />
                                    <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                        Admin
                                    </span>
                                    <span className="font-light">{project?.name}</span>
                                </Button>
                            </Link>
                            <ChevronRight />
                            <Button variant="ghost" size="sm">
                                <span>Users</span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.AdminProjects, () => {
                    return (
                        <div className="flex items-center">
                            <Link to="/admin">
                                <Button variant="ghost" size="sm" className="space-x-1">
                                    <DashboardIcon />
                                    <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                        Admin
                                    </span>
                                    <span className="font-light">{project?.name}</span>
                                </Button>
                            </Link>
                            <ChevronRight />
                            <Button variant="ghost" size="sm">
                                <span>Projects</span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.AdminTimeline, () => {
                    return (
                        <div className="flex items-center">
                            <Link to="/admin">
                                <Button variant="ghost" size="sm" className="space-x-1">
                                    <DashboardIcon />
                                    <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                        Admin
                                    </span>
                                    <span className="font-light">{project?.name}</span>
                                </Button>
                            </Link>
                            <ChevronRight />
                            <Button variant="ghost" size="sm">
                                <span>Timeline</span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.Dashboard, () => {
                    return (
                        <Button variant="ghost" size="sm" className="space-x-1">
                            <DashboardIcon />
                            <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                Dashboard
                            </span>
                        </Button>
                    )
                })
                .with(BreadCrumbs.Projects, () => {
                    return (
                        <div className="flex items-center">
                            <Button variant="ghost" size="sm" className="space-x-1">
                                <DashboardIcon />
                                <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                    Projects
                                </span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.Inbox, () => {
                    return (
                        <div className="flex items-center space-x-1">
                            <Button variant="ghost" size="sm" className="space-x-1">
                                <InboxIcon className="h-4" />
                                <span className="font-light">Inbox</span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.Activity, () => {
                    return (
                        <div className="flex items-center">
                            <Button variant="ghost" size="sm" className="space-x-1">
                                <RadioTower className="size-4" />
                                <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                    Activity
                                </span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.Holidays, () => {
                    return (
                        <div className="flex items-center">
                            <Button variant="ghost" size="sm" className="space-x-1">
                                <CalendarRange className="size-4" />
                                <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                    Holidays
                                </span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.Board, () => {
                    return (
                        <div className="flex items-center">
                            <Link to={`/project/${project?.id}`}>
                                <Button variant="ghost" size="sm" className="space-x-1">
                                    <DashboardIcon />
                                    <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                        {project?.id}
                                    </span>
                                    <span className="font-light">{project?.name}</span>
                                </Button>
                            </Link>
                            <ChevronRight />
                            <Button variant="ghost" size="sm">
                                <span>Board</span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.Timeline, () => {
                    return (
                        <div className="flex items-center">
                            <Link to={`/project/${project?.id}`}>
                                <Button variant="ghost" size="sm" className="space-x-1">
                                    <DashboardIcon />
                                    <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                        {project?.id}
                                    </span>
                                    <span className="font-light">{project?.name}</span>
                                </Button>
                            </Link>
                            <ChevronRight />
                            <Button variant="ghost" size="sm">
                                <span>Timeline</span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.Backlog, () => {
                    return (
                        <div className="flex items-center">
                            <Link to={`/project/${project?.id}`}>
                                <Button variant="ghost" size="sm" className="space-x-1">
                                    <DashboardIcon />
                                    <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                        {project?.id}
                                    </span>
                                    <span className="font-light">{project?.name}</span>
                                </Button>
                            </Link>
                            <ChevronRight />
                            <Button variant="ghost" size="sm">
                                <span>Backlog</span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.Archive, () => {
                    return (
                        <div className="flex items-center">
                            <Link to={`/project/${project?.id}`}>
                                <Button variant="ghost" size="sm" className="space-x-1">
                                    <DashboardIcon />
                                    <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                        {project?.id}
                                    </span>
                                    <span className="font-light">{project?.name}</span>
                                </Button>
                            </Link>
                            <ChevronRight />
                            <Button variant="ghost" size="sm">
                                <span>Archive</span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.ProjectSettings, () => {
                    return (
                        <div className="flex items-center">
                            <Link to={`/project/${project?.id}`}>
                                <Button variant="ghost" size="sm" className="space-x-1">
                                    <DashboardIcon />
                                    <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                        {project?.id}
                                    </span>
                                    <span className="font-light">{project?.name}</span>
                                </Button>
                            </Link>
                            <ChevronRight />
                            <Button variant="ghost" size="sm">
                                <span>Settings</span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.Issue, () => {
                    return (
                        <div className="flex items-center">
                            <Link to={`/project/${project?.id}`}>
                                <Button variant="ghost" size="sm" className="space-x-1">
                                    <DashboardIcon />
                                    <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                        {project?.id}
                                    </span>
                                    <span className="font-light">{project?.name}</span>
                                </Button>
                            </Link>
                            <ChevronRight />
                            <Button variant="ghost" size="sm" className="space-x-2">
                                <IdCardIcon className="text-indigo-400" />
                                <span>
                                    #{project?.id}-{issue?.key}
                                </span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.Epics, () => {
                    return (
                        <div className="flex items-center">
                            <Link to={`/project/${project?.id}`}>
                                <Button variant="ghost" size="sm" className="space-x-1">
                                    <DashboardIcon />
                                    <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                        {project?.id}
                                    </span>
                                    <span className="font-light">{project?.name}</span>
                                </Button>
                            </Link>
                            <ChevronRight />
                            <Button variant="ghost" size="sm" className="space-x-2">
                                <ActivityLogIcon className="text-violet-500" />
                                <span>Epics</span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.Epic, () => {
                    return (
                        <div className="flex items-center">
                            <Link to={`/project/${project?.id}`}>
                                <Button variant="ghost" size="sm" className="space-x-1">
                                    <DashboardIcon />
                                    <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                        {project?.id}
                                    </span>
                                    <span className="font-light">{project?.name}</span>
                                </Button>
                            </Link>
                            <ChevronRight />
                            <Button variant="ghost" size="sm" className="space-x-2">
                                <CardStackIcon className="text-green-500" />
                                <span>
                                    {
                                        project?.epics?.find((m) => {
                                            return m.id === epicId
                                        })?.name
                                    }
                                </span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.Cycles, () => {
                    return (
                        <div className="flex items-center">
                            <Link to={`/project/${project?.id}`}>
                                <Button variant="ghost" size="sm" className="space-x-1">
                                    <DashboardIcon />
                                    <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                        {project?.id}
                                    </span>
                                    <span className="font-light">{project?.name}</span>
                                </Button>
                            </Link>
                            <ChevronRight />
                            <Button variant="ghost" size="sm" className="space-x-2">
                                <CalendarRange className="h-4 text-red-400" />
                                <span>Cycles</span>
                            </Button>
                        </div>
                    )
                })
                .with(BreadCrumbs.Cycle, () => {
                    return (
                        <div className="flex items-center">
                            <Link to={`/project/${project?.id}`}>
                                <Button variant="ghost" size="sm" className="space-x-1">
                                    <DashboardIcon />
                                    <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                        {project?.id}
                                    </span>
                                    <span className="font-light">{project?.name}</span>
                                </Button>
                            </Link>
                            <ChevronRight />
                            <Button variant="ghost" size="sm" className="space-x-2">
                                <CardStackIcon className="text-green-500" />
                                <span>
                                    {
                                        project?.cycles?.find((c) => {
                                            return c.id === cycleId
                                        })?.name
                                    }
                                </span>
                            </Button>
                        </div>
                    )
                })

                .with(BreadCrumbs.List, () => {
                    return (
                        <div className="flex items-center">
                            <Link to={`/project/${project?.id}`}>
                                <Button variant="ghost" size="sm" className="space-x-1">
                                    <DashboardIcon />
                                    <span className="rounded-sm bg-muted-foreground px-1 py-[.5px]">
                                        {project?.id}
                                    </span>
                                    <span className="font-light">{project?.name}</span>
                                </Button>
                            </Link>
                            <ChevronRight />
                            <Button variant="ghost" size="sm" className="space-x-2">
                                <DoubleArrowRightIcon className="text-indigo-400" />
                                <span>List</span>
                            </Button>
                        </div>
                    )
                })
                .otherwise(() => (
                    <div>TODO BREADCRUMB</div>
                ))}
        </div>
    )
}
