import { EditPasswordSchema } from '@schema'
import { useForm } from 'react-hook-form'

import { UserOutput } from '../../contexts/auth.context'
import { useToast } from '../../hooks/useToast'
import { trpc } from '../../utils/trpc'

export const PasswordForm: React.FC<{ user: UserOutput }> = () => {
    const { register, handleSubmit } = useForm<EditPasswordSchema>({
        defaultValues: {
            password: '',
            newPassword: '',
            confirmNewPassword: '',
        },
    })
    const { toast } = useToast()
    const utils = trpc.useUtils()

    const { mutate: editUserPasswordHandler } = trpc.auth.editUserPasswordHandler.useMutation({
        onSuccess() {
            utils.auth.getMe.invalidate()
            toast({
                title: 'Password updated',
                description: 'Your password has been updated',
            })
        },
        onError(err) {
            let errorMsg = err.message || 'Error'
            if (err?.data?.zodError?.fieldErrors) {
                errorMsg = Object.values(err.data.zodError.fieldErrors)[0]?.toString() || ''
            }

            toast({
                title: 'Error',
                description: `${errorMsg || 'Please try again'}`,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = async (data: EditPasswordSchema) => {
        await editUserPasswordHandler(data)
    }

    return (
        <form className="md:col-span-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div className="col-span-full">
                    <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-white"
                    >
                        Current password
                    </label>
                    <div className="mt-2">
                        <input
                            {...register('password')}
                            type="password"
                            name="password"
                            id="password"
                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 pl-4 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-brand-purple sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

                <div className="col-span-full">
                    <label
                        htmlFor="newPassword"
                        className="block text-sm font-medium leading-6 text-white"
                    >
                        new password
                    </label>
                    <div className="mt-2">
                        <input
                            {...register('newPassword')}
                            type="password"
                            name="newPassword"
                            id="newPassword"
                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 pl-4 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-brand-purple sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className="col-span-full">
                    <label
                        htmlFor="confirmNewPassword"
                        className="block text-sm font-medium leading-6 text-white"
                    >
                        Confirm password
                    </label>
                    <div className="mt-2">
                        <input
                            {...register('confirmNewPassword')}
                            type="password"
                            name="confirmNewPassword"
                            id="confirmNewPassword"
                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 pl-4 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-brand-purple sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
            </div>

            <div className="mt-8 flex">
                <button
                    type="submit"
                    className="rounded-md bg-brand-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-blue/60 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-blue"
                >
                    Save
                </button>
            </div>
        </form>
    )
}
