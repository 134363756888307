import { GetTimeEntriesOutput } from '@schema'
import { format } from 'date-fns'
import { groupBy } from 'lodash'
import { Fragment } from 'react'

import { cn } from '../../utils/cn'
import { getGrid } from '../../utils/grid'
import { ProjectEntry } from './ProjectEntry'
import { TimeSelect } from './TimeSelect'

interface Props {
    date: Date | undefined
    setDate: (date: Date | undefined) => void
    numberOfDays: number
    setNumberOfDays: (days: number) => void
    timeEntries?: GetTimeEntriesOutput[]
}

export const TimelineDisplay = ({
    date,
    setDate,
    numberOfDays,
    setNumberOfDays,
    timeEntries,
}: Props) => {
    const days = Array.from({ length: numberOfDays }, (_, i) => i)
    const timeEntriesByProject = groupBy(timeEntries, 'issue.projectId')

    return (
        <div className="relative flex size-full flex-col text-base text-primary">
            <main className="mt-4 h-full px-8 pb-12">
                <div className="flex h-full flex-col">
                    <TimeSelect
                        date={date}
                        setDate={setDate}
                        numberOfDays={numberOfDays}
                        setNumberOfDays={setNumberOfDays}
                    />
                    <div className="mt-8 grid h-full grid-cols-8">
                        <div />
                        <div className="col-span-7">
                            <div className={cn(`grid w-full`, getGrid(numberOfDays))}>
                                {date &&
                                    days?.map((day) => (
                                        <div key={day} className="col-span-1">
                                            <div className="pb-2 text-center text-xs">
                                                {format(
                                                    new Date(
                                                        date!.getFullYear(),
                                                        date!.getMonth(),
                                                        date!.getDate() + day,
                                                    ),
                                                    'EEEE MMM d',
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        {timeEntries && timeEntries.length > 0 ? (
                            Object.keys(timeEntriesByProject).length === 1 ? (
                                <ProjectEntry
                                    date={date}
                                    numberOfDays={numberOfDays}
                                    timeEntries={timeEntries}
                                />
                            ) : (
                                Object.values(timeEntriesByProject).map((entries) => {
                                    const projectId = entries[0].issue.project.id
                                    const projectName = entries[0].issue.project.name
                                    return (
                                        <Fragment key={projectId}>
                                            <div className="col-span-8 py-6">
                                                <div className="flex items-center space-x-2">
                                                    <span className="rounded-sm bg-brand-purple px-1 py-[.2px] text-muted dark:text-primary">
                                                        {projectId}
                                                    </span>
                                                    <span className="ml-1 flex flex-1 justify-start text-xs font-semibold text-muted-foreground">
                                                        {projectName}
                                                    </span>
                                                </div>
                                            </div>
                                            <ProjectEntry
                                                key={projectId}
                                                date={date}
                                                numberOfDays={numberOfDays}
                                                timeEntries={entries}
                                            />
                                        </Fragment>
                                    )
                                })
                            )
                        ) : (
                            <div className="col-span-1 flex items-start p-4">
                                <div className="flex items-center">
                                    <h2 className="text-base">No data</h2>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </div>
    )
}
