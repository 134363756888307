export const getJwt = (key = 'jwt'): string | null => {
    const token = localStorage.getItem(key)
    return token || null
}

export const storeJwt = (jwt: string, key = 'jwt'): void => {
    return localStorage.setItem(key, jwt)
}

export const removeJwt = (key = 'jwt'): void => {
    return localStorage.removeItem(key)
}
