import { Status } from '@flow/db'
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Transition,
} from '@headlessui/react'
import { CheckIcon } from '@radix-ui/react-icons'
import { Fragment } from 'react'

import { cn } from '../../utils/cn'
import { ScrollArea } from '../Primitives/ScrollArea'

interface AssigneeBoxProps {
    selectedStates: Status[]
    stateOptions: Status[]
    className?: string
    disabled?: boolean
    onChange: (states: Status[]) => void
}

export const StatusBox = ({
    selectedStates,
    stateOptions,
    className,
    disabled,
    onChange,
}: AssigneeBoxProps) => {
    return (
        <Listbox value={selectedStates} multiple onChange={onChange} disabled={disabled}>
            {({ open }) => (
                <div className="relative">
                    <ListboxButton
                        className={cn(
                            'flex h-9 w-[250px] items-center rounded-md border border-input bg-background px-3 py-2 text-sm shadow-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none disabled:cursor-not-allowed disabled:opacity-50',
                            className,
                        )}
                    >
                        {selectedStates.length > 0 ? (
                            selectedStates.length < 4 ? (
                                selectedStates.map((state, i) => (
                                    <span
                                        key={state.id}
                                        className="flex items-center whitespace-nowrap text-sm"
                                    >
                                        {state.name}
                                        {i !== selectedStates.length - 1 && ', '}
                                    </span>
                                ))
                            ) : (
                                <span>{selectedStates.length} states</span>
                            )
                        ) : (
                            <span>Select state</span>
                        )}
                    </ListboxButton>

                    <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <ListboxOptions className="absolute z-10 mt-10 w-full rounded-md border bg-secondary py-1 text-sm text-popover-foreground shadow-md">
                            <ScrollArea className="h-40">
                                <div>
                                    {stateOptions.map((state) => (
                                        <ListboxOption
                                            key={state.id}
                                            className={({ active, selected }) =>
                                                cn(
                                                    'relative flex w-full cursor-default select-none items-center px-4 py-2',
                                                    {
                                                        'bg-muted/50': active,
                                                        'bg-muted': selected,
                                                    },
                                                )
                                            }
                                            value={state}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <div className="flex items-center">
                                                        {state.name}
                                                    </div>

                                                    {selected ? (
                                                        <span
                                                            className={cn(
                                                                active
                                                                    ? 'text-secondary'
                                                                    : 'text-primary',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                                            )}
                                                        >
                                                            <CheckIcon
                                                                className="size-5 text-primary"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </ListboxOption>
                                    ))}
                                </div>
                            </ScrollArea>
                        </ListboxOptions>
                    </Transition>
                </div>
            )}
        </Listbox>
    )
}
