import { cn, withRef } from '@udecode/cn'
import { PlateLeaf } from '@udecode/plate-common'
import React from 'react'

export const HighlightLeaf = withRef<typeof PlateLeaf>(({ children, className, ...props }, ref) => (
    <PlateLeaf
        asChild
        className={cn('bg-primary/20 text-inherit dark:bg-primary/40', className)}
        ref={ref}
        {...props}
    >
        <mark>{children}</mark>
    </PlateLeaf>
))
