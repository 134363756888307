import { createFileRoute } from '@tanstack/react-router'

import { DateDisplay } from '../../components/Dashboard/DateDisplay'
import { ProjectSummaryCard } from '../../components/Dashboard/ProjectSummaryCard'
import { TaskSummaryCard } from '../../components/Dashboard/TaksSummaryCard'
import { WeeklyStatusBadge } from '../../components/Dashboard/WeeklyStatusBadge'
import { useAuth } from '../../contexts/auth.context'
import { BreadCrumbs } from '../../utils/breadCrumbs'
import { trpc } from '../../utils/trpc'

export const Dashboard = () => {
    const { user } = useAuth()

    const { data: taskList } = trpc.issue.getByAssignedUser.useQuery()

    if (!user) {
        return null
    }
    return (
        <main className="mx-auto">
            <div className="flex w-full flex-col items-center gap-y-2">
                <DateDisplay date={new Date()} />
                <div className="text-4xl">Hello, {user.name}!</div>
                {taskList && <WeeklyStatusBadge taskList={taskList} />}
            </div>
            <div className="mt-12 flex w-full justify-center gap-12">
                {taskList && <TaskSummaryCard user={user} taskList={taskList} />}
                {user.projects && <ProjectSummaryCard projects={user.projects} />}
            </div>
        </main>
    )
}

export const Route = createFileRoute('/_auth/')({
    component: Dashboard,
    beforeLoad: () => {
        return {
            breadCrumb: BreadCrumbs.Dashboard,
        }
    },
})
