import { match } from 'ts-pattern'

type TimeUnit = 'w' | 'd' | 'h' | 'm'
type TimeData = { unit: TimeUnit; value: number }

export const parseTimeString = (timeStr: string): TimeData[] => {
    // Regex to match the following format:
    // 1w 2d 3h 4m
    const regex =
        /^(?:(?:(\d+)w)?\s?(?:(\d+)d)?\s?(?:(\d+)h)?\s?(?:(\d+)m)?|(?:\d+w\s?\d+m|\d+h|\d+m))$/

    if (!timeStr || !regex.test(timeStr)) {
        throw new Error('Invalid time string')
    }

    let currentNum = ''
    const result: TimeData[] = []

    for (const char of timeStr) {
        // If the character is a number, add it to the current number string
        if ('0123456789'.includes(char)) {
            currentNum += char
        } else if ('wdhm'.includes(char)) {
            // If the character is a unit type, convert the current number string to a number
            // and add the time data to the result array
            result.push({ unit: char as TimeUnit, value: parseInt(currentNum) })
            currentNum = '' // Reset the current number string for the next number
        } else if (char === ' ') {
            // If the character is a space, skip it
            continue
        } else {
            throw new Error(`Invalid character "${char}" in time string`)
        }
    }

    return result
}

export const convertToMinutes = (timeData: TimeData[]): number => {
    return timeData.reduce((acc, data) => {
        return (
            acc +
            match<TimeData, number>(data)
                .with({ unit: 'w' }, (data) => data.value * 7 * 24 * 60)
                .with({ unit: 'd' }, (data) => data.value * 24 * 60)
                .with({ unit: 'h' }, (data) => data.value * 60)
                .with({ unit: 'm' }, (data) => data.value)
                .exhaustive()
        )
    }, 0)
}

export const checkTimeString = (str: string) => {
    if (str === '') return true // Empty string is valid
    try {
        parseTimeString(str)
        return true // If parseTimeString does not throw an error, the string is valid.
    } catch (err) {
        return false // If parseTimeString throws an error, the string is invalid.
    }
}
