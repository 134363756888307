import { NotificationStatus } from '@flow/db'
import { useNavigate } from '@tanstack/react-router'
import { Bell as BellIcon } from 'lucide-react'

import { trpc } from '../../utils/trpc'
import { Button } from '../Primitives/Button'
import { Popover, PopoverContent, PopoverTrigger } from '../Primitives/Popover'
import { ScrollArea } from '../Primitives/ScrollArea'
import { NotificationItem } from './NotificationItem'

export const NotificationPopup = () => {
    const { data } = trpc.notification.getLastNotifications.useQuery()
    const navigate = useNavigate()
    const unreadCount =
        data?.reduce((acc, notification) => {
            if (notification.status === NotificationStatus.UNREAD) {
                return acc + 1
            }
            return acc
        }, 0) || 0

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button size="icon" variant="ghost" className="relative">
                    {unreadCount > 0 && (
                        <div className="absolute right-0 top-0 z-10 mr-1 mt-1 flex size-[14px] items-center justify-center rounded-full bg-destructive text-[0.5rem] text-primary">
                            {unreadCount}
                        </div>
                    )}
                    <BellIcon className="size-4 text-yellow-500" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="mt-1 w-[500px] bg-secondary" align="end">
                <div className="-mx-4 grid gap-4">
                    <div className="space-y-2 px-4">
                        <h4 className="text-center font-medium leading-none">
                            Notifications
                            {unreadCount > 0 && <span> ({unreadCount})</span>}
                        </h4>
                    </div>
                    <ScrollArea>
                        <div className="grid max-h-[300px] text-sm">
                            {data?.map((notification) => {
                                return (
                                    <NotificationItem
                                        key={notification.id}
                                        notification={notification}
                                    />
                                )
                            })}
                        </div>
                    </ScrollArea>
                    <div className="flex w-full justify-end px-4">
                        <Button
                            onClick={() => {
                                navigate({ to: '/inbox' })
                            }}
                            variant="ghost"
                            size="sm"
                        >
                            View All
                        </Button>
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    )
}
