import { z } from 'zod'

export const createCycleSchema = z.object({
    projectId: z.string(),
    name: z.string(),
    description: z.string(),
    startDate: z.date().nullable(),
    targetDate: z.date().nullable(),
})

export type CreateCycleSchema = z.infer<typeof createCycleSchema>
