import { User } from '@flow/db'
import { Plate, Value } from '@udecode/plate-common'
import { CursorOverlay } from '@udecode/plate-cursor'
import { useEffect, useRef } from 'react'

import { useTaskStore } from '../../hooks/useTaskStore'
import { cn } from '../../utils/cn'
import { ScrollArea } from '../Primitives/ScrollArea'
import { Editor } from './_plate-ui/editor'
import { FixedToolbar } from './_plate-ui/fixed-toolbar'
import { FixedToolbarButtons } from './_plate-ui/fixed-toolbar-buttons'
import { FloatingToolbar } from './_plate-ui/floating-toolbar'
import { FloatingToolbarButtons } from './_plate-ui/floating-toolbar-buttons'
import { TooltipProvider } from './_plate-ui/tooltip'
import { plugins } from './plugins'

interface Props {
    users: User[]
    defaultValue: Value | null
}

export function BaseEditor({ users: _users, defaultValue }: Props) {
    const containerRef = useRef(null)
    const { description, setDescription, reset } = useTaskStore()

    useEffect(() => {
        reset()
        if (!defaultValue) {
            setDescription([{ children: [{ text: '' }], type: 'p' }])
            return
        }
        setDescription(defaultValue)
        return () => {
            reset()
        }
    }, [defaultValue, reset, setDescription])

    if (!description) return
    return (
        <div className="relative rounded-lg bg-secondary">
            <TooltipProvider>
                <Plate plugins={plugins} value={description} onChange={setDescription}>
                    <FixedToolbar>
                        <FixedToolbarButtons />
                    </FixedToolbar>
                    <div className="flex">
                        <ScrollArea className="flex w-full flex-col justify-stretch">
                            <Editor
                                size="md"
                                placeholder=""
                                className={cn(
                                    'px-8 outline-none cursor w-full bg-transparent border-none min-h-[400px]',
                                )}
                            />
                            <FloatingToolbar>
                                <FloatingToolbarButtons />
                            </FloatingToolbar>

                            <CursorOverlay containerRef={containerRef} />
                        </ScrollArea>
                    </div>
                </Plate>
            </TooltipProvider>
        </div>
    )
}
