import { UserRole } from '@flow/db'
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/admin/_admin')({
    component: () => <Outlet />,
    beforeLoad: ({ context }) => {
        if (context.auth?.role !== UserRole.ADMIN) {
            throw redirect({
                to: '/login',
            })
        }
    },
})
