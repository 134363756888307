import { createFileRoute, Link } from '@tanstack/react-router'
import { useParams } from '@tanstack/react-router'

import { NewCycleDialog } from '../../../components/Cycle/NewCycleDialog'
import { Card, CardDescription, CardTitle } from '../../../components/Primitives/Card'
import { Progress } from '../../../components/Primitives/Progress'
import { BreadCrumbs } from '../../../utils/breadCrumbs'
import { formatDuration } from '../../../utils/date'
import { trpc } from '../../../utils/trpc'

export const Cycles = () => {
    const { projectId } = useParams({
        strict: false,
    })
    const { data } = trpc.cycle.getByProject.useQuery({
        projectId: projectId || '',
    })

    if (!projectId) return null

    return (
        <div className="flex flex-col space-y-2">
            <div>
                <NewCycleDialog />
            </div>
            <div className="flex flex-1 flex-wrap gap-2">
                {data?.map((cycle) => {
                    const percentage =
                        cycle.issues.length > 0
                            ? (cycle._count.issues / cycle.issues.length) * 100
                            : 0
                    return (
                        <Card key={cycle.id} className="flex w-[500px] flex-col space-y-2 p-4">
                            <Link
                                to={`/project/$projectId/list`}
                                params={{
                                    projectId: projectId,
                                }}
                                search={{
                                    cycleId: cycle.id,
                                }}
                                className="space-y-2"
                            >
                                <CardTitle>{cycle.name}</CardTitle>
                                <CardDescription>{cycle.description}</CardDescription>
                            </Link>
                            <div className="text-sm">
                                <p>
                                    Start date:{' '}
                                    {cycle.startDate &&
                                        new Date(cycle.startDate).toLocaleDateString()}
                                </p>
                                <p>
                                    Target date:{' '}
                                    {cycle.targetDate &&
                                        new Date(cycle.targetDate).toLocaleDateString()}
                                </p>
                            </div>
                            <div className="flex space-x-2 text-xs">
                                <div className="w-1/3 rounded-lg bg-muted-foreground p-2 text-muted">
                                    <p>Issues: {cycle.issues.length}</p>
                                    <p>Completed: {cycle._count.issues}</p>
                                    <p>Percentage: {percentage.toFixed(0)}%</p>
                                </div>
                                <div className="w-2/3 rounded-lg bg-muted-foreground p-2 text-muted">
                                    <p>
                                        Estimation(issues):{' '}
                                        {formatDuration(
                                            cycle.issues.reduce(
                                                (acc, issue) => acc + (issue?.estimation || 0),
                                                0,
                                            ),
                                        ) || 'n/a'}
                                    </p>
                                    <p>
                                        Clocked:{' '}
                                        {formatDuration(
                                            cycle.issues.reduce((acc, curr) => {
                                                return (
                                                    acc +
                                                    curr.timeEntries.reduce((a, c) => {
                                                        return a + (c?.timeSpent || 0)
                                                    }, 0)
                                                )
                                            }, 0),
                                        ) || 'n/a'}
                                    </p>
                                </div>
                            </div>
                            <Progress value={percentage} />
                        </Card>
                    )
                })}
            </div>
        </div>
    )
}

export const Route = createFileRoute('/_auth/project/$projectId/cycles')({
    component: Cycles,
    beforeLoad: () => {
        return {
            breadCrumb: BreadCrumbs.Cycles,
        }
    },
})
