import { User } from '@flow/db'
import { UserTimeEntryByProject } from '@schema'
import { isSameDay } from 'date-fns'
import { groupBy } from 'lodash'
import { Fragment } from 'react'

import { cn } from '../../utils/cn'
import { getGrid } from '../../utils/grid'
import { Avatar, AvatarImage } from '../Primitives/Avatar'
import { EntryCard } from './EntryCard'

interface Props {
    user: User
    date: Date | undefined
    numberOfDays: number
    timeEntries: UserTimeEntryByProject[]
}

export const UserEntry = ({ user, date, numberOfDays, timeEntries }: Props) => {
    const days = Array.from({ length: numberOfDays }, (_, i) => i)

    return (
        <Fragment key={user.id}>
            <div className="col-span-1 flex items-start border-t p-4">
                <div className="flex items-center">
                    <Avatar className="mr-2 size-8">
                        <AvatarImage src={user.avatar} />
                    </Avatar>
                    <h2 className="text-base">{user.name}</h2>
                </div>
            </div>
            <div className={cn('col-span-7 border-t p-4')}>
                <div className={cn(`grid w-full`, getGrid(numberOfDays))}>
                    {days?.map((day) => {
                        const TT = new Date(
                            date!.getFullYear(),
                            date!.getMonth(),
                            date!.getDate() + day,
                        )
                        const grouped = groupBy(
                            timeEntries.filter((t) => {
                                return isSameDay(new Date(t.startDate), TT)
                            }),
                            (ent) => {
                                return ent.issueId
                            },
                        )
                        return (
                            <div key={day} className="col-span-1">
                                <div className="flex flex-col space-y-2 px-2 text-xs">
                                    {Object.values(grouped).map((entries) => {
                                        const timeSpent = entries.reduce((acc, curr) => {
                                            acc += curr.timeSpent || 0
                                            return acc
                                        }, 0)
                                        const inProgress = entries.some((entry) => {
                                            return entry.timeSpent === null
                                        })
                                        return (
                                            <EntryCard
                                                key={entries[0].id}
                                                entry={entries[0]}
                                                numberOfEntries={entries.length}
                                                inProgress={inProgress}
                                                timeSpent={timeSpent}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Fragment>
    )
}
